import {useMediaQuery} from '@inperium-corp/convergo-aria-utils';

import {ColorScheme, ThemeConfiguration} from './vars/types';

/**
 * A hook to get the active color scheme to use for the styles.
 * @param theme The theme configuration of the application.
 * @param defaultColorScheme The default color scheme.
 * @returns The color scheme to use.
 */
export function useColorScheme(theme: ThemeConfiguration, defaultColorScheme: ColorScheme): ColorScheme {
  const matchesDark = useMediaQuery('(prefers-color-scheme: dark)');
  const matchesLight = useMediaQuery('(prefers-color-scheme: light)');

  // Importance goes as follows: OS > default > omitted

  if (theme.dark && matchesDark) {
    return 'dark';
  }

  if (theme.light && matchesLight) {
    return 'light';
  }

  if (theme.dark && defaultColorScheme === 'dark') {
    return 'dark';
  }

  if (theme.light && defaultColorScheme === 'light') {
    return 'light';
  }

  if (!theme.dark) {
    return 'light';
  }

  if (!theme.light) {
    return 'dark';
  }

  return 'light';
}
