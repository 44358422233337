import React, {Context, createContext, ReactNode, useContext, useMemo} from 'react';

import {mergeProps} from '@inperium-corp/convergo-aria-utils';
import {SlotProps} from '@inperium-corp/convergo-types';

type Slot = Record<string, unknown> | ((props: Record<string, unknown>) => Record<string, unknown>);

const SlotsContext = createContext<Record<string, Slot>>({});

export type SlotsProviderProps = {
  /**
   * Slots to be stored in a context.
   */
  slots: typeof SlotsContext extends Context<infer S> ? S : never;

  /**
   * By default slots will only be applied one level deep.
   * This can be overridden by setting this to `true`, if
   * you want to propagate slots down multiple levels.
   * @default false
   */
  shouldPropagate?: boolean;

  /**
   * Children in which scope slots will be avaliable.
   */
  children: ReactNode;
};

/**
 * A Slots Provider stores in a context slots with properties to be accessed
 * in the @see Slot component by its name property.
 */
export const SlotsProvider = (props: SlotsProviderProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parentSlots = useContext(SlotsContext) || {};
  const {slots, shouldPropagate = false, children} = props;

  // Merge props for each slot from parent context and props
  const value = useMemo(
    () =>
      shouldPropagate
        ? Object.keys(parentSlots)
            .concat(Object.keys(slots))
            .reduce((o, p) => {
              const slotProvider = slots[p];
              const parentSlotProvider = parentSlots[p];

              if (typeof slotProvider === 'function') {
                if (typeof parentSlotProvider === 'function') {
                  return {
                    ...o,
                    [p]: (props: any) => slotProvider(parentSlotProvider(mergeProps(props)))
                  };
                }

                return {
                  ...o,
                  [p]: (props: any) => slotProvider(mergeProps(parentSlotProvider, props))
                };
              } else if (typeof parentSlotProvider === 'function') {
                return {
                  ...o,
                  [p]: (props: any) => mergeProps(parentSlotProvider(props), slotProvider)
                };
              }

              return {
                ...o,
                [p]: mergeProps(parentSlots[p] || {}, slots[p] || {})
              };
            }, {})
        : slots,
    [parentSlots, shouldPropagate, slots]
  );

  return <SlotsContext.Provider value={value}>{children}</SlotsContext.Provider>;
};

/**
 * A component to clear the slot context for all of its children.
 */
export function ClearSlots(props: any) {
  const {children, ...otherProps} = props;
  let content = children;
  if (React.Children.count(children) <= 1) {
    if (typeof children === 'function') {
      // need to know if the node is a string or something else that react can render that doesn't get props
      content = React.cloneElement(React.Children.only(children), otherProps);
    }
  }

  return <SlotsContext.Provider value={{}}>{content}</SlotsContext.Provider>;
}

/**
 * A Slots Consumer to access slots from a context.
 */
export const SlotsConsumer = SlotsContext.Consumer;

/**
 * A hook to that returns the props from the slot context @see SlotsProvider.
 * Alternatively, the same functionality can be achieved with the @see Slot component.
 * If the props contain a `slot` attribute, it will override the `name` attribute.
 * @param name The name of the slot.
 * @param props The original props of the component.
 * @returns The original props merged with the slot props.
 */
export function useSlotProps<T>(name: string, props: T & SlotProps): T {
  const context = useContext(SlotsContext);
  const {slotName = name, ...propsToMerge} = props;
  const slotProps = context[slotName] || {};

  if (typeof slotProps === 'function') {
    return slotProps(propsToMerge) as T;
  }

  return mergeProps(slotProps, propsToMerge) as T;
}
