import {ModalProvider, ModalProviderProps} from './ModalProvider';
import {useModalProvider} from './useModalProvider';

/**
 * An OverlayProvider acts as a container for the top-level application.
 * Any application that uses modal dialogs or other overlays should
 * be wrapped in a `<OverlayProvider>`. This is used to ensure that
 * the main content of the application is hidden from screen readers
 * if a modal or other overlay is opened. Only the top-most modal or
 * overlay should be accessible at once.
 */
export function OverlayProvider(props: ModalProviderProps) {
  return (
    <ModalProvider>
      <OverlayContainerDOM {...props} />
    </ModalProvider>
  );
}

/**
 * Creates a root node that will be `aria-hidden` if there are other modals open.
 */
function OverlayContainerDOM(props: ModalProviderProps) {
  const {modalProviderProps} = useModalProvider();
  return <div data-overlay-container {...props} {...modalProviderProps} />;
}
