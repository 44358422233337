import {forwardRef, RefObject} from 'react';

import {mergeProps} from '@inperium-corp/convergo-aria-utils';
import {useSlotProps} from '@inperium-corp/convergo-react-layout';
import {css, cx, useStyles} from '@inperium-corp/convergo-react-styles';
import {DOMProps, SlotProps, StyleProps} from '@inperium-corp/convergo-types';
import {IconName, IconSvgProps} from '@inperium-corp/icons';

import {renderSvgPaths} from './renderSvgPaths';

export interface IconProps extends SlotProps, DOMProps, StyleProps {
  /**
   * This component does not support children.
   */
  children?: never;

  /**
   * Color of icon. This is used as the `fill` attribute on the `<svg>` image
   * so it will override any CSS `color` property. If this prop is omitted,
   * icon color is inherited from the surrounding text.
   */
  color?: string;

  /**
   * The name of the icon that should be rendered.
   */
  icon: IconName;

  /**
   * If the icon should be flipped.
   */
  flip?: 'horizontal' | 'vertical' | 'both';

  /**
   * Description string. This string does not appear in normal browsers, but
   * it increases accessibility. For instance, screen readers will use it for
   * aural feedback. By default, this is set to the icon's name. Pass an
   * explicit falsy value to disable.
   */
  title?: string;

  /**
   * The tab inex of the element.
   */
  tabIndex?: string;
}

export const iconStylesFactory = () => ({
  root: css`
    align-items: center;
    color: inherit;
    display: inline-block;
    height: 1em;
    width: 1em;
    vertical-align: middle;
    justify-content: center;
    position: relative;

    &:not(:empty):before {
      content: '';
    }

    transition: transform 0.2s ease;
  `,
  flip: {
    horizontal: css`
      filter: none;
      transform: scale(-1, 1);
    `,
    vertical: css`
      filter: none;
      transform: scale(1, -1);
    `,
    both: css`
      filter: none;
      transform: scale(-1, -1);
    `
  }
});

/**
 * An icon is a pictogram or ideogram in order to help the user navigate.
 */
export const Icon = forwardRef((props: IconProps, ref?: RefObject<SVGSVGElement>) => {
  const styles = useStyles(iconStylesFactory);

  props = useSlotProps('icon', props);
  const {className, color, icon, flip, title} = props;
  let additionalProps = {};
  if (title) {
    additionalProps = {
      title,
      'aria-label': title,
      'data-testid': `${icon}-${title}`
    };
  }

  props = mergeProps(props, additionalProps);

  const iconProps = IconSvgProps[icon];
  if (!iconProps) {
    console.error(`Icon "${icon}" is not defined.`);
  }

  return (
    // @ts-expect-error
    <svg
      data-testid={icon}
      data-icon={icon}
      ref={ref}
      aria-hidden
      role='img'
      {...iconProps}
      {...props}
      fill={color || IconSvgProps[icon].fill || 'none'}
      className={cx('icon', styles.root, flip && styles.flip[flip], className)}
    >
      {title && <desc>{title}</desc>}
      {renderSvgPaths(icon)}
    </svg>
  );
});
