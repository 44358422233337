import {forwardRef, HTMLAttributes, ReactNode, RefObject} from 'react';

import {useModal, usePreventScroll} from '@inperium-corp/convergo-aria-overlays';
import {mergeProps, useViewportSize} from '@inperium-corp/convergo-aria-utils';
import {css, cx, Theme, useStyles} from '@inperium-corp/convergo-react-styles';

export interface ModalWrapperProps extends HTMLAttributes<HTMLElement> {
  /**
   * The content of the modal.
   */
  children: ReactNode;

  /**
   * Whether the modal is open or not.
   */
  isOpen?: boolean;

  /**
   * A function to be called when the modal is closed.
   */
  onClose?: () => void;

  /**
   * The type of the modal.
   */
  type?: 'modal' | 'fullscreen' | 'fullscreenTakeover' | 'drawer';

  /**
   * Additional props for the overlay.
   */
  overlayProps: HTMLAttributes<HTMLElement>;
}

export const modalWrapperStylesFactory = (theme: Theme) => ({
  root: css`
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    z-index: 2;
    visibility: visible;
    transition: visibility 0ms linear 130ms;
    height: var(--convergo-visual-viewport-height);
  `,
  modal: css`
    z-index: 2;
    min-width: 288px;
    border-radius: 9px;
    outline: none;
    // Do not change the opacity or visibility settings!!!
    // This is very important so that the focus can move
    // inside of the popover when the focus is being opened.
    opacity: 0.01111;
    visibility: visible !important;
    transform: translateY(20px);
    // Exit animation
    transition: opacity ${theme.utils.dialog.exitAnimation.duration} cubic-bezier(0.5, 0, 1, 1)
        ${theme.utils.dialog.exitAnimation.delay},
      visibility 0ms linear
        calc(${theme.utils.dialog.exitAnimation.delay} + ${theme.utils.dialog.exitAnimation.duration}),
      transform 0ms linear
        calc(${theme.utils.dialog.exitAnimation.delay} + ${theme.utils.dialog.exitAnimation.duration});
  `,
  isOpen: css`
    opacity: 0.9999;
    pointer-events: auto;
    visibility: visible;
    transform: translateY(0);
    // Entry animation
    transition: transform ${theme.utils.dialog.entryAnimation.duration} cubic-bezier(0, 0, 0.4, 1)
        ${theme.utils.dialog.entryAnimation.delay},
      opacity ${theme.utils.dialog.entryAnimation.duration} cubic-bezier(0, 0, 0.4, 1)
        ${theme.utils.dialog.entryAnimation.delay};
  `,
  types: {
    modal: css`
      max-height: calc(var(--convergo-visual-viewport-height) - 20px);
    `,
    fullscreen: css`
      max-height: none;
      max-width: none;
      width: calc(100% - 80px);
      height: calc(100% - 80px);
      border-radius: 0;
      position: fixed;
      left: 40px;
      top: 40px;
      right: 40px;
      bottom: 40px;
    `,
    fullscreenTakeover: css`
      max-height: none;
      max-width: none;
      width: 100%;
      height: 100%;
      border-radius: 0;
    `,
    drawer: css`
      max-height: none;
      max-width: none;
      height: 100vh;
      width: 100vw;
      border-radius: 0;
    `
  }
});

export const ModalWrapper = forwardRef((props: ModalWrapperProps, ref?: RefObject<HTMLDivElement>) => {
  const styles = useStyles(modalWrapperStylesFactory);
  const {children, isOpen, type, overlayProps, className, ...otherProps} = props;

  usePreventScroll();
  const {modalProps} = useModal();

  const viewport = useViewportSize();
  const style: any = {
    '--convergo-visual-viewport-height': viewport.height + 'px'
  };

  return (
    <div className={styles.root} style={style}>
      <div
        {...mergeProps(otherProps, overlayProps, modalProps)}
        className={cx(styles.modal, isOpen && styles.isOpen, styles.types[type], className)}
        ref={ref}
        data-testid='modal'
      >
        {children}
      </div>
    </div>
  );
});
