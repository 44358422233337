import {MutableRefObject, RefObject} from 'react';

import {useLayoutEffect} from '@inperium-corp/convergo-aria-ssr';

/**
 * Syncs the ref from a context with the ref passed to a hook.
 * @param ref The ref passed to the hook.
 * @param contextRef The ref from the context.
 */
export function useSyncRef<T>(ref: RefObject<T>, contextRef?: MutableRefObject<T>) {
  useLayoutEffect(() => {
    if (contextRef && ref) {
      contextRef.current = ref.current;
      return () => {
        contextRef.current = null;
      };
    }
  }, [contextRef, ref]);
}
