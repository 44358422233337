import {ITokenClaims} from '../interfaces';
import {Permission} from '../types';

/**
 * Checks if the user has permissions to access a specific resource.
 * @param permissions the required permissions for the resource.
 * @param accessTokenClaims the decoded access token claims of the user.
 */
export function hasPermissions(permissions: Permission[] | undefined, accessTokenClaims: ITokenClaims | undefined) {
  if (accessTokenClaims) {
    if (!permissions || permissions.length === 0) {
      return true;
    }

    if (accessTokenClaims.admin) {
      return true;
    }

    if (accessTokenClaims.permissions) {
      return permissions.some((p) => accessTokenClaims.permissions && accessTokenClaims.permissions.indexOf(p) !== -1);
    }
  }
  return false;
}
