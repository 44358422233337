import {HTMLAttributes, RefObject} from 'react';

import {FocusableAriaProps, useFocusable} from '@inperium-corp/convergo-aria-focus';
import {PressAria, usePress} from '@inperium-corp/convergo-aria-interactions';
import {filterDOMProps, mergeProps} from '@inperium-corp/convergo-aria-utils';
import {AriaLabelingProps, PressEvents} from '@inperium-corp/convergo-types';

export interface LinkAriaProps extends PressEvents<HTMLElement>, FocusableAriaProps<HTMLElement>, AriaLabelingProps {
  /**
   * Whether the link is disabled.
   */
  isDisabled?: boolean;

  /**
   * The HTML element used to render the link, e.g. 'a', or 'span'.
   * @default 'a'
   */
  elementType?: string;
}

export interface LinkAria {
  /**
   * The link props to spread on the target element.
   */
  linkProps: HTMLAttributes<HTMLElement>;

  /**
   * The press state of the item.
   */
  pressState: PressAria<HTMLElement>['pressState'];
}

/**
 * Provides the behavior and accessibility implementation for a link component.
 * @param props Props to be applied to the link.
 * @param ref A ref to a DOM element for the link.
 * @returns The aria props to be spread on the link.
 */
export function useLink(props: LinkAriaProps, ref: RefObject<HTMLElement>): LinkAria {
  const {elementType = 'a', onPress, onPressStart, onPressEnd, isDisabled, ...otherProps} = props;

  let linkProps: HTMLAttributes<HTMLElement>;
  if (elementType !== 'a') {
    linkProps = {
      role: 'link',
      tabIndex: !isDisabled ? 0 : undefined
    };
  }
  const {focusableProps} = useFocusable(props, ref);
  const {pressProps, pressState} = usePress({onPress, onPressStart, onPressEnd, isDisabled}, ref);
  const domProps = filterDOMProps(otherProps, {labelable: true});
  const interactionHandlers = mergeProps(focusableProps, pressProps);

  return {
    pressState,
    linkProps: mergeProps(domProps, {
      ...interactionHandlers,
      ...linkProps,
      'aria-disabled': isDisabled || undefined
    })
  };
}
