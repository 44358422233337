// https://en.wikipedia.org/wiki/Right-to-left
const RTL_SCRIPTS = new Set(['Arab', 'Syrc', 'Samr', 'Mand', 'Thaa', 'Mend', 'Nkoo', 'Adlm', 'Rohg', 'Hebr']);
const RTL_LANGS = new Set([
  'ae',
  'ar',
  'arc',
  'bcc',
  'bqi',
  'ckb',
  'dv',
  'fa',
  'glk',
  'he',
  'ku',
  'mzn',
  'nqo',
  'pnb',
  'ps',
  'sd',
  'ug',
  'ur',
  'yi'
]);

/**
 * Determines if a locale is read from right to left (RTL).
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale
 * @returns If the locale is RTL.
 */
export function isRTL(locale: string): boolean {
  // If the Intl.Locale API is available, use it to get the script for the locale.
  // This is more accurate than guessing by language, since languages can be written in multiple scripts.
  // @ts-ignore the Intl types are not great.
  if (Intl.Locale) {
    // @ts-ignore the Intl types are not great.
    const {script} = new Intl.Locale(locale).maximize();
    return RTL_SCRIPTS.has(script);
  }

  // If not, just fallback to guessing by the language (first part of the locale).
  const lang = locale.split('-')[0];
  return RTL_LANGS.has(lang);
}
