import format from 'date-fns/format';
import {useEffect, useState} from 'react';

import {AlertDialog, DialogContainer} from '@inperium-corp/convergo-react-dialog';
import {css, useStyles} from '@inperium-corp/convergo-react-styles';
import {Text} from '@inperium-corp/convergo-react-text';

import {useInperiumAuth} from '../context/useInperiumAuth';
import {useTranslation} from '../i18n/useTranslation';
import {WavingHandIcon} from './WavingHandIcon';

const loginTimingOutModalStylesFactory = () => ({
  root: css`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  `
});

/**
 * This modal is used when the user has been inactive for a while.
 * It asks him to confirm that he is still there and otherwise
 * logs him out of his currently active session.
 */
export const LoginTimingOutModal = () => {
  const {t} = useTranslation('timing_out_modal');

  const styles = useStyles(loginTimingOutModalStylesFactory);

  const {isUserActive, authenticated, refreshTokens, logout, config} = useInperiumAuth();

  const [isOpen, setOpen] = useState(false);

  /** When this countdown is up, the user will be logged out. */
  const [logoutCountdown, setLogoutCounter] = useState(0);

  /** This effect opens the modal when the user is inactive for a period of time. */
  useEffect(() => {
    if (!isUserActive && authenticated) {
      setOpen(true);
      setLogoutCounter(config.logoutCountdownSeconds);
    }
  }, [authenticated, config.logoutCountdownSeconds, isUserActive]);

  /** This effect closes the modal if the user is active again. */
  useEffect(() => {
    if (isUserActive || !authenticated) {
      setOpen(false);
    }
  }, [authenticated, isUserActive]);

  /** This effect sets the intervals for the logout countdown. */
  useEffect(() => {
    const timer = setInterval(() => setLogoutCounter((counter) => Math.max(counter - 1, 0)), 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <DialogContainer
      onDismiss={() => {
        refreshTokens();
        setOpen(false);
      }}
    >
      {isOpen && (
        <AlertDialog
          variant='destructive'
          title={t('still_here')}
          // @ts-ignore TODO: Fix this in Convergo.
          actions={[
            {type: 'primary', label: t('logout'), onPress: () => logout()},
            {type: 'cancel', label: t('im_here'), onPress: () => refreshTokens()}
          ]}
        >
          <span className={styles.root}>
            <WavingHandIcon />
            <Text>{t('explanation', {leftTime: format(new Date(logoutCountdown * 1000), 'm:ss')})}</Text>
          </span>
        </AlertDialog>
      )}
    </DialogContainer>
  );
};
