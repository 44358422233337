import {forwardRef, HTMLAttributes, ReactNode, RefObject} from 'react';

import {filterDOMProps} from '@inperium-corp/convergo-aria-utils';
import {css, cx, useStyles} from '@inperium-corp/convergo-react-styles';
import {SlotProps, StyleProps} from '@inperium-corp/convergo-types';

import {useSlotProps} from './SlotsContext';

export interface CenteredWrapperProps extends StyleProps, SlotProps, HTMLAttributes<HTMLElement> {
  /**
   * The content to be rendered in the element.
   */
  children: ReactNode;
}

const centeredWrapperStylesFactory = () => ({
  root: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  `
});

/**
 * A wrapper element used to center its children in its parent element.
 */
export const CenteredWrapper = forwardRef((props: CenteredWrapperProps, ref: RefObject<HTMLDivElement>) => {
  props = useSlotProps('centeredWrapper', props);
  const {className, children, ...otherProps} = props;
  const styles = useStyles(centeredWrapperStylesFactory);

  return (
    <div {...filterDOMProps(otherProps)} className={cx(styles.root, className)} ref={ref}>
      {children}
    </div>
  );
});
