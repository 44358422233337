import {darkColorScheme} from './darkColorScheme';
import {darkHighContrastColorScheme} from './darkHighContrastColorScheme';
import {globalValues} from './global';
import {largeScale} from './largeScale';
import {lightColorScheme} from './lightColorScheme';
import {lightHighContrastColorScheme} from './lightHighContrastColorScheme';
import {mediumScale} from './mediumScale';
import {utilsFactory} from './utils';

export type ColorScheme = 'light' | 'lightHighContrast' | 'dark' | 'darkHighContrast';
export type Scale = 'medium' | 'large';

export interface ThemeConfiguration {
  /** Defines the global variables, which do not change between color schemes/scales. */
  global: typeof globalValues;
  /** Defines the variables for the light color scheme. */
  light: typeof lightColorScheme;
  /** Defines the variables for the light high contrast scheme. */
  lightHighContrast: typeof lightHighContrastColorScheme;
  /** Defines the variables for the dark color scheme. */
  dark: typeof darkColorScheme;
  /** Defines the variables for the dark high contrast scheme. */
  darkHighContrast: typeof darkHighContrastColorScheme;
  /** Defines the variables for the medium scale. */
  medium: typeof mediumScale;
  /** Defines the variables for the large scale. */
  large: typeof largeScale;
}

export interface Theme {
  /** The global variables, which do not change between color schemes/scales. */
  global: typeof globalValues;
  /** The color scheme of the nearest parent ConvergoProvider. */
  colorScheme: ThemeConfiguration[ColorScheme];
  /** The scale of the nearest parent ConvergoProvider. */
  scale: ThemeConfiguration[Scale];
  /**
   * The utils of the current theme.
   * @deprecated Utils are a deprecated concept to make the color scheme migration easier.
   */
  utils: Utils;
}

export type Utils = ReturnType<typeof utilsFactory>;
