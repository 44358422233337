import {ElementType, forwardRef, RefObject} from 'react';

import {Template, TemplateProps} from './Template';

export interface ViewProps extends Omit<TemplateProps, 'elementType'> {
  /**
   * The HTML element or React element used to render the view component, e.g. 'div', 'a'.
   */
  elementType?: ElementType;
}

export const View = forwardRef((props: ViewProps, ref: RefObject<HTMLDivElement>) => {
  const {elementType = 'div', slotName = 'view', ...otherProps} = props;

  return <Template elementType={elementType} slotName={slotName} ref={ref} {...otherProps} />;
});
