import {forwardRef, ReactNode, RefObject, useContext, useEffect, useRef} from 'react';

import {mergeProps, useSyncRef} from '@inperium-corp/convergo-aria-utils';

import {PressResponderContext} from './PressResponderContext';
import {PressAriaProps} from './usePress';

export interface PressResponderProviderProps<T extends HTMLElement> extends PressAriaProps<T> {
  /**
   * The children that should be rendered as part of the press responder.
   */
  children: ReactNode;
}

/**
 * A context provider that makes its child components respond to user presses.
 */
export const PressResponderProvider = forwardRef(
  <T extends HTMLElement = HTMLElement>(props: PressResponderProviderProps<T>, ref: RefObject<HTMLElement>) => {
    const {children} = props;

    const isRegistered = useRef(false);
    const prevContext = useContext(PressResponderContext);

    const context = mergeProps(prevContext || {}, {
      ...props,
      ref: ref || prevContext?.ref,
      register() {
        isRegistered.current = true;
        if (prevContext) {
          prevContext.register();
        }
      }
    });

    useSyncRef(ref, prevContext?.ref);

    useEffect(() => {
      if (!isRegistered.current) {
        console.warn(
          'A PressResponder was rendered without a pressable child. ' +
            'Either call the usePress hook, or wrap your DOM node with <Pressable> component.'
        );
      }
    }, []);

    return <PressResponderContext.Provider value={context}>{children}</PressResponderContext.Provider>;
  }
);
