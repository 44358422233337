import {HTMLAttributes} from 'react';

import {useId} from '@inperium-corp/convergo-aria-utils';
import {ButtonAriaProps, OverlayTriggerState} from '@inperium-corp/convergo-types';

export interface OverlayTriggerAriaProps {
  /**
   * The type of overlay that is opened by the trigger.
   */
  type: 'dialog' | 'menu' | 'listbox' | 'tree' | 'grid';
}

interface OverlayTriggerAria {
  /**
   * Props for the trigger element.
   */
  triggerProps: ButtonAriaProps;

  /**
   * Props for the overlay container element.
   */
  overlayProps: HTMLAttributes<HTMLElement>;
}

/**
 * Handles the behavior and accessibility for an overlay trigger, e.g. a button
 * that opens a popover, menu, or other overlay that is positioned relative to the trigger.
 * @param props Props to be applied to the overlay trigger.
 * @param state The state of the overlay trigger.
 * @returns The aria props to be spread on the overlay trigger.
 */
export function useOverlayTrigger(props: OverlayTriggerAriaProps, state: OverlayTriggerState): OverlayTriggerAria {
  const {type} = props;
  const {isOpen} = state;

  // Aria 1.1 supports multiple values for aria-haspopup other than just menus.
  // https://www.w3.org/TR/wai-aria-1.1/#aria-haspopup
  // However, we only add it for menus for now because screen readers often
  // announce it as a menu even for other values.
  let ariaHasPopup;
  if (type === 'menu') {
    ariaHasPopup = true;
  } else if (type === 'listbox') {
    ariaHasPopup = 'listbox';
  }

  const overlayId = useId();
  return {
    triggerProps: {
      'aria-haspopup': ariaHasPopup as OverlayTriggerAriaProps['type'],
      'aria-expanded': isOpen,
      'aria-controls': isOpen ? overlayId : null
    },
    overlayProps: {
      id: overlayId
    }
  };
}
