import {cloneElement, ReactElement} from 'react';

import {mergeProps} from '@inperium-corp/convergo-aria-utils';

import {SlotsConsumer} from './SlotsContext';

type SlotProps = {
  /**
   * The name of the slot.
   */
  name: string;

  /**
   * A child to add props from the slot to.
   */
  children: ReactElement;
};

/**
 * A slot to propagate props to the closest children based on the type property from @see SlotsProvider.
 * Alternatively, the same functionality can be achieved with the @see useSlotProps hook.
 */
export const Slot = (props: SlotProps) => {
  const {name, children, ...otherProps} = props;

  return <SlotsConsumer>{(slots) => cloneElement(children, mergeProps(slots[name], otherProps))}</SlotsConsumer>;
};
