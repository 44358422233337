import {ElementType, forwardRef, ReactNode, RefObject} from 'react';

import {filterDOMProps} from '@inperium-corp/convergo-aria-utils';
import {useSlotProps} from '@inperium-corp/convergo-react-layout';
import {css, cx, Theme, useStyles} from '@inperium-corp/convergo-react-styles';
import {DOMProps, FontSize, FontWeight, SlotProps, StyleProps} from '@inperium-corp/convergo-types';

import {textStylesFactory} from './Text';

export interface HeadingProps extends SlotProps, DOMProps, StyleProps {
  /**
   * The heading size of the component.
   * @default medium
   */
  size?: FontSize;

  /**
   * The heading weight of the component.
   * @default semiBold
   */
  weight?: FontWeight;

  /**
   * The heading level of the component.
   * @default 1
   */
  level?: 1 | 2 | 3 | 4 | 5 | 6;

  /**
   * @deprecated
   * Uses the CSS text-transform attribute.
   */
  transform?: 'lowercase' | 'capitalize' | 'capitalize-first-letter';

  /**
   * If the heading should be hidden. Useful for
   * slots that can hide text based on the usecase.
   */
  isHidden?: boolean;

  /**
   * The text that shall be rendered.
   */
  children?: ReactNode;
}

export const headingStylesFactory = (theme: Theme) => {
  const baseStyles = textStylesFactory(theme);
  return {
    root: cx(
      baseStyles.root,
      css`
        align-items: center;
      `
    ),
    sizes: baseStyles.sizes,
    weights: baseStyles.weights,
    transforms: baseStyles.transforms,
    isHidden: baseStyles.isHidden
  };
};

/**
 * A heading with the Inperium standard typography styles.
 */
export const Heading = forwardRef((props: HeadingProps, ref?: RefObject<HTMLElement>) => {
  const styles = useStyles(headingStylesFactory);

  props = useSlotProps('heading', props);
  const {
    size = 'medium',
    weight = 'semiBold',
    level = 1,
    transform,
    isHidden,
    className,
    children,
    ...otherProps
  } = props;

  if (transform) {
    console.warn(
      'You are using the deprecated transform property. Please consider changing the text in a translation file.'
    );
  }

  const ElementType = `h${level}` as ElementType;

  return (
    <ElementType
      {...filterDOMProps(otherProps)}
      ref={ref}
      className={cx(
        styles.root,
        styles.sizes[size],
        styles.weights[weight],
        transform && styles.transforms[transform],
        isHidden && styles.isHidden,
        className
      )}
    >
      {children}
    </ElementType>
  );
});
