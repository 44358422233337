import {useEffect, useState} from 'react';

interface ViewportSize {
  /**
   * The width of the viewport.
   */
  width: number;

  /**
   * The height of the viewport.
   */
  height: number;
}

const visualViewport = typeof window !== 'undefined' && window.visualViewport;

/**
 * A hook to get information about the size of the viewport.
 * @returns The size of the viewport.
 */
export function useViewportSize(): ViewportSize {
  const [size, setSize] = useState(() => getViewportSize());

  useEffect(() => {
    // Use visualViewport api to track available height even on iOS virtual keyboard opening
    const onResize = () => {
      setSize((size) => {
        const newSize = getViewportSize();
        if (newSize.width === size.width && newSize.height === size.height) {
          return size;
        }
        return newSize;
      });
    };

    if (!visualViewport) {
      window.addEventListener('resize', onResize);
    } else {
      visualViewport.addEventListener('resize', onResize);
    }

    return () => {
      if (!visualViewport) {
        window.removeEventListener('resize', onResize);
      } else {
        visualViewport.removeEventListener('resize', onResize);
      }
    };
  }, []);

  return size;
}

function getViewportSize(): ViewportSize {
  return {
    width: visualViewport?.width || window.innerWidth,
    height: visualViewport?.height || window.innerHeight
  };
}
