import {ReactNode} from 'react';

import {Locale} from '@inperium-corp/convergo-types';

import {I18nContext} from './I18nContext';
import {isRTL} from './isRTL';
import {useDefaultLocale} from './useDefaultLocale';

export interface I18nProviderProps {
  /**
   * Contents that should have the locale applied.
   */
  children: ReactNode;

  /**
   * The locale to apply to the children.
   * @see https://www.ietf.org/rfc/bcp/bcp47.txt
   */
  locale?: string;
}

/**
 * Provides the locale for the application to all child components.
 */
export function I18nProvider(props: I18nProviderProps) {
  const {locale, children} = props;
  const defaultLocale = useDefaultLocale();

  const value: Locale = locale
    ? {
      locale,
      writingDirection: isRTL(locale) ? 'rtl' : 'ltr'
    }
    : defaultLocale;

  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
}
