import {ReactPortal, useEffect} from 'react';
import ReactDOM from 'react-dom';

import {useIsSSR} from '@inperium-corp/convergo-aria-ssr';

import {ModalProviderProps} from './ModalProvider';
import {OverlayProvider} from './OverlayProvider';

export interface OverlayContainerProps extends ModalProviderProps {
  /**
   * The container element in which the overlay portal will be placed.
   * @default document.body
   */
  portalContainer?: HTMLElement;
}

/**
 * A container for overlays like modals and popovers. Renders the overlay
 * into a Portal which is placed at the end of the document body.
 * Also ensures that the overlay is hidden from screen readers if a
 * nested modal is opened. Only the top-most modal or overlay should
 * be accessible at once.
 */
export function OverlayContainer(props: OverlayContainerProps): ReactPortal {
  const isSSR = useIsSSR();
  const {portalContainer = isSSR ? null : document.body, ...otherProps} = props;

  useEffect(() => {
    if (portalContainer?.closest('[data-overlay-container]')) {
      throw new Error(
        'An OverlayContainer must not be inside another container. Please change the portalContainer prop.'
      );
    }
  }, [portalContainer]);

  if (!portalContainer) {
    return null;
  }

  const contents = <OverlayProvider {...otherProps} />;
  return ReactDOM.createPortal(contents, portalContainer);
}
