import {forwardRef, RefObject, useCallback, useRef} from 'react';

import {useLayoutEffect} from '@inperium-corp/convergo-aria-ssr';
import {
  filterDOMProps,
  mergeProps,
  useFallbackRef,
  useResizeObserver,
  useValueEffect
} from '@inperium-corp/convergo-aria-utils';
import {SlotsProvider, useSlotProps} from '@inperium-corp/convergo-react-layout';
import {css, cx, useStyles} from '@inperium-corp/convergo-react-styles';
import {ButtonGroupProps} from '@inperium-corp/convergo-types';

export const buttonGroupStylesFactory = () => ({
  root: css`
    display: inline-flex;
    position: relative;
  `,
  orientations: {
    vertical: css`
      flex-direction: column;
      flex-shrink: 0;

      gap: 0.8rem;
    `,
    horizontal: css`
      flex-direction: row;
      flex-shrink: 0;

      gap: 0.8rem;
    `
  },
  alignments: {
    vertical: {
      start: css`
        align-items: flex-start;
      `,
      center: css`
        align-items: center;
      `,
      end: css`
        align-items: flex-end;
      `
    },
    horizontal: {
      start: css`
        justify-content: flex-start;
      `,
      center: css`
        justify-content: center;
      `,
      end: css`
        justify-content: flex-end;
      `
    }
  }
});

/**
 * A button group gives users access to frequently performed, related actions.
 */
export const ButtonGroup = forwardRef((props: ButtonGroupProps, ref?: RefObject<HTMLDivElement>) => {
  const styles = useStyles(buttonGroupStylesFactory);

  props = useSlotProps('buttonGroup', props);

  const buttonProps = useSlotProps('button', {});

  const {
    children,
    orientation: orientationProp = 'horizontal',
    className,
    isDisabled,
    alignment = 'start',
    ...otherProps
  } = props;

  ref = useFallbackRef<HTMLDivElement>(ref);

  const [hasOverflow, setHasOverflow] = useValueEffect(false);

  const checkForOverflow = useCallback(() => {
    const computeHasOverflow = () => {
      const element = ref.current;

      if (element?.children && orientationProp === 'horizontal') {
        const buttonGroupChildren = Array.from(element.children) as HTMLElement[];
        const maxX = element.offsetWidth + 1; // + 1 to account for rounding errors

        /**
         * If any buttons have negative X positions (alignment="end") or extend beyond
         * the width of the button group (alignment="start"), then switch to vertical.
         */
        if (buttonGroupChildren.some((child) => child.offsetLeft < 0 || child.offsetLeft + child.offsetWidth > maxX)) {
          return true;
        }
        return false;
      }
    };
    if (orientationProp === 'horizontal') {
      setHasOverflow(function* () {
        // Force to horizontal for measurement.
        yield false;

        // Measure, and update if there is overflow.
        yield computeHasOverflow();
      });
    }
  }, [ref, orientationProp, setHasOverflow]);

  // There are two main reasons we need to remeasure:
  // 1. Internal changes: Check for initial overflow or when orientation/scale/children change.
  // This comes from the checkForOverflow dep array.
  useLayoutEffect(() => {
    checkForOverflow();
  }, [checkForOverflow]);

  // 2. External changes: ButtonGroup won't change size due to any parents changing size, so listen
  // to its container for size changes to figure out if we should remeasure.
  const parent = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      parent.current = ref.current.parentElement as HTMLElement;
    }
  }, [parent, ref]);

  useResizeObserver({ref: parent, onResize: checkForOverflow});

  const orientation = hasOverflow ? 'vertical' : orientationProp;

  return (
    <div
      {...filterDOMProps(otherProps)}
      ref={ref}
      className={cx(
        styles.root,
        styles.orientations[orientation],
        styles.alignments[orientation][alignment],
        className
      )}
    >
      <SlotsProvider slots={{button: mergeProps(buttonProps, {isDisabled})}}>{children}</SlotsProvider>
    </div>
  );
});
