import {ITokenClaims} from '../interfaces';
import {Permission} from '../types';
import {hasPermissions} from './hasPermissions';

/**
 * Checks if the user has permissions to access a specific resource.
 * It does not just take into account the required permission but also the "assigned owner".
 * @param permissions The required permissions for the resource.
 * @param userId The owner of the object.
 * @param accessTokenClaims the decoded access token claims of the user.
 */
export function hasPermissionsAndOwnership(
  permissions: Permission[],
  userId: string | undefined,
  accessTokenClaims: ITokenClaims | undefined
) {
  if (accessTokenClaims && accessTokenClaims.permissions) {
    return accessTokenClaims.userId === userId && hasPermissions(permissions, accessTokenClaims);
  }
  return false;
}
