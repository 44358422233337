import IntlMessageFormat from 'intl-messageformat';
import {FormatXMLElementFn, PrimitiveType} from 'intl-messageformat/src/formatters';

import {MessageDictionary} from './MessageDictionary';

/**
 * Formats ICU Message strings to create localized strings from a MessageDictionary.
 */
export class MessageFormatter {
  private locale: string;

  private messages: MessageDictionary;

  private cache: {[key: string]: IntlMessageFormat};

  constructor(locale: string, messages: MessageDictionary) {
    this.locale = locale;
    this.messages = messages;
    this.cache = {};
  }

  format<T = void>(
    key: string,
    variables: Record<string, PrimitiveType | T | FormatXMLElementFn<T, string | T | (string | T)[]>> | undefined
  ) {
    let message = this.cache[key];
    if (!message) {
      const msg = this.messages.getStringForLocale(key, this.locale);
      if (!msg) {
        throw new Error(`Could not find intl message ${key} in ${this.locale} locale`);
      }

      message = new IntlMessageFormat(msg, this.locale, null, {});
      this.cache[key] = message;
    }

    let varCopy: Record<string, PrimitiveType | T | FormatXMLElementFn<T, string | T | (string | T)[]>> | undefined;
    if (variables) {
      varCopy = Object.keys(variables).reduce((acc: {[key in string]: any}, key: string) => {
        acc[key] = variables[key] == null ? false : variables[key];
        return acc;
      }, {});
    }

    return message.format(varCopy);
  }
}
