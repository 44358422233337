import {RefObject, useEffect} from 'react';

type ResizeObserverProps<T> = {
  /**
   * The ref to the object that should be observed.
   */
  ref: RefObject<T | undefined>;

  /**
   * A method executed when the object is being resized.
   */
  onResize: () => void;
};

/**
 * A hook that observes if an element is being resized and can trigger a method based on it.
 * @param options The options to configure the hook.
 */
export function useResizeObserver<T extends HTMLElement>(props: ResizeObserverProps<T>) {
  const {ref, onResize} = props;

  useEffect(() => {
    const element = ref?.current;
    if (!element) {
      return;
    }

    if (!hasResizeObserver()) {
      window.addEventListener('resize', onResize, false);
      return () => {
        window.removeEventListener('resize', onResize, false);
      };
    } else {
      const resizeObserverInstance = new window.ResizeObserver((entries) => {
        if (!entries.length) {
          return;
        }

        onResize();
      });

      resizeObserverInstance.observe(element);

      return () => {
        resizeObserverInstance.unobserve(element);
      };
    }
  }, [onResize, ref]);
}

/**
 * Checks if the window has a resize observer.
 * @returns If the window has a resize observer.
 */
function hasResizeObserver() {
  return typeof window.ResizeObserver !== 'undefined';
}
