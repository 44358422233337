import {ReactNode, useContext, useMemo} from 'react';

import {
  defaultUniqueIdentifierContext,
  UniqueIdentifierContext,
  UniqueIdentifierContextValue
} from './UniqueIdentifierContext';

interface UniqueIdentifierProviderProps {
  /**
   * The entire application.
   */
  children: ReactNode;
}

/**
 * Applications must be wrapped in an UniqueIdentifierProvider.
 * This ensures that auto generated ids are consistent between the client and server.
 */
export function UniqueIdentifierProvider(props: UniqueIdentifierProviderProps) {
  const {children} = props;
  const context = useContext(UniqueIdentifierContext);

  const value: UniqueIdentifierContextValue = useMemo(
    () => ({
      // If this is the first UniqueIdentifierProvider, start with an empty string prefix, otherwise
      // append and increment the counter.
      prefix: context === defaultUniqueIdentifierContext ? '0' : String(++context.current),
      current: 0
    }),
    [context]
  );

  return <UniqueIdentifierContext.Provider value={value}>{children}</UniqueIdentifierContext.Provider>;
}
