{
  "name": "@inperium-corp/convergo-react-provider",
  "description": "The React implementation of Inperium Convergo provider components.",
  "version": "2.2.2",
  "author": "Inperium Corp.",
  "license": "SEE LICENSE IN LICENSE.md",
  "keywords": [
    "react",
    "inperium",
    "provider"
  ],
  "source": "src/index.ts",
  "main": "dist/index.js",
  "module": "dist/module.js",
  "types": "dist/index.d.ts",
  "files": [
    "dist",
    "src"
  ],
  "sideEffects": false,
  "scripts": {
    "clean": "rm -rf dist",
    "reset": "rm -rf node_modules dist",
    "version": "yarn version"
  },
  "dependencies": {
    "@babel/runtime": "7.6.2",
    "@inperium-corp/convergo-aria-i18n": "^2.2.2",
    "@inperium-corp/convergo-aria-overlays": "^2.2.2",
    "@inperium-corp/convergo-aria-utils": "^2.2.2",
    "@inperium-corp/convergo-types": "^2.2.2",
    "@swc/helpers": "^0.3.13"
  },
  "devDependencies": {
    "@inperium-corp/convergo-react-styles": "^2.2.2"
  },
  "peerDependencies": {
    "@inperium-corp/convergo-react-styles": "^2.2.2",
    "react": "^17.0.0 || ^18.0.0"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/inperium-corp/inperium-convergo.git",
    "directory": "packages/convergo-react/provider"
  },
  "publishConfig": {
    "registry": "https://npm.pkg.github.com/"
  },
  "gitHead": "71b7176c2bb2544765f5d1f71b8ef7b0a957b09d"
}
