import {RefObject, useEffect} from 'react';

export interface CloseOnScrollProps {
  /**
   * If the overlay is open.
   */
  isOpen?: boolean;

  /**
   * A method to close the overlay.
   */
  onClose?: () => void;
}

/**
 * A hook that allows us to close an overlay when the user scrolls.
 * @param props The props for the hook.
 * @param ref The ref to the overlay trigger.
 */
export function useCloseOnScroll(props: CloseOnScrollProps, ref: RefObject<HTMLElement>) {
  const {isOpen, onClose} = props;

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const onScroll = (event: Event) => {
      // Ignore if scrolling an scrollable region outside the trigger's tree.
      const {target} = event;
      // window is not a Node and doesn't have contain, but window contains everything
      if (!ref.current || (target instanceof Node && !target.contains(ref.current))) {
        return;
      }

      if (onClose) {
        onClose();
      }
    };

    window.addEventListener('scroll', onScroll, true);
    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, [isOpen, onClose, ref]);
}
