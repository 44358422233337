import {forwardRef, ReactNode, RefObject} from 'react';

import {useSlotProps} from '@inperium-corp/convergo-react-layout';
import {css, cx, Theme, useStyles} from '@inperium-corp/convergo-react-styles';
import {DOMProps, FontSize, FontWeight, SlotProps, StyleProps} from '@inperium-corp/convergo-types';

export interface TextProps extends SlotProps, DOMProps, StyleProps {
  /**
   * The text size of the component.
   * @default medium
   */
  size?: FontSize;

  /**
   * The text weight of the component.
   * @default regular
   */
  weight?: FontWeight;

  /**
   * @deprecated
   * Uses the CSS text-transform attribute.
   */
  transform?: 'lowercase' | 'capitalize' | 'capitalize-first-letter';

  /**
   * If the text should be hidden. Useful for
   * slots that can hide text based on the usecase.
   */
  isHidden?: boolean;

  /**
   * The text that shall be rendered.
   */
  children?: ReactNode;
}

export const textStylesFactory = (theme: Theme) => ({
  root: css`
    color: ${theme.colorScheme.text.color};
    font-family: ${theme.global.font.family.base};
    font-style: ${theme.global.font.style.regular};
  `,
  sizes: {
    small: css`
      font-size: ${theme.scale.font.size.small};
      line-height: ${theme.scale.font.lineHeight.small};
    `,
    medium: css`
      font-size: ${theme.scale.font.size.medium};
      line-height: ${theme.scale.font.lineHeight.medium};
    `,
    large: css`
      font-size: ${theme.scale.font.size.large};
      line-height: ${theme.scale.font.lineHeight.large};
    `,
    xlarge: css`
      font-size: ${theme.scale.font.size.xlarge};
      line-height: ${theme.scale.font.lineHeight.xlarge};
    `,
    '2xlarge': css`
      font-size: ${theme.scale.font.size['2xlarge']};
      line-height: ${theme.scale.font.lineHeight['2xlarge']};
    `,
    '3xlarge': css`
      font-size: ${theme.scale.font.size['3xlarge']};
      line-height: ${theme.scale.font.lineHeight['3xlarge']};
    `
  },
  weights: {
    thin: css`
      font-weight: ${theme.global.font.weight.thin};
    `,
    ultraLight: css`
      font-weight: ${theme.global.font.weight.ultraLight};
    `,
    light: css`
      font-weight: ${theme.global.font.weight.light};
    `,
    regular: css`
      font-weight: ${theme.global.font.weight.regular};
    `,
    medium: css`
      font-weight: ${theme.global.font.weight.medium};
    `,
    semiBold: css`
      font-weight: ${theme.global.font.weight.semiBold};
    `,
    bold: css`
      font-weight: ${theme.global.font.weight.bold};
    `,
    extraBold: css`
      font-weight: ${theme.global.font.weight.extraBold};
    `,
    black: css`
      font-weight: ${theme.global.font.weight.black};
    `
  },
  transforms: {
    lowercase: css`
      text-transform: lowercase;
    `,
    capitalize: css`
      text-transform: capitalize;
    `,
    'capitalize-first-letter': css`
      &:first-letter {
        text-transform: uppercase;
      }
    `
  },
  isHidden: css`
    display: none;
  `
});

/**
 * The text component is used to display a short text with the standard Inperium typography styles.
 */
export const Text = forwardRef((props: TextProps, ref?: RefObject<HTMLSpanElement>) => {
  const styles = useStyles(textStylesFactory);

  props = useSlotProps('text', props);
  const {size = 'medium', weight = 'regular', className, children, transform, isHidden, ...otherProps} = props;

  if (transform) {
    console.warn(
      'You are using the deprecated transform property. Please consider changing the text in a translation file.'
    );
  }

  return (
    <span
      {...otherProps}
      ref={ref}
      className={cx(
        styles.root,
        styles.sizes[size],
        styles.weights[weight],
        transform && styles.transforms[transform],
        isHidden && styles.isHidden,
        className
      )}
    >
      {children}
    </span>
  );
});
