import {ITokenClaims} from '../interfaces';
import {Feature} from '../types';

/**
 * Checks if the principal owns a specific feature.
 * @param features The required feature for the resource.
 * @param accessTokenClaims the decoded access token claims of the user.
 */
export function ownsFeatures(features: Feature[] | undefined, accessTokenClaims: ITokenClaims | undefined) {
  if (accessTokenClaims) {
    if (!features || features.length === 0) {
      return true;
    }

    return features.some((f) => accessTokenClaims.features && accessTokenClaims.features.indexOf(f) !== -1);
  }
  return false;
}
