import {forwardRef, ReactNode, RefObject} from 'react';

import {useMessageFormatter} from '@inperium-corp/convergo-aria-i18n';
import {useSlotProps} from '@inperium-corp/convergo-react-layout';
import {css, cx, keyframes, useStyles} from '@inperium-corp/convergo-react-styles';
import {DOMProps, SlotProps, StyleProps} from '@inperium-corp/convergo-types';

import i18nMessages from '../i18n/*.json';

export interface SpinnerProps extends SlotProps, DOMProps, StyleProps {
  /**
   * Any children of the spinner. This can be used to change the
   * width or height of the spinner based on the content that it
   * overlays.
   * @default '...'
   */
  children?: ReactNode;
}

const spinnerStylesFactory = () => ({
  root: css`
    align-items: inherit;
    color: inherit;
    display: inherit;
    justify-content: inherit;
    position: relative;
    font-size: 1.4rem;
  `,
  animation: css`
    animation: ${keyframes`
        from {
          -webkit-transform: translateY(-50%) rotate(0deg);
        }
        
        to {
          -webkit-transform: translateY(-50%) rotate(359deg);
        }
      `} 1s infinite;
    border: 3px solid;
    border-radius: 50%;
    border-top-color: transparent;
    display: inline-block;
    height: 1em;
    left: 50%;
    margin-left: -0.5em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1em;
  `,
  label: css`
    opacity: 0;
  `
});

export const Spinner = forwardRef((props: SpinnerProps, ref?: RefObject<HTMLDivElement>) => {
  props = useSlotProps('spinner', props);
  const {className, children, ...otherProps} = props;

  const styles = useStyles(spinnerStylesFactory);

  const formatMessage = useMessageFormatter(i18nMessages);

  return (
    <div
      ref={ref}
      className={cx(styles.root, className)}
      aria-label={formatMessage('loading')}
      role='progressbar'
      {...otherProps}
    >
      <span className={styles.animation} />
      <span className={styles.label}>{children || '...'}</span>
    </div>
  );
});
