import {HTMLAttributes, JSXElementConstructor, ReactNode} from 'react';

import {mergeProps} from '@inperium-corp/convergo-aria-utils';

import {useVisuallyHidden} from './useVisuallyHidden';

export interface VisuallyHiddenProps extends HTMLAttributes<HTMLElement> {
  /**
   * The content to visually hide.
   */
  children?: ReactNode;

  /**
   * The element type for the container.
   * @default 'div'
   */
  elementType?: string | JSXElementConstructor<any>;

  /**
   * Whether the element should become visible on focus, for example skip links.
   */
  isFocusable?: boolean;
}

/**
 * VisuallyHidden hides its children visually, while keeping content visible to screen readers.
 */
export function VisuallyHidden(props: VisuallyHiddenProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {children, elementType: Element = 'div', isFocusable, style, ...otherProps} = props;
  const {visuallyHiddenProps} = useVisuallyHidden(props);

  return <Element {...mergeProps(otherProps, visuallyHiddenProps)}>{children}</Element>;
}
