import {FC, ReactNode} from 'react';

import {useInperiumAuth} from '../context/useInperiumAuth';
import {Guard, useAuthorizationGuard} from '../hooks';

export interface IAuthorizationGuardProps {
  /**
   * The resource to protect with the AuthorizationGuard.
   */
  children: ReactNode;

  /**
   * Authorization guards.
   */
  guards: Guard | Guard[];

  /**
   * Specifies the component to render if children are not allowed to be rendered.
   */
  fallbackComponent?: ReactNode | null;
}

/**
 * This component protects its children to only allow certain users to see them.
 * It can protect them based on feature/permission flags or simple authentication checks.
 */
export const AuthorizationGuard: FC<IAuthorizationGuardProps> = ({
  guards,
  children,
  fallbackComponent = null
}: IAuthorizationGuardProps) => {
  const {initialized} = useInperiumAuth();
  const {protect} = useAuthorizationGuard();

  return initialized ? <>{protect(children, guards, fallbackComponent)}</> : null;
};
