/* eslint-disable no-use-before-define */
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {CookieChangeEvent} from './CookieChangeEvent';
import {CookieOptions} from './CookieOptions';
import {getCookie as getDocumentCookie} from './getCookie';
import {setCookie as setDocumentCookie} from './setCookie';

export type UseCookieOptions = {
  /**
   * Specifies the polling interval in seconds.
   * @default 5
   */
  pollingInterval?: number;
};

const defaultValueOptions: CookieOptions = {
  domain: process.env.GATSBY_COOKIE_DOMAIN || process.env.REACT_APP_COOKIE_DOMAIN
};

const defaultOptions: UseCookieOptions = {
  pollingInterval: 5
};

const AUTH_TOKEN_COOKIE_CHUNK_LENGTH =
  process.env.GATSBY_AUTH_TOKEN_COOKIE_CHUNK_LENGTH || process.env.REACT_APP_AUTH_TOKEN_COOKIE_CHUNK_LENGTH || '3000';

/**
 * A hook to retrieve and store data in the browser cookies.
 */
export const useCookie = <TValue>(
  name: string,
  defaultValue: TValue,
  valueOptions: CookieOptions = defaultValueOptions,
  options: UseCookieOptions = defaultOptions
): [TValue, (value: TValue, options?: CookieOptions) => void] => {
  const chunkCountName = `${name}_chunk_count`;

  const getChunkedCookie = useCallback(() => {
    const chunkCountCookie = getDocumentCookie(chunkCountName);
    try {
      if (chunkCountCookie) {
        const chunkLength = parseInt(chunkCountCookie, 10);
        const chunks = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < chunkLength; i++) {
          const cookieName = `${name}_${i}`;
          const cookieChunk = getDocumentCookie(cookieName);
          if (cookieChunk) {
            chunks.push(cookieChunk);
          }
        }
        return chunks.join('');
      }
      return getDocumentCookie(name);
    } catch (error) {
      return getDocumentCookie(name);
    }
  }, [chunkCountName, name]);

  const setCookie = useCallback(
    (value: TValue, options?: CookieOptions) => {
      // Clear the previous cookie chunks.
      const prevChunkCountCookie = getDocumentCookie(chunkCountName);

      const maxLength = parseInt(AUTH_TOKEN_COOKIE_CHUNK_LENGTH, 10);
      const stringValue = String(value);
      const valueLength = stringValue.length;
      const chunks = [];
      for (let i = 0; i < valueLength; i += maxLength) {
        const chunkValue = stringValue.slice(i, i + maxLength);
        chunks.push(chunkValue);
      }
      // Set a cookie for the amount of chunks.
      saveCookie(chunkCountName, String(chunks.length), {...valueOptions, ...options});
      // Set the individual chunks as cookies.
      chunks.forEach((chunk, index) => {
        const cookieName = `${name}_${index}`;
        saveCookie(cookieName, chunk, {...valueOptions, ...options});
      });

      if (prevChunkCountCookie) {
        const chunkLength = parseInt(prevChunkCountCookie, 10);
        // eslint-disable-next-line no-plusplus
        for (let i = chunks.length; i < chunkLength; i++) {
          const cookieName = `${name}_${i}`;
          const expiration = new Date(new Date().setFullYear(new Date().getFullYear() - 100));
          setDocumentCookie(cookieName, '', {...defaultValueOptions, ...options, expires: expiration, path: '/'});
        }
      }
    },
    [name, valueOptions, chunkCountName]
  );

  const [cookieState, setCookieState] = useState(() => getChunkedCookie() || defaultValue);

  /**
   * This effect syncs cookies across the document.
   */
  useEffect(() => {
    const listener = (event: Event) => {
      const {detail} = event as CookieChangeEvent;
      if (detail.options.domain === valueOptions.domain) {
        setCookieState(getChunkedCookie() || '');
      }
    };

    window.addEventListener('cookieChange', listener);

    return () => document.removeEventListener('cookieChange', listener);
  }, [getChunkedCookie, valueOptions]);

  const pollingInterval = useRef<NodeJS.Timeout | null>(null);

  /**
   * This effect handles polling for changes to the cookie in order to sync state if the cookie was changed from outside of the document.
   */
  useEffect(() => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
    }

    pollingInterval.current = setInterval(() => {
      const newCookie = getChunkedCookie();
      if (newCookie && newCookie !== cookieState) {
        setCookieState(newCookie);
      }
    }, (options.pollingInterval || 5) * 1000);
  }, [cookieState, getChunkedCookie, options]);

  // @ts-ignore
  return [useMemo(() => cookieState, [cookieState]), setCookie];
};

function saveCookie(name: string, value: string, options: CookieOptions = defaultValueOptions) {
  setDocumentCookie(name, value, options);
  window.dispatchEvent(
    new CustomEvent('cookieChange', {
      detail: {
        name,
        value,
        options
      }
    })
  );
}
