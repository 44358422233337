import {HTMLAttributes} from 'react';

import {KeyboardEvents} from '@inperium-corp/convergo-types';

import {createEventHandler} from './createEventHandler';

export interface KeyboardAriaProps<T extends HTMLElement> extends KeyboardEvents<T> {
  /**
   * Whether the keyboard events should be disabled.
   */
  isDisabled?: boolean;
}

export interface KeyboardAria<T extends HTMLElement> {
  /**
   * Props to spread onto the target element.
   */
  keyboardProps: HTMLAttributes<T>;
}

/**
 * Handles keyboard interactions for a focusable element.
 * @param props The props to configure the hook.
 * @returns The keyboard aria properties.
 */
export function useKeyboard<T extends HTMLElement>(props: KeyboardAriaProps<T>): KeyboardAria<T> {
  return {
    keyboardProps: props.isDisabled
      ? {}
      : {
        onKeyDown: createEventHandler(props.onKeyDown),
        onKeyUp: createEventHandler(props.onKeyUp)
      }
  };
}
