import {forwardRef, ReactNode, RefObject} from 'react';

import {filterDOMProps} from '@inperium-corp/convergo-aria-utils';
import {useSlotProps} from '@inperium-corp/convergo-react-layout';
import {css, cx, Theme, useStyles} from '@inperium-corp/convergo-react-styles';
import {DOMProps, FontSize, FontWeight, SlotProps, StyleProps} from '@inperium-corp/convergo-types';

import {textStylesFactory} from './Text';

export interface ParagraphProps extends SlotProps, DOMProps, StyleProps {
  /**
   * The paragraph size of the component.
   * @default medium
   */
  size?: FontSize;

  /**
   * The paragraph weight of the component.
   * @default regular
   */
  weight?: FontWeight;

  /**
   * @deprecated
   * Uses the CSS text-transform attribute.
   */
  transform?: 'lowercase' | 'capitalize' | 'capitalize-first-letter';

  /**
   * If the paragraph should be hidden. Useful for
   * slots that can hide text based on the usecase.
   */
  isHidden?: boolean;

  /**
   * The text that shall be rendered.
   */
  children?: ReactNode;
}

export const paragraphStylesFactory = (theme: Theme) => {
  const baseStyles = textStylesFactory(theme);
  return {
    root: cx(
      baseStyles.root,
      css`
        align-items: center;
      `
    ),
    sizes: baseStyles.sizes,
    weights: baseStyles.weights,
    transforms: baseStyles.transforms,
    isHidden: baseStyles.isHidden
  };
};

/**
 * A paragraph with the Inperium standard typography styles.
 */
export const Paragraph = forwardRef((props: ParagraphProps, ref?: RefObject<HTMLParagraphElement>) => {
  const styles = useStyles(paragraphStylesFactory);

  props = useSlotProps('paragraph', props);
  const {size = 'medium', weight = 'regular', transform, isHidden, className, children, ...otherProps} = props;

  if (transform) {
    console.warn(
      'You are using the deprecated transform property. Please consider changing the text in a translation file.'
    );
  }

  return (
    <p
      {...filterDOMProps(otherProps)}
      ref={ref}
      className={cx(
        styles.root,
        styles.sizes[size],
        styles.weights[weight],
        transform && styles.transforms[transform],
        isHidden && styles.isHidden,
        className
      )}
    >
      {children}
    </p>
  );
});
