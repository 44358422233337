import {ReactNode, RefObject, useContext, useEffect, useRef} from 'react';

import {useLayoutEffect} from '@inperium-corp/convergo-aria-ssr';

import {focusSafely} from './focusSafely';
import {FocusScopeContext} from './FocusScopeContext';
import {isElementVisible} from './isElementVisible';

interface FocusScopeProviderProps {
  /**
   * The contents of the focus scope.
   */
  children: ReactNode;

  /**
   * Whether to contain focus inside the scope, so users cannot
   * move focus outside, for example in a modal dialog.
   */
  contain?: boolean;

  /**
   * Whether to restore focus back to the element that was focused
   * when the focus scope mounted, after the focus scope unmounts.
   */
  restoreFocus?: boolean;

  /**
   * Whether to auto focus the first focusable element in the focus scope on mount.
   */
  autoFocus?: boolean;
}

export type ScopeRef = RefObject<HTMLElement[]>;

let activeScope: ScopeRef = null;
const scopes: Map<ScopeRef, ScopeRef | null> = new Map();

/**
 * A Focus Scope Provider manages focus for its descendants. It supports containing the
 * focus inside the scope, restoring focus to the previously focused element on unmount,
 * and auto focusing children on mount. It also acts as a container for a programmatic
 * focus management interface that can be used to move focus forward and backwards in
 * response to user events.
 */
export function FocusScopeProvider(props: FocusScopeProviderProps) {
  const {children, contain, restoreFocus, autoFocus} = props;

  const firstElementRef = useRef<HTMLSpanElement>();
  const lastElementRef = useRef<HTMLSpanElement>();
  const scopeRef = useRef<HTMLElement[]>([]);
  const ctx = useContext(FocusScopeContext);
  const parentScope = ctx?.scopeRef;

  useLayoutEffect(() => {
    // Find all rendered elements between the sentinels and add them to the scope.
    let element = firstElementRef.current.nextSibling as HTMLElement;
    const elements: HTMLElement[] = [];
    while (element && element !== lastElementRef.current) {
      elements.push(element);
      element = element.nextSibling as HTMLElement;
    }

    scopeRef.current = elements;
  }, [children, parentScope]);

  useLayoutEffect(() => {
    scopes.set(scopeRef, parentScope);
    return () => {
      // Restore the active scope on unmount if this scope or a descendant scope is active.
      // Parent effect cleanups run before children, so we need to check if the
      // parent scope actually still exists before restoring the active scope to it.
      if (
        (scopeRef === activeScope || isAncestorScope(scopeRef, activeScope)) &&
        (!parentScope || scopes.has(parentScope))
      ) {
        activeScope = parentScope;
      }
      scopes.delete(scopeRef);
    };
  }, [scopeRef, parentScope]);

  useFocusContainment(scopeRef, contain);
  useRestoreFocus(scopeRef, restoreFocus, contain);
  useAutoFocus(scopeRef, autoFocus);

  const focusManager = createFocusManagerForScope(scopeRef);

  return (
    <FocusScopeContext.Provider value={{scopeRef, focusManager}}>
      <span data-focus-scope-start hidden ref={firstElementRef} />
      {children}
      <span data-focus-scope-end hidden ref={lastElementRef} />
    </FocusScopeContext.Provider>
  );
}

interface FocusManagerOptions {
  /**
   * The element to start searching from. The currently focused element by default.
   */
  from?: HTMLElement;

  /**
   * Whether to only include tabbable elements, or all focusable elements.
   */
  tabbable?: boolean;

  /**
   * Whether focus should wrap around when it reaches the end of the scope.
   */
  wrap?: boolean;

  /**
   * A callback that determines whether the given element is focused.
   */
  accept?: (node: Element) => boolean;
}

export interface FocusManager {
  /**
   * Moves focus to the next focusable or tabbable element in the focus scope.
   */
  focusNext(opts?: FocusManagerOptions): HTMLElement;

  /**
   * Moves focus to the previous focusable or tabbable element in the focus scope.
   */
  focusPrevious(opts?: FocusManagerOptions): HTMLElement;

  /**
   * Moves focus to the first focusable or tabbable element in the focus scope.
   */
  focusFirst(opts?: FocusManagerOptions): HTMLElement;

  /**
   * Moves focus to the last focusable or tabbable element in the focus scope.
   */
  focusLast(opts?: FocusManagerOptions): HTMLElement;
}

/**
 * Returns a focusManager interface for the parent FocusScopeProvider.
 * A FocusManager can be used to programmatically move focus within
 * a FocusScopeProvider, e.g. in response to user events like keyboard navigation.
 * @see FocusManager
 * @see FocusScopeProvider
 * @returns The focus scope manager.
 */
export function useFocusManager(): FocusManager {
  return useContext(FocusScopeContext).focusManager;
}

/**
 * Creates a focus manager for the scope.
 * @param scopeRef A ref to the scope.
 * @returns The focus manager.
 */
function createFocusManagerForScope(scopeRef: RefObject<HTMLElement[]>): FocusManager {
  return {
    focusNext(opts: FocusManagerOptions = {}) {
      const scope = scopeRef.current;
      const {from, tabbable, wrap, accept} = opts;
      const node = from || document.activeElement;
      const sentinel = scope[0].previousElementSibling;
      const walker = getFocusableTreeWalker(getScopeRoot(scope), {tabbable, accept}, scope);
      walker.currentNode = isElementInScope(node, scope) ? node : sentinel;
      let nextNode = walker.nextNode() as HTMLElement;
      if (!nextNode && wrap) {
        walker.currentNode = sentinel;
        nextNode = walker.nextNode() as HTMLElement;
      }
      if (nextNode) {
        focusElement(nextNode, true);
      }
      return nextNode;
    },
    focusPrevious(opts: FocusManagerOptions = {}) {
      const scope = scopeRef.current;
      const {from, tabbable, wrap, accept} = opts;
      const node = from || document.activeElement;
      const sentinel = scope[scope.length - 1].nextElementSibling;
      const walker = getFocusableTreeWalker(getScopeRoot(scope), {tabbable, accept}, scope);
      walker.currentNode = isElementInScope(node, scope) ? node : sentinel;
      let previousNode = walker.previousNode() as HTMLElement;
      if (!previousNode && wrap) {
        walker.currentNode = sentinel;
        previousNode = walker.previousNode() as HTMLElement;
      }
      if (previousNode) {
        focusElement(previousNode, true);
      }
      return previousNode;
    },
    focusFirst(opts = {}) {
      const scope = scopeRef.current;
      const {tabbable, accept} = opts;
      const walker = getFocusableTreeWalker(getScopeRoot(scope), {tabbable, accept}, scope);
      walker.currentNode = scope[0].previousElementSibling;
      const nextNode = walker.nextNode() as HTMLElement;
      if (nextNode) {
        focusElement(nextNode, true);
      }
      return nextNode;
    },
    focusLast(opts = {}) {
      const scope = scopeRef.current;
      const {tabbable, accept} = opts;
      const walker = getFocusableTreeWalker(getScopeRoot(scope), {tabbable, accept}, scope);
      walker.currentNode = scope[scope.length - 1].nextElementSibling;
      const previousNode = walker.previousNode() as HTMLElement;
      if (previousNode) {
        focusElement(previousNode, true);
      }
      return previousNode;
    }
  };
}

const focusableElements = [
  'input:not([disabled]):not([type=hidden])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'button:not([disabled])',
  'a[href]',
  'area[href]',
  'summary',
  'iframe',
  'object',
  'embed',
  'audio[controls]',
  'video[controls]',
  '[contenteditable]'
];

const FOCUSABLE_ELEMENT_SELECTOR =
  focusableElements.join(':not([hidden]),') + ',[tabindex]:not([disabled]):not([hidden])';

focusableElements.push('[tabindex]:not([tabindex="-1"]):not([disabled])');
const TABBABLE_ELEMENT_SELECTOR = focusableElements.join(':not([hidden]):not([tabindex="-1"]),');

/**
 * Gets the root element in a specified scope of elements.
 * @param scope The scope.
 * @returns The root element.
 */
function getScopeRoot(scope: HTMLElement[]) {
  return scope[0].parentElement;
}

/**
 * Contains the focus within a specified scope. This can be useful to avoid a user focusing
 * on undesirable elements, such as the content behind a modal.
 * @param scopeRef The ref to the scope elements.
 * @param contain A toggle for the focus containment.
 */
function useFocusContainment(scopeRef: RefObject<HTMLElement[]>, contain: boolean) {
  const focusedNode = useRef<HTMLElement>();

  // The window.requestAnimationFrame() method tells the browser that you wish to perform
  // an animation and requests that the browser calls a specified function to update an
  // animation before the next repaint. The method takes a callback as an argument to be
  // invoked before the repaint. We use this ref to fix an issue in Firefox, where it does
  // not shift the focus back properly in certain situations. For more details, visit:
  // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
  const windowRequestAnimationFrame = useRef(null);

  useLayoutEffect(() => {
    const scope = scopeRef.current;
    if (!contain) {
      // if contain was changed, then we should cancel any ongoing waits to pull focus back into containment
      if (windowRequestAnimationFrame.current) {
        cancelAnimationFrame(windowRequestAnimationFrame.current);
        windowRequestAnimationFrame.current = null;
      }
      return;
    }

    // Handle the Tab key to contain focus within the scope
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key !== 'Tab' || e.altKey || e.ctrlKey || e.metaKey || scopeRef !== activeScope) {
        return;
      }

      const focusedElement = document.activeElement as HTMLElement;
      const scope = scopeRef.current;
      if (!isElementInScope(focusedElement, scope)) {
        return;
      }

      const walker = getFocusableTreeWalker(getScopeRoot(scope), {tabbable: true}, scope);
      walker.currentNode = focusedElement;
      let nextElement = (e.shiftKey ? walker.previousNode() : walker.nextNode()) as HTMLElement;
      if (!nextElement) {
        walker.currentNode = e.shiftKey ? scope[scope.length - 1].nextElementSibling : scope[0].previousElementSibling;
        nextElement = (e.shiftKey ? walker.previousNode() : walker.nextNode()) as HTMLElement;
      }

      e.preventDefault();
      if (nextElement) {
        focusElement(nextElement, true);
      }
    };

    const onFocus = (e: FocusEvent) => {
      // If focusing an element in a child scope of the currently active scope, the child becomes active.
      // Moving out of the active scope to an ancestor is not allowed.
      if (!activeScope || isAncestorScope(activeScope, scopeRef)) {
        activeScope = scopeRef;
        focusedNode.current = e.target as HTMLElement;
      } else if (scopeRef === activeScope && !isElementInChildScope(e.target as HTMLElement, scopeRef)) {
        // If a focus event occurs outside the active scope (e.g. user tabs from browser location bar),
        // restore focus to the previously focused node or the first tabbable element in the active scope.
        if (focusedNode.current) {
          focusedNode.current.focus();
        } else if (activeScope) {
          focusFirstInScope(activeScope.current);
        }
      } else if (scopeRef === activeScope) {
        focusedNode.current = e.target as HTMLElement;
      }
    };

    const onBlur = (e: FocusEvent) => {
      // Firefox doesn't shift focus back to the Dialog properly without this
      windowRequestAnimationFrame.current = requestAnimationFrame(() => {
        // Use document.activeElement instead of e.relatedTarget so we can tell if user clicked into iframe
        if (scopeRef === activeScope && !isElementInChildScope(document.activeElement, scopeRef)) {
          activeScope = scopeRef;
          focusedNode.current = e.target as HTMLElement;
          focusedNode.current.focus();
        }
      });
    };

    document.addEventListener('keydown', onKeyDown, false);
    document.addEventListener('focusin', onFocus, false);
    scope.forEach((element) => element.addEventListener('focusin', onFocus, false));
    scope.forEach((element) => element.addEventListener('focusout', onBlur, false));
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
      document.removeEventListener('focusin', onFocus, false);
      scope.forEach((element) => element.removeEventListener('focusin', onFocus, false));
      scope.forEach((element) => element.removeEventListener('focusout', onBlur, false));
    };
  }, [scopeRef, contain]);

  useEffect(() => {
    return () => {
      if (windowRequestAnimationFrame.current) {
        cancelAnimationFrame(windowRequestAnimationFrame.current);
      }
    };
  }, [windowRequestAnimationFrame]);
}

/**
 * Checks if a specific element is contained within a set of scopes.
 * @param element The element to find.
 * @returns If the element is contained in any scope.
 */
function isElementInAnyScope(element: Element) {
  for (const scope of scopes.keys()) {
    if (isElementInScope(element, scope.current)) {
      return true;
    }
  }
  return false;
}

/**
 * Checks if an element is within a specified scope.
 * @param element The element to find.
 * @param scope The scope to find it in.
 * @returns If the element is contained in the scope.
 */
function isElementInScope(element: Element, scope: HTMLElement[]) {
  return scope.some((node) => node.contains(element));
}

/**
 * Checks if an element is within a child scope of a specified scope.
 * @param element The element.
 * @param scope The scope.
 * @returns The result of the check.
 */
function isElementInChildScope(element: Element, scope: ScopeRef) {
  // node.contains in isElementInScope covers child scopes that are also DOM children,
  // but does not cover child scopes in portals.
  for (const s of scopes.keys()) {
    if ((s === scope || isAncestorScope(scope, s)) && isElementInScope(element, s.current)) {
      return true;
    }
  }

  return false;
}

/**
 * A method that checks if the first scope is an ancestor of the second scope.
 * @param ancestor The ancestor.
 * @param scope The scope.
 * @returns The result.
 */
function isAncestorScope(ancestor: ScopeRef, scope: ScopeRef): boolean {
  const parent = scopes.get(scope);
  if (!parent) {
    return false;
  }

  if (parent === ancestor) {
    return true;
  }

  return isAncestorScope(ancestor, parent);
}

/**
 * Focuses a given element.
 * @param element The element.
 * @param scroll If the window should scroll to the element being focused.
 */
function focusElement(element: HTMLElement | null, scroll = false) {
  if (element != null && !scroll) {
    try {
      focusSafely(element);
    } catch (err) {
      // ignore
    }
  } else if (element != null) {
    try {
      element.focus();
    } catch (err) {
      // ignore
    }
  }
}

/**
 * Focuses the first element within a given scope.
 * @param scope The scope of elements.
 */
function focusFirstInScope(scope: HTMLElement[]) {
  const sentinel = scope[0].previousElementSibling;
  const walker = getFocusableTreeWalker(getScopeRoot(scope), {tabbable: true}, scope);
  walker.currentNode = sentinel;
  focusElement(walker.nextNode() as HTMLElement);
}

/**
 * A hook to automatically focus the first element within a scope.
 * @param scopeRef The ref to the scope.
 * @param autoFocus If the element should be automatically focused.
 */
export function useAutoFocus(scopeRef: RefObject<HTMLElement[]>, autoFocus: boolean) {
  const autoFocusRef = useRef(autoFocus);
  useEffect(() => {
    if (autoFocusRef.current) {
      activeScope = scopeRef;
      if (!isElementInScope(document.activeElement, activeScope.current)) {
        focusFirstInScope(scopeRef.current);
      }
    }
    autoFocusRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

/**
 * Automatically restore the focus to the last active element.
 * @param scopeRef A ref to the scope of elements.
 * @param restoreFocus If the focus should be restored.
 * @param contain If the focus should be contained within the scope.
 */
export function useRestoreFocus(scopeRef: RefObject<HTMLElement[]>, restoreFocus: boolean, contain: boolean) {
  // Create a ref during render instead of useLayoutEffect so the active element is saved before a child with autoFocus=true mounts.
  const nodeToRestoreRef = useRef(typeof document !== 'undefined' ? (document.activeElement as HTMLElement) : null);

  // useLayoutEffect instead of useEffect so the active element is saved synchronously instead of asynchronously.
  useLayoutEffect(() => {
    let nodeToRestore = nodeToRestoreRef.current;
    if (!restoreFocus) {
      return;
    }

    /**
     * Handle the Tab key so that tabbing out of the scope goes to the next element
     * after the node that had focus when the scope mounted. This is important when
     * using portals for overlays, so that focus goes to the expected element when
     * tabbing out of the overlay.
     * @param event The event to handle.
     */
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key !== 'Tab' || event.altKey || event.ctrlKey || event.metaKey) {
        return;
      }

      const focusedElement = document.activeElement as HTMLElement;
      if (!isElementInScope(focusedElement, scopeRef.current)) {
        return;
      }

      // Create a DOM tree walker that matches all tabbable elements
      const walker = getFocusableTreeWalker(document.body, {tabbable: true});

      // Find the next tabbable element after the currently focused element
      walker.currentNode = focusedElement;
      let nextElement = (event.shiftKey ? walker.previousNode() : walker.nextNode()) as HTMLElement;

      if (!document.body.contains(nodeToRestore) || nodeToRestore === document.body) {
        nodeToRestore = null;
      }

      // If there is no next element, or it is outside the current scope, move focus to the
      // next element after the node to restore to instead.
      if ((!nextElement || !isElementInScope(nextElement, scopeRef.current)) && nodeToRestore) {
        walker.currentNode = nodeToRestore;

        // Skip over elements within the scope, in case the scope immediately follows the node to restore.
        do {
          nextElement = (event.shiftKey ? walker.previousNode() : walker.nextNode()) as HTMLElement;
        } while (isElementInScope(nextElement, scopeRef.current));

        event.preventDefault();
        event.stopPropagation();
        if (nextElement) {
          focusElement(nextElement, true);
        } else {
          // If there is no next element and the elementToRestore isn't within a FocusScope (i.e. we are leaving the top level focus scope)
          // then move focus to the body.
          // Otherwise restore focus to the elementToRestore (e.g menu within a popover -> tabbing to close the menu should move focus to menu trigger)
          if (!isElementInAnyScope(nodeToRestore)) {
            focusedElement.blur();
          } else {
            focusElement(nodeToRestore, true);
          }
        }
      }
    };

    if (!contain) {
      document.addEventListener('keydown', onKeyDown, true);
    }

    return () => {
      if (!contain) {
        document.removeEventListener('keydown', onKeyDown, true);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (restoreFocus && nodeToRestore && isElementInScope(document.activeElement, scopeRef.current)) {
        requestAnimationFrame(() => {
          // Only restore focus if we've lost focus to the body, the alternative is that focus has been purposefully moved elsewhere
          if (document.body.contains(nodeToRestore) && document.activeElement === document.body) {
            focusElement(nodeToRestore);
          }
        });
      }
    };
  }, [scopeRef, restoreFocus, contain]);
}

/**
 * Create a [TreeWalker]{@link https://developer.mozilla.org/en-US/docs/Web/API/TreeWalker}
 * that matches all focusable/tabbable elements.
 */
export function getFocusableTreeWalker(root: HTMLElement, opts?: FocusManagerOptions, scope?: HTMLElement[]) {
  const selector = opts?.tabbable ? TABBABLE_ELEMENT_SELECTOR : FOCUSABLE_ELEMENT_SELECTOR;
  const walker = document.createTreeWalker(root, NodeFilter.SHOW_ELEMENT, {
    acceptNode(node) {
      // Skip nodes inside the starting node.
      if (opts?.from?.contains(node)) {
        return NodeFilter.FILTER_REJECT;
      }

      if (
        (node as HTMLElement).matches(selector) &&
        isElementVisible(node as HTMLElement) &&
        (!scope || isElementInScope(node as HTMLElement, scope)) &&
        (!opts?.accept || opts.accept(node as Element))
      ) {
        return NodeFilter.FILTER_ACCEPT;
      }

      return NodeFilter.FILTER_SKIP;
    }
  });

  if (opts?.from) {
    walker.currentNode = opts.from;
  }

  return walker;
}

/**
 * Creates a FocusManager object that can be used to move focus within an element.
 */
export function createFocusManager(
  ref: RefObject<HTMLElement>,
  defaultOptions: FocusManagerOptions = {}
): FocusManager {
  return {
    focusNext(opts: FocusManagerOptions = {}) {
      let root = ref.current;
      if (!root) {
        return;
      }
      let {from, tabbable = defaultOptions.tabbable, wrap = defaultOptions.wrap, accept = defaultOptions.accept} = opts;
      let node = from || document.activeElement;
      let walker = getFocusableTreeWalker(root, {tabbable, accept});
      if (root.contains(node)) {
        walker.currentNode = node;
      }
      let nextNode = walker.nextNode() as HTMLElement;
      if (!nextNode && wrap) {
        walker.currentNode = root;
        nextNode = walker.nextNode() as HTMLElement;
      }
      if (nextNode) {
        focusElement(nextNode, true);
      }
      return nextNode;
    },
    focusPrevious(opts: FocusManagerOptions = defaultOptions) {
      let root = ref.current;
      if (!root) {
        return;
      }
      let {from, tabbable = defaultOptions.tabbable, wrap = defaultOptions.wrap, accept = defaultOptions.accept} = opts;
      let node = from || document.activeElement;
      let walker = getFocusableTreeWalker(root, {tabbable, accept});
      if (root.contains(node)) {
        walker.currentNode = node;
      } else {
        let next = last(walker);
        if (next) {
          focusElement(next, true);
        }
        return next;
      }
      let previousNode = walker.previousNode() as HTMLElement;
      if (!previousNode && wrap) {
        walker.currentNode = root;
        previousNode = last(walker);
      }
      if (previousNode) {
        focusElement(previousNode, true);
      }
      return previousNode;
    },
    focusFirst(opts = defaultOptions) {
      let root = ref.current;
      if (!root) {
        return;
      }
      let {tabbable = defaultOptions.tabbable, accept = defaultOptions.accept} = opts;
      let walker = getFocusableTreeWalker(root, {tabbable, accept});
      let nextNode = walker.nextNode() as HTMLElement;
      if (nextNode) {
        focusElement(nextNode, true);
      }
      return nextNode;
    },
    focusLast(opts = defaultOptions) {
      let root = ref.current;
      if (!root) {
        return;
      }
      let {tabbable = defaultOptions.tabbable, accept = defaultOptions.accept} = opts;
      let walker = getFocusableTreeWalker(root, {tabbable, accept});
      let next = last(walker);
      if (next) {
        focusElement(next, true);
      }
      return next;
    }
  };
}

function last(walker: TreeWalker) {
  let next: HTMLElement;
  let last: HTMLElement;
  do {
    last = walker.lastChild() as HTMLElement;
    if (last) {
      next = last;
    }
  } while (last);
  return next;
}
