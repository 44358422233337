import axios, {AxiosResponse} from 'axios';
import {differenceInMilliseconds, differenceInSeconds, fromUnixTime} from 'date-fns';
import jwtDecode from 'jwt-decode';
import {FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {InperiumAuthContext} from '../context/InperiumAuthContext';
import {generateUuid} from '../deprecated/generateUuid';
import {getCookie} from '../deprecated/getCookie';
import {never} from '../deprecated/never';
import {useCookie} from '../deprecated/useCookie';
import {useLocalStorage} from '../deprecated/useLocalStorage';
import {usePrevious} from '../deprecated/usePrevious';
import {useAuthorizationTimers} from '../hooks/useAuthorizartionTimers';
import {IAppMessage, IAuthTokens, IIFrameMessage, ITokenClaims} from '../interfaces';
import {ILoginRequest} from '../interfaces/ILoginRequest';
import {IRefreshTokensRequest} from '../interfaces/IRefreshTokensRequest';
import {IInperiumAuthConfig} from './IInperiumAuthConfig';

export interface IInperiumAuthProviderProps extends IInperiumAuthConfig {
  /**
   * The children of the component.
   */
  children?: ReactNode;
}

const silentSSOIframeId = 'inperium-silent-sso-frame';
const cookieOptions = {
  domain: process.env.GATSBY_COOKIE_DOMAIN || process.env.REACT_APP_COOKIE_DOMAIN
};

const getTokenExpirationTime = (token: string) => {
  const decodedToken = jwtDecode<{exp: number}>(token);
  const tokenExpiration = fromUnixTime(decodedToken.exp);
  return differenceInSeconds(tokenExpiration, new Date());
};

/**
 * The Inperium authentication context provider for application-wide auth state.
 * You need to wrap your application on the highest level possible in this provider.
 * The context works in two modes: iframe and main app. To make both of them synced we use
 * window.postMessage() method which safely enables cross-origin communication
 * between Window objects. I.e. when any UI's silent authentication check happens, the
 * requesting party will send a postMessage() to the iframe, asking for the token.
 * We will then respond with another postMessage() sending the tokens back from the iframe.
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
 */
export const InperiumAuthProvider: FC<IInperiumAuthProviderProps> = ({children, ...config}) => {
  const silentSSOPageUrl = useMemo(() => `${config.hubUrl}/silent-sso`, [config.hubUrl]);

  const [iframeLocalStorageKey, setIframeLocalStorageKey] = useLocalStorage('iframeLocalStorageKey', '');

  /** The access token (JWT token) of the user. */
  const [accessToken, setAccessToken] = useCookie<string>('accessToken', '', cookieOptions);

  /** The refresh token of the user. */
  const [refreshToken, setRefreshToken] = useCookie<string>('refreshToken', '', cookieOptions);
  const prevRefreshToken = usePrevious(refreshToken);

  /** Whether the user is active. */
  const [isUserActive, setUserActive] = useState<boolean>(true);

  /** Whether the Inperium authentication instance is initialized or not. */
  const [initialized, setInitialized] = useState<boolean>(false);
  const prevInitialized = usePrevious(initialized);

  const timers = useAuthorizationTimers();

  /** This provider has logic for an iframe or a window containing it. By this flag we distinguish them. */
  const iframe = useMemo(() => (typeof window !== 'undefined' ? window.self !== window.top : false), []);

  /** The main app origin is set for the iframe when the tokens are requested for the first time. */
  const appOrigin = useRef('');

  const [isOnLine, setOnLine] = useState(typeof window !== 'undefined' ? window.navigator.onLine : false);

  const [visibility, setVisibility] = useState(typeof document !== 'undefined' ? document.visibilityState : 'hidden');

  const accessTokenClaims: ITokenClaims | undefined = useMemo(() => {
    try {
      if (accessToken) {
        return jwtDecode<ITokenClaims>(accessToken);
      }
      return undefined;
    } catch (error) {
      setAccessToken('');
      setRefreshToken('');
      // eslint-disable-next-line no-console
      console.warn(error);
      return undefined;
    }
  }, [accessToken, setAccessToken, setRefreshToken]);

  const refreshTokenClaims: ITokenClaims | undefined = useMemo(() => {
    try {
      if (refreshToken) {
        return jwtDecode<ITokenClaims>(refreshToken);
      }
      return undefined;
    } catch (error) {
      setAccessToken('');
      setRefreshToken('');
      // eslint-disable-next-line no-console
      console.warn(error);
      return undefined;
    }
  }, [refreshToken, setAccessToken, setRefreshToken]);

  const authenticated: boolean = useMemo(
    () => !!(accessTokenClaims && refreshTokenClaims),
    [accessTokenClaims, refreshTokenClaims]
  );

  /**
   * Sets the authentication tokens stored in the context and local storage.
   * @param tokens the tokens to store.
   */
  const setTokens = useCallback(
    (tokens: Partial<IAuthTokens> | null) => {
      if (tokens?.accessToken) {
        setAccessToken(tokens.accessToken, {
          ...cookieOptions,
          maxAge: getTokenExpirationTime(tokens.accessToken)
        });
      }

      if (tokens?.refreshToken) {
        setRefreshToken(tokens.refreshToken, {
          ...cookieOptions,
          maxAge: getTokenExpirationTime(tokens.refreshToken)
        });
      }

      if (!tokens || (!tokens.accessToken && !tokens.refreshToken)) {
        setAccessToken('');
        setRefreshToken('');
      }
    },
    [setAccessToken, setRefreshToken]
  );

  /**
   * A method to perform a HTTP call to login the user. On success
   * it stores the auth tokens in the local storage and updates
   * the authentication state of the InperiumAuthInstance.
   * @model The model required to login the user.
   * @redirectUri The uri to which the user should be redirected after login.
   */
  const login = async (model: ILoginRequest, redirectUri?: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      return axios
        .post<ILoginRequest, AxiosResponse<IAuthTokens>>(`auth/tokens`, model, {
          baseURL: config?.baseApiUrl
        })
        .then((response) => {
          setTokens(response.data);

          const queryRedirectUrl = new URL(window.location.href).searchParams.get('redirectUrl');
          window.location.assign(redirectUri || queryRedirectUrl || config.afterLoginRedirectUri);

          resolve();
        })
        .catch((error) => {
          setTokens(null);
          reject(error);
        });
    });
  };

  /**
   * Send a message from the main app to the iframe.
   */
  const sendIFrameRequest = useCallback(
    (type: IAppMessage['type'], request?: Omit<IAppMessage, 'type' | 'origin'>) => {
      const iframe = document.getElementById(silentSSOIframeId) as HTMLIFrameElement;
      if (iframe && iframe.contentWindow) {
        const message: IAppMessage = {
          type,
          origin: window.location.origin,
          ...request
        };

        // TODO: We might want to send the clientId here.
        setTimeout(() => {
          if (iframe?.contentWindow) {
            iframe.contentWindow.postMessage(message, silentSSOPageUrl);
          }
        }, 500);
      }
    },
    [silentSSOPageUrl]
  );

  /**
   * Send a message from the iframe to the main app.
   */
  const sendAppRequest = useCallback(
    (type: IIFrameMessage['type'], request?: Omit<IIFrameMessage, 'type' | 'origin'>) => {
      if (appOrigin.current) {
        const message: IIFrameMessage = {
          type,
          origin: window.location.origin,
          ...request
        };

        const parentWindow = window.top || window.parent;
        parentWindow.postMessage(message, appOrigin.current);
      }
    },
    []
  );

  /**
   * Refreshes the access and refresh tokens.
   */
  const refreshTokens = useCallback((): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (!refreshToken || !initialized) return reject();

      const model: IRefreshTokensRequest = {
        strategy: 'REFRESH',
        token: refreshToken
      };

      return axios
        .post<IRefreshTokensRequest, AxiosResponse<IAuthTokens>>(`auth/tokens`, model, {
          baseURL: config?.baseApiUrl
        })
        .then((response) => {
          setTokens(response.data);
          sendIFrameRequest('INPERIUM_SSO_SYNC_TOKENS_REQUEST', response.data);
          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }, [config?.baseApiUrl, initialized, refreshToken, sendIFrameRequest, setTokens]);

  /**
   * A method that redirects the user to the login page of the application.
   * @param redirectUri The uri of the login page.
   */
  const redirectToLogin = useCallback(
    (redirectUri?: string) => {
      const url = window.location.href;
      /** Small fix to not redirect to the login if the user is already there. */
      if ((redirectUri && url === redirectUri) || (!redirectUri && url === config.loginUri)) {
        return;
      }

      const loginUrl = new URL(redirectUri || config.loginUri);
      loginUrl.searchParams.append('redirectUrl', config.afterLoginRedirectUri);

      window.location.assign(loginUrl.toString());
    },
    [config.afterLoginRedirectUri, config.loginUri]
  );

  // Make sure we won't call the logout method when we are already logging out the user and setting tokens to null.
  const loggingOutGuard = useRef(false);

  /**
   * A method that logs out the currently authenticated user and
   * redirects him to a page not protected by authentication.
   * @param redirectUri The uri to which the user should be redirected after logout.
   */
  const logout = useCallback(
    (redirectUri?: string) => {
      if (!authenticated || loggingOutGuard.current) return;
      loggingOutGuard.current = true;

      sendIFrameRequest('INPERIUM_SSO_LOGOUT_REQUEST');

      const logoutUrl = new URL(config.afterLogoutRedirectUri);

      const queryRedirectUrl = redirectUri || new URL(window.location.href).searchParams.get('redirectUrl');
      if (queryRedirectUrl) {
        logoutUrl.searchParams.append('redirectUrl', queryRedirectUrl);
      }

      window.location.assign(logoutUrl.toString());

      setTokens(null);
    },
    [authenticated, config.afterLogoutRedirectUri, sendIFrameRequest, setTokens]
  );

  /**
   * This effect removes old cookies, which were set by the old version of auth flow.
   */
  const OLD_COOKIES_DELETED_FLAG = 'old_cookies_deleted';
  useEffect(() => {
    if (window.location.hostname.includes('inperium')) {
      const wereOldCookiesDeleted = getCookie(OLD_COOKIES_DELETED_FLAG);
      if (!wereOldCookiesDeleted) {
        const expiredDate = new Date(0).toUTCString();
        const oneYearAfterDate = new Date();
        oneYearAfterDate.setFullYear(oneYearAfterDate.getFullYear() + 1);

        // Old cookies were set without specifying the domain.
        // Here we also remove cookie without domain attribute.
        // So, cookie for `inperium.com` will be removed, while cookie for `.inperium.com` remains.
        document.cookie = `accessToken=; path=/; expires=${expiredDate}`;
        document.cookie = `refreshToken=; path=/; expires=${expiredDate}`;
        document.cookie = `userLastTimeActive=; path=/; expires=${expiredDate}`;
        document.cookie = `${OLD_COOKIES_DELETED_FLAG}=true; path=/; expires=${oneYearAfterDate.toUTCString()}`;
      }
    }
  }, []);

  /**
   * This effect listens to the online/offline status to refresh tokens once the user is back online.
   */
  useEffect(() => {
    const handleOnLine = () => setOnLine(true);
    const handleOffLine = () => setOnLine(false);

    window.addEventListener('online', handleOnLine);
    window.addEventListener('offline', handleOffLine);

    return () => {
      window.removeEventListener('online', handleOnLine);
      window.removeEventListener('offline', handleOffLine);
    };
  }, [refreshTokens]);

  /**
   * If the refresh token claims change a new refresh timer
   * is being attached to the providers refs. The refresh
   * timer will call refreshTokens() 10-15 seconds before the
   * access token is expired.
   */
  useEffect(() => {
    if (accessTokenClaims && !iframe && isOnLine) {
      const accessTokenExpiration = fromUnixTime(accessTokenClaims.exp);
      const accessTokenExpiresIn = differenceInMilliseconds(accessTokenExpiration, new Date());

      return timers.setTimeout(
        'refreshTokens',
        () => refreshTokens(),
        accessTokenExpiresIn - (Math.random() * 5 + 10) * 1000
      );
    }

    return () => null;
  }, [accessTokenClaims, iframe, isOnLine, refreshTokens, timers]);

  /**
   * This effect listens to the tab visibility state change and performs an immediate check if the user was inactive for too long.
   */
  useEffect(() => {
    const handleVisibilityChange = () => setVisibility(document.visibilityState);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [authenticated, logout, refreshTokenClaims, refreshTokens]);

  useEffect(() => {
    if (visibility === 'visible' && authenticated && refreshTokenClaims) {
      const refreshTokenExpiration = fromUnixTime(refreshTokenClaims.exp);
      const isRefreshTokenExpired = differenceInMilliseconds(refreshTokenExpiration, new Date()) <= 0;

      if (isRefreshTokenExpired) {
        logout();
      }
    }
  }, [authenticated, logout, refreshTokenClaims, visibility]);

  /**
   * This effects sets and updates the login timing out and log out timers.
   * Only if the tab is active.
   */
  useEffect(() => {
    if (!iframe && authenticated && refreshTokenClaims && visibility === 'visible') {
      setUserActive(true);

      const tokenExpiration = fromUnixTime(refreshTokenClaims.exp);
      const tokenExpiresIn = differenceInMilliseconds(tokenExpiration, new Date());

      return timers.setTimeout(
        'loginTimingOut',
        () => setUserActive(false),
        tokenExpiresIn - config.logoutCountdownSeconds * 1000
      );
    }

    return () => null;
  }, [authenticated, refreshTokenClaims, config.logoutCountdownSeconds, iframe, logout, timers, visibility]);

  useEffect(() => {
    if (!isUserActive) {
      return timers.setTimeout('logout', () => logout(), config.logoutCountdownSeconds * 1000);
    }

    return () => null;
  }, [config.logoutCountdownSeconds, isUserActive, logout, timers]);

  /**
   * Attaches an iframe to the body of the app which allows us
   * to securely share data and fetch the auth tokens from
   * a different origin. For example, it allows inperium-ui-sell to
   * access the auth tokens stored in inperium-ui-auth.
   */
  const setupSilentSSOIFrame = useCallback(() => {
    return new Promise<void>((resolve) => {
      const existingIframe = document.getElementById(silentSSOIframeId) as HTMLIFrameElement;

      if (existingIframe?.contentWindow) {
        sendIFrameRequest('INPERIUM_SSO_TOKEN_REQUEST');
        return resolve();
      }

      const iframe = document.createElement('iframe');

      iframe.onload = () => {
        sendIFrameRequest('INPERIUM_SSO_TOKEN_REQUEST');
        return resolve();
      };

      iframe.setAttribute('id', silentSSOIframeId);
      iframe.setAttribute('src', silentSSOPageUrl);
      iframe.setAttribute('title', 'inperium-silent-sso-check');
      iframe.style.display = 'none';

      window.document.body.appendChild(iframe);

      return resolve();
    });
  }, [sendIFrameRequest, silentSSOPageUrl]);

  /**
   * An effect to perform the silent sso check if the auth provider
   * is not initialized yet. Before we initialize it, we always
   * check if the user is already authenticated.
   */
  useEffect(() => {
    if (!prevInitialized && !initialized && !iframe) {
      setupSilentSSOIFrame();
    }
  }, [iframe, initialized, prevInitialized, setupSilentSSOIFrame]);

  /**
   * Attaches an event listener for the postMessage events from the iframe.
   */
  useEffect(() => {
    if (iframe) return () => null;

    const listener = (event: MessageEvent): void => {
      if (event.origin !== config.hubUrl || !event.data) return;

      const message = event.data as IIFrameMessage;
      const {type} = message;

      switch (type) {
        case 'INPERIUM_SSO_TOKEN_RESPONSE': {
          // Remove silent sso iframe for the hub after initialization. Otherwise it will cause racing condition since we would have two context with the same domain on the same page.
          if (config.hubUrl === window.location.origin) {
            document.getElementById(silentSSOIframeId)?.remove();
          }

          setTokens(message);
          setInitialized(true);

          break;
        }
        default:
          never(type);
      }
    };

    window.addEventListener('message', listener, false);

    return () => window.removeEventListener('message', listener);
  }, [config.hubUrl, iframe, setTokens]);

  /**
   * Attaches an event listener for postmessage events from the iframe.
   */
  useEffect(() => {
    if (!iframe) return () => null;

    const listener = (event: MessageEvent) => {
      const message = event.data as IAppMessage;

      if (!message) return;

      const {type, origin} = message;

      switch (type) {
        case 'INPERIUM_SSO_LOGOUT_REQUEST': {
          logout();
          break;
        }
        case 'INPERIUM_SSO_TOKEN_REQUEST': {
          appOrigin.current = origin;

          // TODO: Maybe restrict to which location we post this message back!
          sendAppRequest('INPERIUM_SSO_TOKEN_RESPONSE', {
            accessToken,
            refreshToken
          });
          break;
        }
        case 'INPERIUM_SSO_SYNC_TOKENS_REQUEST': {
          setTokens(message);
          break;
        }
        default:
          never(type);
      }
    };

    window.addEventListener('message', listener, false);

    return () => window.removeEventListener('message', listener);
  }, [accessToken, iframe, logout, refreshToken, sendAppRequest, setTokens]);

  /**
   * When we're losing the refresh token, we are the user logging out from the app.
   */
  useEffect(() => {
    if (initialized && !refreshToken && prevRefreshToken) {
      logout();
    }
  }, [refreshToken, initialized, logout, prevRefreshToken]);

  /** This effects sets key for the sso iframe local storage.
   * By reading it from the main app we can check if local storage is supported.
   * @example Safari doesn't support local storage in iframe it creates a sandbox for it. In this case the main won't have the key.
   */
  useEffect(() => {
    if (iframe && !initialized) {
      setIframeLocalStorageKey(generateUuid());
    }
  }, [iframe, initialized, setIframeLocalStorageKey]);

  return (
    <InperiumAuthContext.Provider
      value={{
        accessToken,
        refreshToken,
        initialized,
        authenticated,
        accessTokenClaims,
        setTokens,
        refreshTokens,
        login,
        logout,
        redirectToLogin,
        iframe,
        iframeLocalStorageSupported: !iframe && !!iframeLocalStorageKey,
        config,
        isUserActive
      }}
    >
      {children}
    </InperiumAuthContext.Provider>
  );
};
