import {createContext, forwardRef, ReactNode, RefObject, useContext, useEffect, useMemo, useRef} from 'react';

import {I18nProvider, useLocale} from '@inperium-corp/convergo-aria-i18n';
import {ModalProvider, useModalProvider} from '@inperium-corp/convergo-aria-overlays';
import {filterDOMProps, useFallbackRef} from '@inperium-corp/convergo-aria-utils';
import {
  ColorScheme,
  cx,
  injectGlobal,
  inperiumTheme,
  InperiumThemeProvider,
  Scale,
  ThemeConfiguration,
  useColorScheme,
  useScale,
  utilsFactory
} from '@inperium-corp/convergo-react-styles';
import {DOMProps, StyleProps} from '@inperium-corp/convergo-types';

import {version} from '../package.json';

export interface ConvergoProviderContext {
  /** The package version number of the nearest parent Provider. */
  version: string;
  /** The theme of the nearest parent Provider. */
  theme: ThemeConfiguration;
  /** The color scheme of the nearest parent Provider. */
  colorScheme: ColorScheme;
  /** The scale of the nearest parent Provider. */
  scale: Scale;
}

export interface ConvergoProviderProps extends StyleProps, DOMProps {
  /** The children of the provider. */
  children: ReactNode;
  /** The theme configuration for your application. */
  theme?: ThemeConfiguration;
  /** Sets the scale for your applications. Defaults based on device pointer type. */
  scale?: Scale;
  /** The color scheme for your application. Defaults to operating system preferences. */
  colorScheme?: ColorScheme;
  /**
   * The default color scheme if no operating system setting is available.
   * @default 'light'
   */
  defaultColorScheme?: ColorScheme;
  /**
   * The locale for your application as a [BCP 47](https://www.ietf.org/rfc/bcp/bcp47.txt) language code.
   * Defaults to the browser/OS language setting.
   */
  locale?: string;
}

injectGlobal({
  html: {
    fontSize: '62.5%'
  }
});

const ConvergoContext = createContext<ConvergoProviderContext | null>(null);

export const ConvergoProvider = forwardRef((props: ConvergoProviderProps, ref?: RefObject<HTMLDivElement>) => {
  const prevContext = useConvergoProvider();
  const prevColorScheme = prevContext ? prevContext.colorScheme : null;
  const {theme = prevContext ? prevContext.theme : inperiumTheme, defaultColorScheme} = props;
  // Hooks must always be called.
  const autoColorScheme = useColorScheme(theme, defaultColorScheme);
  const autoScale = useScale(theme);
  const {locale: prevLocale} = useLocale();
  // if the new theme doesn't support the prevColorScheme, we must resort to the auto
  const usePrevColorScheme = !!theme[prevColorScheme];

  // importance of color scheme props > parent > auto:(OS > default > omitted)
  const {
    colorScheme = usePrevColorScheme ? prevColorScheme : autoColorScheme,
    scale = prevContext ? prevContext.scale : autoScale,
    locale = prevContext ? prevLocale : null,
    children,
    ...otherProps
  } = props;

  // select only the props with values so undefined props don't overwrite prevContext values
  const currentProps = {
    version,
    theme,
    colorScheme,
    scale
  };

  const filteredProps: any = {};
  Object.entries(currentProps).forEach(([key, value]) => value !== undefined && (filteredProps[key] = value));

  // Merge options with parent provider
  const context = Object.assign({}, prevContext, filteredProps);

  // Only wrap in a DOM node if the theme, colorScheme, or scale changed
  let contents = children;
  let domProps = filterDOMProps(otherProps);
  if (
    !prevContext ||
    props.locale ||
    theme !== prevContext.theme ||
    colorScheme !== prevContext.colorScheme ||
    scale !== prevContext.scale ||
    Object.keys(domProps).length > 0 ||
    otherProps.className
  ) {
    contents = (
      <ConvergoProviderWrapper
        {...props}
        style={{isolation: !prevContext ? 'isolate' : undefined, ...otherProps.style}}
        ref={ref}
      >
        {contents}
      </ConvergoProviderWrapper>
    );
  }

  return (
    <ConvergoContext.Provider value={context}>
      <I18nProvider locale={locale}>
        <ModalProvider>{contents}</ModalProvider>
      </I18nProvider>
    </ConvergoContext.Provider>
  );
});

const ConvergoProviderWrapper = forwardRef((props: ConvergoProviderProps, ref?: RefObject<HTMLDivElement>) => {
  const {children, ...otherProps} = props;

  ref = useFallbackRef(ref);
  const {locale, writingDirection} = useLocale();
  const {theme, colorScheme, scale} = useConvergoProvider();

  const {modalProviderProps} = useModalProvider();

  const className = cx(props.className, 'convergo', `convergo-${colorScheme}`, `convergo-${scale}`);

  const style = {
    ...props.style,
    // This ensures that browser native UI like scrollbars are rendered in the right color scheme.
    // See https://web.dev/color-scheme/.
    colorScheme:
      props.colorScheme ??
      colorScheme ??
      Object.keys(theme)
        .filter((k) => k === 'light' || k === 'dark')
        .join(' ')
  };

  let hasWarned = useRef(false);
  useEffect(() => {
    if (writingDirection && ref.current) {
      let closestDir = ref.current.parentElement.closest('[dir]');
      let dir = closestDir && closestDir.getAttribute('dir');
      if (dir && dir !== writingDirection && !hasWarned.current) {
        console.warn(`Language directions cannot be nested. ${writingDirection} inside ${dir}.`);
        hasWarned.current = true;
      }
    }
  }, [writingDirection, ref, hasWarned]);

  const providerTheme = useMemo(
    () => ({
      colorScheme: theme[colorScheme],
      scale: theme[scale],
      global: theme.global,
      utils: utilsFactory(theme[colorScheme], theme[scale])
    }),
    [theme, colorScheme, scale]
  );

  return (
    <div
      {...filterDOMProps(otherProps)}
      {...modalProviderProps}
      className={className}
      style={style}
      lang={locale}
      dir={writingDirection}
      ref={ref}
    >
      <InperiumThemeProvider theme={providerTheme}>{children}</InperiumThemeProvider>
    </div>
  );
});

export function useConvergoProvider(): ConvergoProviderContext {
  return useContext(ConvergoContext);
}
