import {useMemo} from 'react';

import {Replace, WritingDirection} from '@inperium-corp/convergo-types';

/**
 * A method that translates the placement of the overlay properly for locales
 * that are read from right-to-left (RTL). This reverses many aspects of the
 * position from the original left-to-right (LTR) locale.
 * @param placement The original placement of the overlay for the LTR language.
 * @param direction The direction of the overlay.
 * @returns The translated placement.
 */
export function translatePlacement<T extends string>(
  placement: T,
  direction: WritingDirection
): Replace<Replace<T, ['start', 'right']>, ['end', 'left']> | Replace<Replace<T, ['start', 'left']>, ['end', 'right']> {
  if (direction === 'rtl') {
    return placement.replace('start', 'right').replace('end', 'left') as Replace<
      Replace<T, ['start', 'right']>,
      ['end', 'left']
    >;
  }

  return placement.replace('start', 'left').replace('end', 'right') as Replace<
    Replace<T, ['start', 'left']>,
    ['end', 'right']
  >;
}

export interface PlacementTranslationProps<T extends string> {
  /**
   * The element's placement relative to the target element.
   */
  placement: T;

  /**
   * The writing direction of the locale.
   */
  direction: WritingDirection;
}

export const usePlacementTranslation = <T extends string>(props: PlacementTranslationProps<T>) => {
  const {placement, direction} = props;

  return useMemo(() => translatePlacement(placement, direction), [placement, direction]);
};
