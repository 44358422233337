import {HTMLAttributes, MouseEvent, useEffect, useMemo, useRef, useState} from 'react';

import {HoverEvent, HoverEvents, PointerType} from '@inperium-corp/convergo-types';

export interface HoverAriaProps extends HoverEvents {
  /**
   * Whether the hover events should be disabled.
   */
  isDisabled?: boolean;
}

export interface HoverAriaState {
  /**
   * Whether the current element is hovered.
   */
  isHovered: boolean;
}

export interface HoverAria {
  /**
   * Hover props to spread on the target element.
   */
  hoverProps: HTMLAttributes<HTMLElement>;

  /**
   * The state of the hovered element.
   */
  hoverState: HoverAriaState;
}

// iOS fires onPointerEnter twice: once with pointerType="touch" and again with pointerType="mouse".
// We want to ignore these emulated events so they do not trigger hover behavior.
// See https://bugs.webkit.org/show_bug.cgi?id=214609.
let globalIgnoreEmulatedMouseEvents = false;
let hoverCount = 0;

/**
 * Sets the flag to globally ignore emulated events to true.
 */
function setGlobalIgnoreEmulatedMouseEvents() {
  globalIgnoreEmulatedMouseEvents = true;

  // Clear globalIgnoreEmulatedMouseEvents after a short timeout. iOS fires onPointerEnter
  // with pointerType="mouse" immediately after onPointerUp and before onFocus. On other
  // devices that don't have this quirk, we don't want to ignore a mouse hover sometime in
  // the distant future because a user previously touched the element.
  setTimeout(() => {
    globalIgnoreEmulatedMouseEvents = false;
  }, 50);
}

/**
 * A method to handle events for the pointer modality properly.
 * @param event The event to handle.
 */
function handleGlobalPointerEvent(event: PointerEvent) {
  if (event.pointerType === 'touch') {
    setGlobalIgnoreEmulatedMouseEvents();
  }
}

/**
 * A method to setup global touch events in the document by
 * attaching several event listeners.
 */
function setupGlobalTouchEvents() {
  if (typeof document === 'undefined') {
    return;
  }

  if (typeof PointerEvent !== 'undefined') {
    document.addEventListener('pointerup', handleGlobalPointerEvent);
  } else {
    document.addEventListener('touchend', setGlobalIgnoreEmulatedMouseEvents);
  }

  hoverCount++;
  return () => {
    hoverCount--;
    if (hoverCount > 0) {
      return;
    }

    if (typeof PointerEvent !== 'undefined') {
      document.removeEventListener('pointerup', handleGlobalPointerEvent);
    } else {
      document.removeEventListener('touchend', setGlobalIgnoreEmulatedMouseEvents);
    }
  };
}

/**
 * Handles pointer hover interactions for an element. Normalizes behavior across
 * browsers and platforms, and ignores emulated mouse events on touch devices.
 * @param props The props to configure the hook.
 * @returns The hover aria properties.
 */
export function useHover(props: HoverAriaProps): HoverAria {
  const {isDisabled, onHoverStart, onHoverChange, onHoverEnd} = props;

  const [isHovered, setHovered] = useState(false);
  const {current: state} = useRef({
    isHovered: false,
    ignoreEmulatedMouseEvents: false,
    pointerType: '',
    target: null
  });

  useEffect(setupGlobalTouchEvents, []);

  const {hoverProps, triggerHoverEnd} = useMemo(() => {
    const triggerHoverStart = (event: MouseEvent, pointerType: PointerType) => {
      state.pointerType = pointerType;
      if (
        isDisabled ||
        pointerType === 'touch' ||
        state.isHovered ||
        !event.currentTarget.contains(event.target as HTMLElement)
      ) {
        return;
      }

      state.isHovered = true;
      const target = event.currentTarget;
      state.target = target;

      if (onHoverStart) {
        onHoverStart({
          type: 'hoverstart',
          target: target as HTMLElement,
          pointerType: pointerType as HoverEvent['pointerType']
        });
      }

      if (onHoverChange) {
        onHoverChange(true);
      }

      setHovered(true);
    };

    const triggerHoverEnd = (event: MouseEvent, pointerType: PointerType) => {
      state.pointerType = '';
      state.target = null;

      if (pointerType === 'touch' || !state.isHovered) {
        return;
      }

      state.isHovered = false;
      const target = event.currentTarget;
      if (onHoverEnd) {
        onHoverEnd({
          type: 'hoverend',
          target: target as HTMLElement,
          pointerType: pointerType as HoverEvent['pointerType']
        });
      }

      if (onHoverChange) {
        onHoverChange(false);
      }

      setHovered(false);
    };

    const hoverProps: HTMLAttributes<HTMLElement> = {};

    if (typeof PointerEvent !== 'undefined') {
      hoverProps.onPointerEnter = (e) => {
        if (globalIgnoreEmulatedMouseEvents && e.pointerType === 'mouse') {
          return;
        }

        triggerHoverStart(e, e.pointerType);
      };

      hoverProps.onPointerLeave = (e) => {
        if (!isDisabled && e.currentTarget.contains(e.target as HTMLElement)) {
          triggerHoverEnd(e, e.pointerType);
        }
      };
    } else {
      hoverProps.onTouchStart = () => {
        state.ignoreEmulatedMouseEvents = true;
      };

      hoverProps.onMouseEnter = (e) => {
        if (!state.ignoreEmulatedMouseEvents && !globalIgnoreEmulatedMouseEvents) {
          triggerHoverStart(e, 'mouse');
        }

        state.ignoreEmulatedMouseEvents = false;
      };

      hoverProps.onMouseLeave = (e) => {
        if (!isDisabled && e.currentTarget.contains(e.target as HTMLElement)) {
          triggerHoverEnd(e, 'mouse');
        }
      };
    }
    return {hoverProps, triggerHoverEnd};
  }, [onHoverStart, onHoverChange, onHoverEnd, isDisabled, state]);

  useEffect(() => {
    // Call the triggerHoverEnd as soon as isDisabled changes to true
    // Safe to call triggerHoverEnd, it will early return if we aren't currently hovering
    if (isDisabled) {
      triggerHoverEnd({currentTarget: state.target} as MouseEvent, state.pointerType as PointerType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled]);

  return {
    hoverProps,
    hoverState: {
      isHovered
    }
  };
}
