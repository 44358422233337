import {IconName, IconSvgPaths} from '@inperium-corp/icons';

/**
 * Renders the `<path>` elements for an icon. It returns `null` if the icon is unknown.
 * @param iconName The name of the icon.
 * @returns The `<path>` elements of the icon.
 */
export function renderSvgPaths(iconName: IconName): JSX.Element[] | null {
  const pathStrings = IconSvgPaths[iconName] || null;

  if (pathStrings == null) {
    return null;
  }

  return pathStrings.map((props, i) => <path key={i} {...props} fillRule='evenodd' />);
}
