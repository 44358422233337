import {chainFunctions} from './chainFunctions';
import {mergeClassNames} from './mergeClassNames';
import {mergeIds} from './mergeIds';

interface Props {
  [key: string]: any;
}

type TupleTypes<T> = {[P in keyof T]: T[P]} extends {[key: number]: infer V} ? V : never;
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

/**
 * Merges multiple props objects together. Event handlers are chained,
 * classNames are combined, and ids are deduplicated. For all other props,
 * the last prop object overrides all previous ones.
 * @param args Multiple sets of props to merge together.
 */
export function mergeProps<T extends Props[]>(...args: T): UnionToIntersection<TupleTypes<T>> {
  return joinProps('full', ...args);
}

/**
 * Joins multiple props objects together. Event handlers are chained,
 * classNames are combined, and ids are deduplicated. For all other props,
 * the last prop object overrides all previous ones.
 * @param join Join direction.
 * @param args Multiple sets of props to merge together.
 */
export function joinProps<T extends Props[]>(
  join: 'left' | 'right' | 'inner' | 'full' = 'full',
  ...args: T
): UnionToIntersection<TupleTypes<T>> {
  if (join === 'right') {
    args.reverse();
    join = 'left';
  }

  let result: Props;

  for (let i = 1; i < args.length; i++) {
    const left = result || args[i - 1] || {};
    const right = args[i] || {};

    result = result || {};

    const keys = [...Object.keys(left), ...Object.keys(right)].filter(
      (value, index, self) => self.indexOf(value) === index
    );

    for (const key of keys) {
      const a = left[key];
      const b = right[key];

      if (!(key in left) && join === 'left') {
        continue;
      }

      if ((!(key in left) || !(key in right)) && join === 'inner') {
        continue;
      }

      // Chain events
      if (
        typeof a === 'function' &&
        typeof b === 'function' &&
        // This is a lot faster than a regex.
        key[0] === 'o' &&
        key[1] === 'n' &&
        key.charCodeAt(2) >= /* 'A' */ 65 &&
        key.charCodeAt(2) <= /* 'Z' */ 90
      ) {
        result[key] = chainFunctions(a, b);

        // Merge classnames, sometimes classNames are empty string which eval to false, so we just need to do a type check
      } else if (
        (key === 'className' || key === 'UNSAFE_className') &&
        typeof a === 'string' &&
        typeof b === 'string'
      ) {
        result[key] = mergeClassNames(a, b);
      } else if (key === 'id' && a && b) {
        result[key] = mergeIds(a, b);
      } else {
        // Override others
        result[key] = b !== undefined ? b : a;
      }
    }
  }

  return (result || args[0]) as UnionToIntersection<TupleTypes<T>>;
}
