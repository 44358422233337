import {useMessageFormatter} from '@inperium-corp/convergo-aria-i18n';
import {useMergedAriaLabels} from '@inperium-corp/convergo-aria-utils';
import {VisuallyHidden} from '@inperium-corp/convergo-aria-visually-hidden';
import {AriaLabelingProps, DOMProps} from '@inperium-corp/convergo-types';

import i18nMessages from '../i18n/*.json';

export interface DismissButtonProps extends AriaLabelingProps, DOMProps {
  /**
   * A method executed when the dismiss button is clicked.
   */
  onDismiss?: () => void;
}

/**
 * A visually hidden button that can be used to allow screen reader
 * users to dismiss a modal or popup when there is no visual
 * affordance to do so.
 */
export function DismissButton(props: DismissButtonProps) {
  const {onDismiss, ...otherProps} = props;
  const formatMessage = useMessageFormatter(i18nMessages);

  const labels = useMergedAriaLabels(otherProps, formatMessage('dismiss'));

  const onClick = () => {
    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <VisuallyHidden>
      <button {...labels} tabIndex={-1} onClick={onClick} />
    </VisuallyHidden>
  );
}
