import {useContext} from 'react';

import {IInperiumAuthInstance} from './IInperiumAuthInstance';
import {InperiumAuthContext} from './InperiumAuthContext';

/**
 * Use this hook to access the Inperium authentication instance.
 */
export function useInperiumAuth(): IInperiumAuthInstance {
  return useContext(InperiumAuthContext);
}
