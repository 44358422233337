import {useMemo} from 'react';
import {useIdGenerator} from './useIdGenerator';

/**
 * Generates a unique ID that is safe to be used in an UniqueIdentifierContext.
 * @param defaultId The default id to be used if provided.
 * @returns A unique ID string.
 */
export function useId(defaultId?: string): string {
  const generate = useIdGenerator();

  return useMemo(() => generate(defaultId), [defaultId, generate]);
}
