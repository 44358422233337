export const permissions = [
  // Inperium Sell permissions
  'SELL_PAYMENT_CENTER_ACCESS',
  'SELL_COMPANY_ACCESS_UNASSIGNED',
  'SELL_COMPANY_CREATE',
  'SELL_COMPANY_DELETE_ALL',
  'SELL_COMPANY_DELETE_OWNED',
  'SELL_COMPANY_DELETE_TEAM',
  'SELL_COMPANY_EXPORT',
  'SELL_COMPANY_GET_ALL',
  'SELL_COMPANY_GET_OWNED',
  'SELL_COMPANY_GET_TEAM',
  'SELL_COMPANY_IMPORT',
  'SELL_COMPANY_UPDATE_ALL',
  'SELL_COMPANY_UPDATE_OWNED',
  'SELL_COMPANY_UPDATE_TEAM',
  'SELL_CONTACT_ACCESS_UNASSIGNED',
  'SELL_CONTACT_CREATE',
  'SELL_CONTACT_DELETE_ALL',
  'SELL_CONTACT_DELETE_OWNED',
  'SELL_CONTACT_DELETE_TEAM',
  'SELL_CONTACT_EXPORT',
  'SELL_CONTACT_GET_ALL',
  'SELL_CONTACT_GET_OWNED',
  'SELL_CONTACT_GET_TEAM',
  'SELL_CONTACT_IMPORT',
  'SELL_CONTACT_UPDATE_ALL',
  'SELL_CONTACT_UPDATE_OWNED',
  'SELL_CONTACT_UPDATE_TEAM',
  'SELL_DASHBOARD_VIEW_ALL',
  'SELL_DASHBOARD_VIEW_OWNED',
  'SELL_DASHBOARD_VIEW_TEAM',
  'SELL_DEAL_ACCESS_UNASSIGNED',
  'SELL_DEAL_CREATE',
  'SELL_DEAL_DELETE_ALL',
  'SELL_DEAL_DELETE_OWNED',
  'SELL_DEAL_DELETE_TEAM',
  'SELL_DEAL_EXPORT',
  'SELL_DEAL_GET_ALL',
  'SELL_DEAL_GET_OWNED',
  'SELL_DEAL_GET_TEAM',
  'SELL_DEAL_IMPORT',
  'SELL_DEAL_UPDATE_ALL',
  'SELL_DEAL_UPDATE_OWNED',
  'SELL_DEAL_UPDATE_TEAM',
  'SELL_MANAGE_PRODUCT_SETTINGS',
  'SELL_REPORTS_VIEW_ALL',
  'SELL_REPORTS_VIEW_OWNED',
  'SELL_REPORTS_VIEW_TEAM',
  // Inperium Talk permissions
  'TALK_DASHBOARD_VIEW_ALL',
  'TALK_DASHBOARD_VIEW_TEAM',
  'TALK_DASHBOARD_VIEW_OWNED',
  'TALK_INTERNATIONAL_SERVICE',
  'TALK_MANAGE_PRODUCT_SETTINGS',
  'TALK_REPORTS_VIEW_ALL',
  'TALK_REPORTS_VIEW_TEAM',
  'TALK_REPORTS_VIEW_OWNED'
] as const;

export type Permission = typeof permissions[number];

/**
 * A type guard to check if a string is a valid permission.
 * @param permission the permission to check.
 * @returns true if the permission is valid, false otherwise.
 */
export function isPermission(permission: Permission): permission is Permission {
  return permissions.includes(permission);
}
