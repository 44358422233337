import {HTMLAttributes, useRef, useState} from 'react';

import {useLayoutEffect} from '@inperium-corp/convergo-aria-ssr';
import {filterDOMProps} from '@inperium-corp/convergo-aria-utils';
import {css, Theme, useStyles} from '@inperium-corp/convergo-react-styles';

export type PopoverArrowDirection = 'top' | 'bottom' | 'right' | 'left';

export interface PopoverArrowProps extends HTMLAttributes<SVGSVGElement> {
  /**
   * The direction of the arrow placement.
   */
  direction: PopoverArrowDirection;
}

export const popoverArrowStylesFactory = (theme: Theme) => ({
  root: css`
    position: absolute;
    transform: translate(0, 0);
    --popover-tip-size: 16px;
    --popover-tip-borderWidth: 2px;
  `,
  triangle: css`
    box-shadow: 0 1.6rem 4.8rem rgba(0, 0, 0, 0.175);
    fill: ${theme.global.static.colors.white};
    stroke: ${theme.global.static.colors.white};
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: square;
  `
});

const ROOT_2 = Math.sqrt(2);

export function PopoverArrow(props: PopoverArrowProps) {
  const {direction, style} = props;

  const styles = useStyles(popoverArrowStylesFactory);

  const [size, setSize] = useState(20);
  const [borderWidth, setBorderWidth] = useState(1);
  const ref = useRef();

  // Get the css value for the tip size and divide it by 2 for this arrow implementation
  useLayoutEffect(() => {
    if (ref.current) {
      // TODO: Figure out a way to get the real className
      const tipWidth = window.getComputedStyle(ref.current).getPropertyValue('--popover-tip-size');
      if (tipWidth !== '') {
        setSize(parseInt(tipWidth, 10) / 2);
      }

      // TODO: Figure out a way to get the real className
      const borderWidth = window.getComputedStyle(ref.current).getPropertyValue('--popover-tip-borderWidth');
      if (borderWidth !== '') {
        setBorderWidth(parseInt(borderWidth, 10));
      }
    }
  }, [ref]);

  const landscape = direction === 'top' || direction === 'bottom';
  const mirror = direction === 'left' || direction === 'top';

  const borderDiagonal = borderWidth * ROOT_2;
  const halfBorderDiagonal = borderDiagonal / 2;

  const secondary = 2 * size + 2 * borderDiagonal;
  const primary = size + borderDiagonal;

  const primaryStart = mirror ? primary : 0;
  const primaryEnd = mirror ? halfBorderDiagonal : primary - halfBorderDiagonal;

  const secondaryStart = halfBorderDiagonal;
  const secondaryMiddle = secondary / 2;
  const secondaryEnd = secondary - halfBorderDiagonal;

  const pathData = landscape
    ? ['M', secondaryStart, primaryStart, 'L', secondaryMiddle, primaryEnd, 'L', secondaryEnd, primaryStart]
    : ['M', primaryStart, secondaryStart, 'L', primaryEnd, secondaryMiddle, 'L', primaryStart, secondaryEnd];

  /* Use ceil because the svg needs to always accomodate the path inside it */
  return (
    <svg
      xmlns='http://www.w3.org/svg/2000'
      width={Math.ceil(landscape ? secondary : primary)}
      height={Math.ceil(landscape ? primary : secondary)}
      style={style}
      className={styles.root}
      ref={ref}
      {...filterDOMProps(props)}
    >
      <path className={styles.triangle} d={pathData.join(' ')} />
    </svg>
  );
}
