import {ElementType, forwardRef, HTMLAttributes, ReactNode, RefObject} from 'react';

import {SlotProps, StyleProps} from '@inperium-corp/convergo-types';

import {useSlotProps} from './SlotsContext';

export interface TemplateProps extends StyleProps, SlotProps, HTMLAttributes<HTMLElement> {
  /**
   * The HTML element or React element used to render the layout component, e.g. 'div', 'a'.
   */
  elementType?: ElementType;

  /**
   * The content to be rendered in the element.
   */
  children: ReactNode;
}

export const Template = forwardRef(
  ({slotName, elementType, ...restProps}: TemplateProps, ref: RefObject<HTMLElement>) => {
    const props = useSlotProps<TemplateProps>(slotName, restProps);
    const {className, children, elementType: ElementType = elementType, ...otherProps} = props;

    return (
      <ElementType {...otherProps} ref={ref} className={className}>
        {children}
      </ElementType>
    );
  }
);
