// Portions of the code in this file are based on code from ICU.

import {CalendarDate} from '../CalendarDate';
import {AnyCalendarDate} from '../types';
import {Mutable} from '../utils';
import {fromExtendedYear, getExtendedYear, GregorianCalendar} from './GregorianCalendar';

const TAIWAN_ERA_START = 1911;

function gregorianYear(date: AnyCalendarDate) {
  return date.era === 'minguo' ? date.year + TAIWAN_ERA_START : 1 - date.year + TAIWAN_ERA_START;
}

function gregorianToTaiwan(year: number): [string, number] {
  const y = year - TAIWAN_ERA_START;
  if (y > 0) {
    return ['minguo', y];
  } else {
    return ['before_minguo', 1 - y];
  }
}

/**
 * The Taiwanese calendar is the same as the Gregorian calendar, but years
 * are numbered starting from 1912 (Gregorian). Two eras are supported:
 * 'before_minguo' and 'minguo'.
 */
export class TaiwanCalendar extends GregorianCalendar {
  identifier = 'roc'; // Republic of China

  fromJulianDay(jd: number): CalendarDate {
    const date = super.fromJulianDay(jd);
    const extendedYear = getExtendedYear(date.era, date.year);
    const [era, year] = gregorianToTaiwan(extendedYear);
    return new CalendarDate(this, era, year, date.month, date.day);
  }

  toJulianDay(date: AnyCalendarDate) {
    return super.toJulianDay(toGregorian(date));
  }

  getEras() {
    return ['before_minguo', 'minguo'];
  }

  balanceDate(date: Mutable<AnyCalendarDate>) {
    const [era, year] = gregorianToTaiwan(gregorianYear(date));
    date.era = era;
    date.year = year;
  }

  isInverseEra(date: AnyCalendarDate): boolean {
    return date.era === 'before_minguo';
  }

  getDaysInMonth(date: AnyCalendarDate): number {
    return super.getDaysInMonth(toGregorian(date));
  }

  getYearsInEra(date: AnyCalendarDate): number {
    return date.era === 'before_minguo' ? 9999 : 9999 - TAIWAN_ERA_START;
  }
}

function toGregorian(date: AnyCalendarDate) {
  const [era, year] = fromExtendedYear(gregorianYear(date));
  return new CalendarDate(era, year, date.month, date.day);
}
