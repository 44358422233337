import {AriaAttributes, useContext} from 'react';

import {ModalContext} from './ModalContext';

interface ModalProviderAria {
  /**
   * Props to be spread on the container element.
   */
  modalProviderProps: AriaAttributes;
}

/**
 * Used to determine if the tree should be aria-hidden based on how many
 * modals are open.
 * @returns The props that should be applied to the modal provider.
 */
export function useModalProvider(): ModalProviderAria {
  const context = useContext(ModalContext);

  return {
    modalProviderProps: {
      'aria-hidden': context && context.modalCount > 0 ? true : null
    }
  };
}
