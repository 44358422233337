import {IAuthError} from '../interfaces/IAuthError';
import {AuthErrorType} from '../types/AuthErrorType';

/**
 * A custom error class to properly handle server side auth errors
 * and supply the components with additional information.
 */
export class AuthError extends Error {
  /**
   * The error message sent by the server.
   */
  message: string;

  /**
   * The HTTP status code sent by the server.
   */
  status: number;

  /**
   * The type of error sent by the server.
   * Indicates why the authentication failed.
   */
  type: AuthErrorType;

  constructor(error: IAuthError) {
    super(error.response.data.message);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, AuthError.prototype);

    this.message = error.response.data.message;
    this.status = error.response.data.status;
    this.type = error.response.data.type;
  }
}
