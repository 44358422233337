import {useMediaQuery} from '@inperium-corp/convergo-aria-utils';

import {Scale, ThemeConfiguration} from './vars/types';

/**
 * A hook to get the active scale to use for the styles.
 * @param theme The theme configuration of the application.
 * @returns The scale to use.
 */
export function useScale(theme: ThemeConfiguration): Scale {
  let matchesFine = useMediaQuery('(any-pointer: fine)');
  if (matchesFine && theme.medium) {
    return 'medium';
  }

  if (theme.large) {
    return 'large';
  }

  return 'medium';
}
