import {MutableRefObject, RefObject, useRef} from 'react';

export function useFallbackRef<T>(initialValue: MutableRefObject<T>): MutableRefObject<T>;

export function useFallbackRef<T>(initialValue: RefObject<T>): RefObject<T>;

/**
 * This hook allows to initialize a ref with another ref instance.
 * If there is no ref it will fallback and always return a ref object.
 */
export function useFallbackRef<T>(ref?: MutableRefObject<T> | RefObject<T>) {
  const fallbackRef = useRef<T>();
  return ref || fallbackRef;
}
