import getCss from 'dom-helpers/css';
import getOffset from 'dom-helpers/offset';
import ownerDocument from 'dom-helpers/ownerDocument';
import getPosition from 'dom-helpers/position';
import getScrollLeft from 'dom-helpers/scrollLeft';
import getScrollTop from 'dom-helpers/scrollTop';

import {
  Alignment,
  Direction,
  HorizontalDirection,
  RectDimension,
  Split,
  TranslatedAlignment,
  TranslatedPlacement,
  VerticalDirection
} from '@inperium-corp/convergo-types';

type Position = {
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
};

type Dimensions = {
  width: number;
  height: number;
  top: number;
  left: number;
  scroll: Position;
};

type ParsedPlacement = {
  /**
   * The direction the overlay will open in.
   */
  direction: Direction;

  /**
   * The alignment of the overlay. If the overlay direction is vertical it can be aligned horizontally and vece versa.
   */
  alignment: TranslatedAlignment;

  /**
   * The offset property name for the direction to calculate the position of the overlay.
   */
  directionOffset: OffsetAxis;

  /**
   * The offset property name for the alignment to calculate the position of the overlay.
   */
  alignmentOffset: OffsetAxis;

  /**
   * The rect dimension for the direction to calculate the position of the overlay.
   * If the direction is vertical then we calculate the position based on the height.
   */
  directionDimension: RectDimension;

  /**
   * The rect dimension for the direction to calculate the position of the overlay.
   * If the alignment is horizontal then we calculate the position based on the width.
   */
  alignmentDimension: RectDimension;
};

type Offset = {
  top: number;
  left: number;
  width: number;
  height: number;
};

type PositionProps = {
  /**
   * The placement of the overlay.
   */
  placement: TranslatedPlacement;

  /**
   * The target node of the overlay.
   */
  targetNode: HTMLElement;

  /**
   * The overlay node of the overlay.
   */
  overlayNode: HTMLElement;

  /**
   * The scroll node of the overlay.
   */
  scrollNode: HTMLElement;

  /**
   * The padding of the overlay.
   */
  padding: number;

  /**
   * If the overlay should be flipped.
   * @default false
   */
  shouldFlip: boolean;

  /**
   * The boundary element of the overlay.
   */
  boundaryElement: HTMLElement;

  /**
   * The offset of the overlay.
   */
  offset: number;

  /**
   * The cross offset of the overlay.
   */
  crossOffset: number;

  /**
   * The maximum height of the overlay.
   */
  maxHeight?: number;

  /**
   * The default alignment of placement.
   * @default center
   */
  defaultAlignment?: TranslatedAlignment;
};

export interface PositionResult<TDirection = Direction> {
  /**
   * The position of the overlay.
   */
  position?: Position;

  /**
   * The left offset of the arrow.
   */
  arrowOffsetLeft?: number;

  /**
   * The top offest of the arrow.
   */
  arrowOffsetTop?: number;

  /**
   * The max height of the overlay.
   */
  maxHeight?: number;

  /**
   * The direction the overlay will open in.
   */
  direction: TDirection;
}

type OffsetAxis = keyof Offset & Alignment;

const ALIGNMENT_OFFSETS: Record<Direction, OffsetAxis> = {
  top: 'top',
  bottom: 'top',
  left: 'left',
  right: 'left'
};

const FLIPPED_DIRECTIONS: {
  [key in Direction]: key extends HorizontalDirection
    ? Exclude<HorizontalDirection, key>
    : key extends VerticalDirection
    ? Exclude<VerticalDirection, key>
    : key;
} = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left'
};

const CROSS_OFFSETS: {[key in OffsetAxis]: Exclude<OffsetAxis, key>} = {
  top: 'left',
  left: 'top'
};

const ALIGNMENT_DIMENSIONS: Record<OffsetAxis, RectDimension> = {
  top: 'height',
  left: 'width'
};

const PARSED_PLACEMENT_CACHE: Partial<Record<TranslatedPlacement, ParsedPlacement>> = {};

const visualViewport = typeof window !== 'undefined' && window.visualViewport;

/**
 * Calculates the dimensions of a container node.
 * @param node The container node.
 * @returns The dimensions of the container.
 */
function getContainerDimensions(node: HTMLElement): Dimensions {
  let width = 0;
  let height = 0;
  let top = 0;
  let left = 0;
  const scroll: Position = {};

  if (node.tagName === 'BODY') {
    width = visualViewport?.width ?? document.documentElement.clientWidth;
    height = visualViewport?.height ?? document.documentElement.clientHeight;

    scroll.top = getScrollTop(ownerDocument(node).documentElement) || getScrollTop(node);
    scroll.left = getScrollLeft(ownerDocument(node).documentElement) || getScrollLeft(node);
  } else {
    ({width, height, top, left} = getOffset(node));
    scroll.top = getScrollTop(node);
    scroll.left = getScrollLeft(node);
  }

  return {width, height, scroll, top, left};
}

/**
 * Gets the scroll position of a node.
 * @param node The node.
 * @returns The scroll position.
 */
function getScroll(node: HTMLElement): Offset {
  return {
    top: node.scrollTop,
    left: node.scrollLeft,
    width: node.scrollWidth,
    height: node.scrollHeight
  };
}

/**
 * Calculates the delta between an element and its start and end edge offset.
 * @param axis The axis of the element.
 * @param offset The offset of the element.
 * @param size The size of the element.
 * @param containerDimensions The container dimensions of the element.
 * @param padding The padding of the element.
 * @returns The delta.
 */
function getDelta(
  axis: OffsetAxis,
  offset: number,
  size: number,
  containerDimensions: Dimensions,
  padding: number
): number {
  const containerScroll = containerDimensions.scroll[axis];

  const containerDimension = containerDimensions[ALIGNMENT_DIMENSIONS[axis]];

  const startEdgeOffset = offset - padding - containerScroll;
  const endEdgeOffset = offset + padding - containerScroll + size;

  if (startEdgeOffset < 0) {
    return -startEdgeOffset;
  }

  if (endEdgeOffset > containerDimension) {
    return Math.max(containerDimension - endEdgeOffset, -startEdgeOffset);
  }

  return 0;
}

/**
 * Gets the margins of a node.
 * @param node The node.
 * @returns The margins of the node.
 */
function getMargins(node: HTMLElement): Position {
  const style = window.getComputedStyle(node);
  return {
    top: parseInt(style.marginTop, 10) || 0,
    bottom: parseInt(style.marginBottom, 10) || 0,
    left: parseInt(style.marginLeft, 10) || 0,
    right: parseInt(style.marginRight, 10) || 0
  };
}

/**
 * Parses the placement from the simple interface to a more advanced object
 * which we can use for further operations.
 * @param input The placement to parse.
 * @returns The parsed placement.
 */
function parsePlacement(input: TranslatedPlacement, defaultAlignment: TranslatedAlignment): ParsedPlacement {
  if (PARSED_PLACEMENT_CACHE[input]) {
    return PARSED_PLACEMENT_CACHE[input];
  }

  const placements = input.split(' ') as Split<TranslatedPlacement, ' '>;

  const [direction, alignment = defaultAlignment] = placements;

  const axis = ALIGNMENT_OFFSETS[direction];

  const crossAxis = CROSS_OFFSETS[axis];

  const size = ALIGNMENT_DIMENSIONS[axis];
  const crossSize = ALIGNMENT_DIMENSIONS[crossAxis];

  PARSED_PLACEMENT_CACHE[input] = {
    direction,
    alignment,
    directionOffset: axis,
    alignmentOffset: crossAxis,
    directionDimension: size,
    alignmentDimension: crossSize
  };

  return PARSED_PLACEMENT_CACHE[input];
}

/**
 * Computes the position of the overlay on a wide variety of required parameters.
 * @param childOffset The offset of the child of the overlay.
 * @param boundaryDimensions The boundary dimensions of the overlay.
 * @param overlaySize The size of the overlay.
 * @param placementInfo The placement info of the overlay.
 * @param offset The offset of the overlay.
 * @param crossOffset The cross offset of the overlay.
 * @param containerOffsetWithBoundary The container offset with the boundary of the overlay.
 * @param isContainerPositioned If the container of the overlay is positioned static or non-static.
 * @returns The position of the overlay.
 */
function computePosition(
  childOffset: Offset,
  boundaryDimensions: Dimensions,
  overlaySize: Offset,
  placementInfo: ParsedPlacement,
  offset: number,
  crossOffset: number,
  containerOffsetWithBoundary: Offset,
  isContainerPositioned: boolean
) {
  const {
    direction: placement,
    alignment: crossPlacement,
    directionOffset: axis,
    alignmentOffset: crossAxis,
    directionDimension: size,
    alignmentDimension: crossSize
  } = placementInfo;
  const position: Position = {};

  // The button position.
  position[crossAxis] = childOffset[crossAxis];

  if (crossPlacement === 'center') {
    // + (button size / 2) - (overlay size / 2)
    // At this point the overlay center should match the button center.
    position[crossAxis] += (childOffset[crossSize] - overlaySize[crossSize]) / 2;
  } else if (crossPlacement !== crossAxis) {
    // + (button size) - (overlay size)
    // At this point the overlay bottom should match the button bottom.
    position[crossAxis] += childOffset[crossSize] - overlaySize[crossSize];
  } /* else {
    The overlay top should already match the button top, so no need for any further operations.
  } */
  // Here we add the crossOffset from the props.
  position[crossAxis] += crossOffset;

  // This is the button center position. For this we use the overlay size + half of the button
  // to align the bottom of the overlay with the button center.
  const minViablePosition = childOffset[crossAxis] + childOffset[crossSize] / 2 - overlaySize[crossSize];

  // This is the button position of the center. It aligns the top of the overlay with the button center.
  const maxViablePosition = childOffset[crossAxis] + childOffset[crossSize] / 2;

  // Clamp it into the range of the min/max positions.
  position[crossAxis] = Math.min(Math.max(minViablePosition, position[crossAxis]), maxViablePosition);

  // Floor these so the position isn't placed on a partial pixel, only whole pixels.
  // Here it shouldn't matter if it was floored or ceiled, so we just choose one.
  if (placement === axis) {
    // If the container is positioned (non-static), then we use the container's actual
    // height, as `bottom` will be relative to this height.  But if the container is static,
    // then it can only be the `document.body`, and `bottom` will be relative to its
    // container, which should be as large as boundaryDimensions.
    const containerHeight = isContainerPositioned ? containerOffsetWithBoundary[size] : boundaryDimensions[size];

    position[FLIPPED_DIRECTIONS[axis]] = Math.ceil(containerHeight - childOffset[axis] + offset);
  } else {
    position[axis] = Math.floor(childOffset[axis] + childOffset[size] + offset);
  }

  return position;
}

/**
 * Gets the maximum height the overlay can be based on a wide variety of required arguments.
 * @param position The position of the overlay.
 * @param boundaryDimensions The boundary dimensions of the overlay.
 * @param containerOffsetWithBoundary The container offset with the boundary of the overlay.
 * @param childOffset The offset of the child of the overlay.
 * @param margins The margins of the overlay.
 * @param padding The padding of the overlay.
 * @returns The max height of the overlay.
 */
function getMaxHeight(
  position: Position,
  boundaryDimensions: Dimensions,
  containerOffsetWithBoundary: Offset,
  childOffset: Offset,
  margins: Position,
  padding: number
) {
  return position.top != null
    ? // We want the distance between the top of the overlay to the bottom of the boundary.
      Math.max(
        0,
        boundaryDimensions.height +
          boundaryDimensions.top +
          boundaryDimensions.scroll.top - // this is the bottom of the boundary
          (containerOffsetWithBoundary.top + position.top) - // this is the top of the overlay
          (margins.top + margins.bottom + padding) // save additional space for margin and padding
      )
    : // We want the distance between the top of the trigger to the top of the boundary.
      Math.max(
        0,
        childOffset.top +
          containerOffsetWithBoundary.top - // This is the top of the trigger.
          (boundaryDimensions.top + boundaryDimensions.scroll.top) - // This is the top of the boundary.
          (margins.top + margins.bottom + padding) // This saves additional space for the margin and padding.
      );
}

/**
 * Gets the available space for the overlay.
 * @param boundaryDimensions The boundary dimensions of the overlay.
 * @param containerOffsetWithBoundary The container offset with the boundary of the overlay.
 * @param childOffset The child offset of the overlay.
 * @param margins The margins of the overlay.
 * @param padding The padding of the overlay.
 * @param placementInfo The placement info of the overlay.
 * @returns The available space for the overlay.
 */
function getAvailableSpace(
  boundaryDimensions: Dimensions,
  containerOffsetWithBoundary: Offset,
  childOffset: Offset,
  margins: Position,
  padding: number,
  placementInfo: ParsedPlacement
) {
  const {direction, directionOffset, directionDimension} = placementInfo;
  if (direction === directionOffset) {
    return Math.max(
      0,

      childOffset[directionOffset] -
        boundaryDimensions[directionOffset] -
        boundaryDimensions.scroll[directionOffset] +
        containerOffsetWithBoundary[directionOffset] -
        margins[directionOffset] -
        margins[FLIPPED_DIRECTIONS[directionOffset]] -
        padding
    );
  }

  return Math.max(
    0,
    boundaryDimensions[directionDimension] +
      boundaryDimensions[directionOffset] +
      boundaryDimensions.scroll[directionOffset] -
      containerOffsetWithBoundary[directionOffset] -
      childOffset[directionOffset] -
      childOffset[directionDimension] -
      margins[directionOffset] -
      margins[FLIPPED_DIRECTIONS[directionOffset]] -
      padding
  );
}

/**
 * An internal method to calculate the proper position of an overlay. We do not expose this
 * method as it's too complex and has too many arguments.
 * @param placementInput The placement input of the overlay.
 * @param childOffset The child offset of the overlay.
 * @param overlaySize The overlay size of the overlay.
 * @param scrollSize The scroll size of the overlay.
 * @param margins The margins of the overlay.
 * @param padding The padding of the overlay.
 * @param flip If the overlay is flipped.
 * @param boundaryDimensions  The boundary dimensions of the overlay.
 * @param containerOffsetWithBoundary The container offset with boundaries of the overlay.
 * @param offset The offset of the overlay.
 * @param crossOffset The cross offset of the overlay.
 * @param isContainerPositioned If the container of the overlay is positioned static or non-static.
 * @returns The position of the overlay.
 */
function calculatePositionInternal(
  placementInput: TranslatedPlacement,
  childOffset: Offset,
  overlaySize: Offset,
  scrollSize: Offset,
  margins: Position,
  padding: number,
  flip: boolean,
  boundaryDimensions: Dimensions,
  containerOffsetWithBoundary: Offset,
  offset: number,
  crossOffset: number,
  isContainerPositioned: boolean,
  defaultAlignment: TranslatedAlignment,
  userSetMaxHeight?: number
): PositionResult {
  let placementInfo = parsePlacement(placementInput, defaultAlignment);

  const {directionDimension, alignmentOffset, alignmentDimension, direction, alignment} = placementInfo;

  let position = computePosition(
    childOffset,
    boundaryDimensions,
    overlaySize,
    placementInfo,
    offset,
    crossOffset,
    containerOffsetWithBoundary,
    isContainerPositioned
  );

  let normalizedOffset = offset;

  const space = getAvailableSpace(
    boundaryDimensions,
    containerOffsetWithBoundary,
    childOffset,
    margins,
    padding + offset,
    placementInfo
  );

  // Check if the scroll size of the overlay is greater than the available space to determine if we need to flip
  if (flip && scrollSize[directionDimension] > space) {
    const flippedPlacementInfo = parsePlacement(
      `${FLIPPED_DIRECTIONS[direction]} ${alignment}` as TranslatedPlacement,
      defaultAlignment
    );
    const flippedPosition = computePosition(
      childOffset,
      boundaryDimensions,
      overlaySize,
      flippedPlacementInfo,
      offset,
      crossOffset,
      containerOffsetWithBoundary,
      isContainerPositioned
    );

    const flippedSpace = getAvailableSpace(
      boundaryDimensions,
      containerOffsetWithBoundary,
      childOffset,
      margins,
      padding + offset,
      flippedPlacementInfo
    );

    // If the available space for the flipped position is greater than the original available space, flip.
    if (flippedSpace > space) {
      placementInfo = flippedPlacementInfo;
      position = flippedPosition;
      normalizedOffset = offset;
    }
  }

  let delta = getDelta(
    alignmentOffset,
    position[alignmentOffset],
    overlaySize[alignmentDimension],
    boundaryDimensions,
    padding
  );
  position[alignmentOffset] += delta;

  let maxHeight = getMaxHeight(
    position,
    boundaryDimensions,
    containerOffsetWithBoundary,
    childOffset,
    margins,
    padding
  );

  if (userSetMaxHeight && userSetMaxHeight < maxHeight) {
    maxHeight = userSetMaxHeight;
  }

  overlaySize.height = Math.min(overlaySize.height, maxHeight);

  position = computePosition(
    childOffset,
    boundaryDimensions,
    overlaySize,
    placementInfo,
    normalizedOffset,
    crossOffset,
    containerOffsetWithBoundary,
    isContainerPositioned
  );

  delta = getDelta(
    alignmentOffset,
    position[alignmentOffset],
    overlaySize[alignmentDimension],
    boundaryDimensions,
    padding
  );
  position[alignmentOffset] += delta;

  const arrowPosition: Position = {};

  arrowPosition[alignmentOffset] =
    childOffset[alignmentOffset] - position[alignmentOffset] + childOffset[alignmentDimension] / 2;

  return {
    position,
    maxHeight,
    arrowOffsetLeft: arrowPosition.left,
    arrowOffsetTop: arrowPosition.top,
    direction: placementInfo.direction
  };
}

/**
 * Determines where to place the overlay with regards to the target and the position of an optional indicator.
 * @param props The props to calculate the position of the overlay.
 * @returns The ideal position of the overlay.
 */
export function calculatePosition(props: PositionProps): PositionResult {
  const {
    placement,
    targetNode,
    overlayNode,
    scrollNode,
    padding,
    shouldFlip,
    boundaryElement,
    offset,
    crossOffset,
    defaultAlignment = 'center',
    maxHeight
  } = props;

  const container: HTMLElement = (overlayNode.offsetParent as HTMLElement) || document.body;
  const isBodyContainer = container.tagName === 'BODY';
  const containerPositionStyle = window.getComputedStyle(container).position;
  const isContainerPositioned = !!containerPositionStyle && containerPositionStyle !== 'static';
  const childOffset: Offset = isBodyContainer ? getOffset(targetNode) : getPosition(targetNode, container);

  if (!isBodyContainer) {
    childOffset.top += parseInt(`${getCss(targetNode, 'marginTop')}`, 10) || 0;
    childOffset.left += parseInt(`${getCss(targetNode, 'marginLeft')}`, 10) || 0;
  }

  const overlaySize: Offset = getOffset(overlayNode);
  const margins = getMargins(overlayNode);
  overlaySize.width += margins.left + margins.right;
  overlaySize.height += margins.top + margins.bottom;

  const scrollSize = getScroll(scrollNode);
  const boundaryDimensions = getContainerDimensions(boundaryElement);
  const containerOffsetWithBoundary: Offset =
    boundaryElement.tagName === 'BODY' ? getOffset(container) : getPosition(container, boundaryElement);

  return calculatePositionInternal(
    placement,
    childOffset,
    overlaySize,
    scrollSize,
    margins,
    padding,
    shouldFlip,
    boundaryDimensions,
    containerOffsetWithBoundary,
    offset,
    crossOffset,
    isContainerPositioned,
    defaultAlignment,
    maxHeight
  );
}
