/* eslint-disable @typescript-eslint/no-unused-expressions, no-cond-assign  */
const toVal = (mix: any) => {
  let k;
  let y;
  let str = '';

  if (typeof mix === 'string' || typeof mix === 'number') {
    str += mix;
  } else if (typeof mix === 'object') {
    if (Array.isArray(mix)) {
      for (k = 0; k < mix.length; k += 1) {
        if (mix[k]) {
          if ((y = toVal(mix[k]))) {
            str && (str += ' ');
            str += y;
          }
        }
      }
    } else {
      for (k in mix) {
        if (mix[k]) {
          str && (str += ' ');
          str += k;
        }
      }
    }
  }

  return str;
};

/**
 * Merges any number of classNames into one className string.
 * @param args The classNames to merge.
 */
export const mergeClassNames = (...args: any) => {
  let i = 0;
  let tmp;
  let x;
  let str = '';

  while (i < args.length) {
    if ((tmp = args[i++])) {
      if ((x = toVal(tmp))) {
        str && (str += ' ');
        str += x;
      }
    }
  }

  return str
    .split(' ')
    .filter((className, index, self) => self.indexOf(className) === index)
    .join(' ');
};
