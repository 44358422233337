import {useCallback} from 'react';

import {useInperiumAuth} from '../context';
import {Feature, features, Permission, permissions} from '../types';
import {hasPermissions, ownsFeatures} from '../utils';

// @ts-expect-error
const isPermission = (value: string): value is Permission => permissions.includes(value);

// @ts-expect-error
const isFeature = (value: string): value is Feature => features.includes(value);

/**
 * A guard that protects a part of the application.
 */
export type Guard =
  | undefined // allows undefined for easier conditional usage
  | (() => boolean)
  | Feature[]
  | Permission[]
  | {
      /**
       * A method that guards the children of this component.
       */
      guard: (() => boolean) | Feature[] | Permission[];

      /**
       * A method executed if a guard passes.
       */
      onSuccess?: () => void;

      /**
       * A method executed if a guard fails.
       */
      onError?: () => void;
    };

/**
 * A hook to access authorization guarding mechanism.
 * It goes in pair with AuthorizationGuard component.
 */
export const useAuthorizationGuard = () => {
  const {initialized, accessTokenClaims} = useInperiumAuth();

  const permissionsGuard = useCallback(
    (permissions: Permission[]) => {
      return !!accessTokenClaims && initialized && hasPermissions(permissions, accessTokenClaims);
    },
    [accessTokenClaims, initialized]
  );

  const featuresGuard = useCallback(
    (features?: Feature[]) => {
      return !!accessTokenClaims && initialized && ownsFeatures(features, accessTokenClaims);
    },
    [accessTokenClaims, initialized]
  );

  const protect = useCallback(
    <TTarget, TFallback extends TTarget | null = null>(
      target: TTarget,
      guard: Guard | Guard[],
      fallback?: TFallback
    ) => {
      let guards: Guard[];

      // Allow a single guard and convert it to array so we have general algorithm.
      if (Array.isArray(guard) && guard.length > 0 && typeof guard[0] !== 'string') {
        guards = guard as Guard[];
      } else {
        guards = [guard as Guard];
      }

      for (let i = 0; i < guards.length; i += 1) {
        let guard = guards[i];

        if (guard) {
          let onError;
          let onSuccess;

          // If the guard has callbacks initialize them.
          if ('guard' in guard) {
            onError = guard.onError;
            onSuccess = guard.onSuccess;
            guard = guard.guard;
          }

          let guardPassed = true;

          // If the guard is not a function replacing features and permissions with function guards.
          if (typeof guard !== 'function') {
            if (guard.some(isFeature)) {
              guardPassed = featuresGuard(guard as Feature[]);
            }
            if (guard.some(isPermission)) {
              guardPassed = permissionsGuard(guard as Permission[]);
            }
          } else {
            guardPassed = guard();
          }

          if (!guardPassed && onError) onError();
          if (!guardPassed) return fallback || null;
          if (guardPassed && onSuccess) onSuccess();
        }
      }

      return target;
    },
    [featuresGuard, permissionsGuard]
  );

  return {
    protect
  };
};
