import {useMemo} from 'react';

import {useTheme} from './index';
import {Theme} from './vars/types';

export function useStyles<TStyles extends object, TProps>(
  styles: (styles: Theme, props: TProps) => TStyles,
  props: TProps
): TStyles;

export function useStyles<TStyles extends object>(factory: (theme: Theme) => TStyles): TStyles;

/**
 * A hook that applies the theme from the ThemeProvider to the style object
 * for CSS in JS code. It optionally passes in props if desired.
 * @param styles The styles CSS in JS style object.
 * @param props Optionally pass in props from the components to the styles object.
 * @returns The styles object with an injected scale and color scheme..
 */
export function useStyles<TStyles extends object, TProps = undefined>(
  styles: (styles: Theme, props?: TProps) => TStyles,
  props?: TProps
): TStyles {
  const theme = useTheme();

  if (!theme || Object.keys(theme).length === 0) {
    throw new Error('useStyles is not contained within an InperiumThemeProvider.');
  }

  return useMemo(() => styles(theme, props), [props, theme, styles]);
}
