import {ElementType, RefObject} from 'react';

import {useFocusable} from '@inperium-corp/convergo-aria-focus';
import {usePress} from '@inperium-corp/convergo-aria-interactions';
import {filterDOMProps, mergeProps} from '@inperium-corp/convergo-aria-utils';
import {ButtonAttributes, ButtonElement, ButtonProps} from '@inperium-corp/convergo-types';

export interface ButtonAriaState {
  /**
   * Whether the button is currently pressed.
   */
  isPressed: boolean;
}

export interface ButtonAria<T> {
  /**
   * Button props to spread on the target element.
   */
  buttonProps: T;

  /**
   * The state of the button element.
   */
  buttonState: ButtonAriaState;
}

const isButtonProps = (props: ButtonProps<any>): props is ButtonProps<'button'> =>
  props.elementType === 'button' || !props.elementType;

const isAnchorProps = (props: ButtonProps<any>): props is ButtonProps<'a'> => props.elementType === 'a';

const isInputProps = (props: ButtonProps<any>): props is ButtonProps<'input'> => props.elementType === 'input';

/**
 * Provides the behavior and accessibility implementation for a button component. Handles mouse, keyboard, and touch interactions,
 * focus behavior, and ARIA props for both native button elements and custom element types.
 * @param props Props to be applied to the button.
 * @param ref A ref to a DOM element for the button.
 */
export function useButton<T extends ElementType = 'button'>(
  props: ButtonProps<T>,
  ref: RefObject<ButtonElement<T>>
): ButtonAria<ButtonAttributes<T>> {
  const {
    isDisabled,
    onPress,
    onPressChange,
    onPressEnd,
    onPressStart,
    preventFocusOnPress,
    allowFocusWhenDisabled,
    elementType = 'button',
    type = 'button',
    form
  } = props;

  let additionalProps: ButtonAttributes<typeof elementType> = {
    'aria-disabled': !isDisabled ? undefined : isDisabled,
    role: 'button',
    tabIndex: isDisabled ? undefined : 0
  };

  if (isButtonProps(props)) {
    additionalProps = {
      disabled: isDisabled,
      type
    };
  } else if (isAnchorProps(props)) {
    const {href, rel, target} = props;

    additionalProps = {
      'aria-disabled': !isDisabled ? undefined : isDisabled,
      href: isDisabled ? undefined : href,
      rel,
      role: 'button',
      tabIndex: isDisabled ? undefined : 0,
      target
    };
  } else if (isInputProps(props)) {
    additionalProps = {
      disabled: isDisabled,
      role: 'button',
      tabIndex: isDisabled ? undefined : 0,
      type
    };
  }

  const {pressProps, pressState} = usePress(
    {
      isDisabled,
      onPress,
      onPressChange,
      onPressEnd,
      onPressStart,
      preventFocusOnPress
    },
    ref
  );

  const {focusableProps} = useFocusable(props, ref);
  if (allowFocusWhenDisabled) {
    focusableProps.tabIndex = isDisabled ? -1 : focusableProps.tabIndex;
  }

  // Merge all individual prop objects.
  let buttonProps = mergeProps(focusableProps, pressProps);
  buttonProps = mergeProps(buttonProps, filterDOMProps(props, {labelable: true}));
  buttonProps = mergeProps(additionalProps, buttonProps);

  // These props get lost due to the filterDOMProps function call above, so we just add them back.
  buttonProps = mergeProps(buttonProps, {
    form: props['form'],
    'aria-haspopup': props['aria-haspopup'],
    'aria-expanded': props['aria-expanded'],
    'aria-controls': props['aria-controls'],
    'aria-pressed': props['aria-pressed']
  });

  return {
    buttonProps: buttonProps as ButtonAttributes<T>,
    buttonState: {
      isPressed: pressState.isPressed
    }
  };
}
