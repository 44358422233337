import {RefObject, useEffect, useRef} from 'react';

export function useEvent<K extends keyof GlobalEventHandlersEventMap>(
  ref: RefObject<EventTarget>,
  event: K,
  handler: (this: Document, ev: GlobalEventHandlersEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
) {
  const handlerRef = useRef(handler);
  handlerRef.current = handler;

  const isDisabled = handler == null;

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    const element = ref.current;
    // @ts-expect-error TODO: Ask Oleg for help to fix this.
    const handler = (e: GlobalEventHandlersEventMap[K]) => handlerRef.current.call(this, e);

    // @ts-expect-error this is okay due to the addEventListener types.
    element.addEventListener(event, handler, options);
    return () => {
      // @ts-expect-error this is okay due to the addEventListener types.
      element.removeEventListener(event, handler, options);
    };
  }, [ref, event, options, isDisabled]);
}
