/*
 * Copyright 2022 Inperium Corp. All rights reserved.
 */

export const iconNames = [
'accountingOutlined',
'accountingSolid',
'activityOutlined',
'activitySolid',
'addPhoneOutlined',
'addPhoneSolid',
'alignCenterOutlined',
'alignCenterSolid',
'alignLeftOutlined',
'alignLeftSolid',
'alignRightOutlined',
'alignRightSolid',
'apiKeysOutlined',
'apiKeysSolid',
'arrowDownOutlined',
'arrowDownSolid',
'arrowLeftOutlined',
'arrowLeftSolid',
'arrowRightOutlined',
'arrowRightSolid',
'arrowUpOutlined',
'arrowUpSolid',
'audioFileOutlined',
'audioFileSolid',
'audioLibraryOutlined',
'audioLibrarySolid',
'autoReceptionistOutlined',
'autoReceptionistSolid',
'bellOutlined',
'bellSolid',
'boldOutlined',
'boldSolid',
'brandingOutlined',
'brandingSolid',
'calendarOutlined',
'calendarSolid',
'callForwardingOutlined',
'callForwardingSolid',
'callHistorySolid',
'callOutlined',
'callQueueSolid',
'callSolid',
'cardOutlined',
'cardSolid',
'caretDownOutlined',
'caretDownSolid',
'caretLeftAltOutlined',
'caretLeftAltSolid',
'caretLeftOutlined',
'caretLeftSolid',
'caretRightAltOutlined',
'caretRightAltSolid',
'caretRightOutlined',
'caretRightSolid',
'caretUpOutlined',
'caretUpSolid',
'chartOutlined',
'chartSolid',
'checkboxCheckedOutlined',
'checkboxCheckedSolid',
'checkboxUncheckedOutlined',
'checkboxUncheckedSolid',
'clockOutlined',
'clockSolid',
'cogOutlined',
'cogSolid',
'coinsOutlined',
'collapseOutlined',
'companyOutlined',
'companySolid',
'contactSolid',
'contactsOutlined',
'contactsSolid',
'copyOutlined',
'copySolid',
'creditCardOutlined',
'creditCardSolid',
'crossAltOutlined',
'crossAltSolid',
'crossOutlined',
'crossSolid',
'dashboardOutlined',
'dashboardSolid',
'dashOutlined',
'dashSolid',
'dealOutlined',
'dealSolid',
'deleteFormattingOutlined',
'deleteFormattingSolid',
'dotSolid',
'downloadAltOutlined',
'downloadOutlined',
'downloadSolid',
'duplicateOutlined',
'duplicateSolid',
'editOutlined',
'editSolid',
'emailOpenOutlined',
'emailOpenSolid',
'emailOutlined',
'emailSolid',
'eraseOutlined',
'eraseSolid',
'exclamationMarkCircleOutlined',
'exitOutlined',
'exitSolid',
'externalLinkOutlined',
'externalLinkSolid',
'eyeOffOutlined',
'eyeOffSolid',
'eyeOutlined',
'eyeSolid',
'facebookOutlined',
'facebookSolid',
'fileOutlined',
'fileSolid',
'filterOutlined',
'filterSolid',
'flagSolid',
'folderOutlined',
'folderSolid',
'forwardOutlined',
'geopositionSolid',
'graphSolid',
'heading1Outlined',
'heading1Solid',
'heading2Outlined',
'heading2Solid',
'heading3Outlined',
'heading3Solid',
'heartOutlined',
'heartSolid',
'importExportOutlined',
'importExportSolid',
'inboxOutlined',
'inboxSolid',
'incomingCallOutlined',
'incomingCallSolid',
'infoOutlined',
'infoSolid',
'inperiumOutlined',
'inperiumSolid',
'instagramOutlined',
'instagramSolid',
'integrationsOutlined',
'integrationsSolid',
'invisibleSolid',
'invoiceOutlined',
'italicOutlined',
'italicSolid',
'ivrOutlined',
'ivrSolid',
'keypadSolid',
'lightingOutlined',
'lightingSolid',
'lightningSolid',
'linkedinOutlined',
'linkedinSolid',
'linkOutlined',
'linkSolid',
'listOrderedOutlined',
'listOrderedSolid',
'listOutlined',
'listSolid',
'listUnorderedOutlined',
'listUnorderedSolid',
'locationSolid',
'lockOutlined',
'lockSolid',
'meetingSolid',
'menuOutlined',
'menuSolid',
'messagesOutlined',
'messagesSolid',
'microphoneSolid',
'minusOutlined',
'minusSolid',
'missedCallOutlined',
'missedCallSolid',
'moonOutlined',
'moonSolid',
'moreAltOutlined',
'moreAltSolid',
'moreOutlined',
'moreSolid',
'moveAltOutlined',
'muteSolid',
'noteSolid',
'notificationsSolid',
'numberPortingOutlined',
'numberPortingSolid',
'outgoingCallOutlined',
'outgoingCallSolid',
'paperClipOutlined',
'paragraphOutlined',
'paragraphSolid',
'pauseSolid',
'paymentsOutlined',
'paymentsSolid',
'personalOutlined',
'personalSolid',
'phoneHoldSolid',
'phoneNumberSolid',
'phoneSolid',
'phoneTubeOutlined',
'phoneTubeSolid',
'pieChartOutlined',
'pieChartSolid',
'pipelineOutlined',
'pipelineSolid',
'playSolid',
'plusOutlined',
'plusSolid',
'printSolid',
'productOutlined',
'productSolid',
'propertyOutlined',
'propertySolid',
'questionMarkCircleOutlined',
'questionMarkCircleSolid',
'questionOutlined',
'questionSolid',
'quoteOutlined',
'quoteSolid',
'radioCheckedOutlined',
'radioUncheckedOutlined',
'recordOutlined',
'refreshOutlined',
'renameOutlined',
'renameSolid',
'replyAllSolid',
'replySolid',
'revenueOutlined',
'revenueSolid',
'rolesOutlined',
'rolesSolid',
'roundOutlined',
'roundSolid',
'saveOutlined',
'saveSolid',
'scaleSolid',
'searchOutlined',
'searchSolid',
'securityOutlined',
'securitySolid',
'sellOutlined',
'sellSolid',
'sendOutlined',
'sendSolid',
'shareOutlined',
'shieldOutlined',
'shieldSolid',
'snippetsOutlined',
'snippetsSolid',
'sortOutlined',
'spamBlockingOutlined',
'spamBlockingSolid',
'speakerOffSolid',
'speakerSolid',
'starOutlined',
'starSolid',
'stopSolid',
'subscriptionsOutlined',
'subscriptionsSolid',
'sunOutlined',
'sunSolid',
'talkOutlined',
'talkSolid',
'tapeOutlined',
'taskSolid',
'teamsOutlined',
'teamsSolid',
'templateOutlined',
'templateSolid',
'textToSpeechOutlined',
'tickAltOutlined',
'tickAltSolid',
'tickOutlined',
'tickSolid',
'tilesSolid',
'trackingOutlined',
'trackingSolid',
'transferCallSolid',
'trashOutlined',
'trashSolid',
'trimOutlined',
'turnOffOutlined',
'twitterOutlined',
'twitterSolid',
'underlineOutlined',
'underlineSolid',
'uploadOutlined',
'uploadSolid',
'userOutlined',
'userSolid',
'usersOutlined',
'usersSolid',
'viewBoardOutlined',
'viewListOutlined',
'warningOutlined',
'warningSolid',
'welcomeSolid',
'wireOutlined',
'youtubeOutlined',
'youtubeSolid',
'zoomOutlined',
] as const;

/** String literal union type of all Inperium UI icon names. */
export type IconName = typeof iconNames[number];
