import {useMemo} from 'react';

import {useTheme as useEmotionTheme} from '@emotion/react';

import {Theme} from './vars/types';

/**
 * A hook that returns the current scope's theme.
 * It allows altering the theme if the partial property is provided.
 * @param partial Theme object to alter.
 * @deprecated TODO: Remove.
 * @returns Current theme.
 */
export const useTheme = (partial?: Partial<Theme>) => {
  const theme = useEmotionTheme() as Theme;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ({...theme, ...partial}), [theme]);
};
