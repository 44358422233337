/**
 * Merges two ids.
 */
export function mergeIds(a: string, b: string): string {
  const map: Map<string, (v: string) => void> = new Map();

  if (a === b) {
    return a;
  }

  const setA = map.get(a);
  if (setA) {
    setA(b);
    return b;
  }

  const setB = map.get(b);
  if (setB) {
    setB(a);
    return a;
  }

  return b;
}
