import {forwardRef, RefObject} from 'react';

import {useMessageFormatter} from '@inperium-corp/convergo-aria-i18n';
import {useSlotProps} from '@inperium-corp/convergo-react-layout';
import {css, cx, keyframes, Theme, useStyles} from '@inperium-corp/convergo-react-styles';
import {DOMProps, SlotProps, StyleProps} from '@inperium-corp/convergo-types';

import i18nMessages from '../i18n/*.json';

export interface LogoSpinnerProps extends SlotProps, DOMProps, StyleProps {
  /**
   * This component does not support children.
   */
  children?: never;
}

const makeStripeKeyframes = (translateX: number, translateY: number) =>
  keyframes({
    '25%': {
      transform: `rotate(45deg) translate(${translateX}px, ${translateY}px)`
    },
    '50%': {
      transform: `rotate(45deg) translate(${translateX}px, calc(${translateY - 50}px))`,
      opacity: 1
    },
    '51%': {
      opacity: 0
    },
    '52%': {
      transform: `rotate(45deg) translate(${translateX}px, calc(${translateY + 50}px))`
    },
    '53%': {
      opacity: 1
    },
    '75%': {
      transform: `rotate(45deg) translate(${translateX}px, ${translateY}px)`
    }
  });

const spinnerStylesFactory = (theme: Theme) => ({
  root: css`
    display: flex;
    height: 20px;
    overflow: hidden;
    width: 30px;
  `,
  stripe: css`
    height: 100%;
    transition: transform 0.4s;
    width: 8px;
  `,
  green: css`
    background: ${theme.colorScheme.color.green[500]};
  `,
  blue: css`
    background: ${theme.colorScheme.color.blue[750]};
  `,
  first: css`
    animation: ${makeStripeKeyframes(-5, 6)} 2s -0.2s infinite;
    transform: rotate(45deg) translate(-5px, 6px);
    width: 12px;
  `,
  second: css`
    animation: ${makeStripeKeyframes(-2, 1)} 2s -0.1s infinite;
    height: 25px;
    transform: rotate(45deg) translate(-2px, 1px);
    width: 15px;
  `,
  third: css`
    animation: ${makeStripeKeyframes(1.5, -1)} 2s 0.1s infinite;
    height: 30px;
    transform: rotate(45deg) translate(1.5px, -1px);
    width: 22px;
  `
});

/**
 * A spinner is an animated spinning icon that lets users know content is being loaded.
 */
export const LogoSpinner = forwardRef((props: LogoSpinnerProps, ref?: RefObject<HTMLDivElement>) => {
  props = useSlotProps('spinner', props);
  const {className, ...otherProps} = props;
  const styles = useStyles(spinnerStylesFactory);

  const formatMessage = useMessageFormatter(i18nMessages);

  return (
    <div
      ref={ref}
      className={cx(styles.root, className)}
      aria-label={formatMessage('loading')}
      role='progressbar'
      {...otherProps}
    >
      <div className={cx(styles.stripe, styles.blue, styles.first)} />
      <div className={cx(styles.stripe, styles.green, styles.second)} />
      <div className={cx(styles.stripe, styles.blue, styles.third)} />
    </div>
  );
});
