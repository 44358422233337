import {useContext} from 'react';

import {DialogContext} from './DialogContext';

type DialogContainer = {
  /**
   * The type of the dialog.
   */
  type: 'modal' | 'popover' | 'tray' | 'fullscreen' | 'fullscreenTakeover' | 'drawer';

  /**
   * A method to dismiss the dialog.
   */
  dismiss(): void;
};

export function useDialogContainer(): DialogContainer {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('Cannot call useDialogContext outside a <DialogTrigger> or <DialogContainer>.');
  }

  return {
    type: context.type,
    dismiss() {
      context.onClose();
    }
  };
}
