import {useContext} from 'react';

import {Locale} from '@inperium-corp/convergo-types';

import {I18nContext} from './I18nContext';
import {useDefaultLocale} from './useDefaultLocale';

/**
 * Returns the current locale and layout direction of the user.
 * @returns The current locale.
 */
export function useLocale(): Locale {
  const defaultLocale = useDefaultLocale();
  const context = useContext(I18nContext);

  return context || defaultLocale;
}
