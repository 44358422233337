import {forwardRef, RefObject, useContext} from 'react';

import {chainFunctions} from '@inperium-corp/convergo-aria-utils';
import {Button, ButtonGroup} from '@inperium-corp/convergo-react-button';
import {Content, Footer, Header, useSlotProps} from '@inperium-corp/convergo-react-layout';
import {css, cx, useStyles} from '@inperium-corp/convergo-react-styles';
import {Heading, Text} from '@inperium-corp/convergo-react-text';
import {AlertDialogProps, ButtonProps} from '@inperium-corp/convergo-types';

import {Dialog} from './Dialog';
import {DialogContext, DialogContextProps} from './DialogContext';

export const alertDialogStylesFactory = () => ({
  root: css``,
  variants: {
    confirmation: css``,
    destructive: css``,
    info: css``,
    error: css``,
    warning: css``
  }
});

export const AlertDialog = forwardRef((props: AlertDialogProps, ref: RefObject<HTMLDivElement>) => {
  const styles = useStyles(alertDialogStylesFactory);

  props = useSlotProps('alertDialog', props);

  const {onClose = () => {}} = useContext(DialogContext) || ({} as DialogContextProps);

  const {children, title, variant, className, actions, ...otherProps} = props;

  let primaryActionButtonColor: ButtonProps['color'] = 'primary';
  if (variant === 'destructive') {
    primaryActionButtonColor = 'danger';
  }

  const contents = typeof children === 'string' ? <Text>{children}</Text> : children;

  return (
    <Dialog
      {...otherProps}
      className={cx(styles.root, styles.variants[variant], className)}
      size='medium'
      role='alertdialog'
      ref={ref}
      isDismissable
    >
      <Header>
        <Heading>{title}</Heading>
      </Header>
      <Content>{contents}</Content>
      <Footer>
        <ButtonGroup alignment='end'>
          {actions
            .map(({type, label, onPress = () => {}, autoFocus, isDisabled, isLoading}) => (
              <Button
                key={type}
                variant={type === 'primary' ? 'contained' : 'outlined'}
                color={type === 'primary' ? primaryActionButtonColor : undefined}
                onPress={() => chainFunctions(onClose(), onPress())}
                autoFocus={autoFocus === true}
                isDisabled={isDisabled}
                isLoading={isLoading}
              >
                {label}
              </Button>
            ))
            .reverse()}
        </ButtonGroup>
      </Footer>
    </Dialog>
  );
});
