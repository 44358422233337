import {RefObject, SyntheticEvent, useEffect, useRef} from 'react';

interface InteractOutsideAriaProps {
  /**
   * A method that is executed if the user interacts with the
   * outside of the element.
   */
  onInteractOutside?: (event: SyntheticEvent) => void;

  /**
   * A method that is executed if the user starts to interact with the
   * outside of the element.
   */
  onInteractOutsideStart?: (e: SyntheticEvent) => void;

  /**
   * Whether the interact outside events should be disabled.
   */
  isDisabled?: boolean;
}

/**
 * A hook that detects whether a user interacts with the outside area of a given element.
 * This can be used in many use cases such as Dialogs or Popovers, so they can close
 * when a user clicks outside of them.
 * @param props The props to configure the hook.
 * @param ref The ref to the element.
 */
export function useInteractOutside(props: InteractOutsideAriaProps, ref: RefObject<Element>) {
  const {onInteractOutside, onInteractOutsideStart, isDisabled} = props;

  const stateRef = useRef({
    isPointerDown: false,
    ignoreEmulatedMouseEvents: false,
    onInteractOutside,
    onInteractOutsideStart
  });
  const state = stateRef.current;
  state.onInteractOutside = onInteractOutside;
  state.onInteractOutsideStart = onInteractOutsideStart;

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    const onPointerDown = (e: any) => {
      if (isValidOutsideEvent(e, ref) && state.onInteractOutside) {
        if (state.onInteractOutsideStart) {
          state.onInteractOutsideStart(e);
        }
        state.isPointerDown = true;
      }
    };

    // Use pointer events if available. Otherwise, fall back to mouse and touch events.
    if (typeof PointerEvent !== 'undefined') {
      const onPointerUp = (e: any) => {
        if (state.isPointerDown && state.onInteractOutside && isValidOutsideEvent(e, ref)) {
          state.isPointerDown = false;
          state.onInteractOutside(e);
        }
      };

      document.addEventListener('pointerdown', onPointerDown, true);
      document.addEventListener('pointerup', onPointerUp, true);

      return () => {
        document.removeEventListener('pointerdown', onPointerDown, true);
        document.removeEventListener('pointerup', onPointerUp, true);
      };
    } else {
      const onMouseUp = (e: any) => {
        if (state.ignoreEmulatedMouseEvents) {
          state.ignoreEmulatedMouseEvents = false;
        } else if (state.isPointerDown && state.onInteractOutside && isValidOutsideEvent(e, ref)) {
          state.isPointerDown = false;
          state.onInteractOutside(e);
        }
      };

      const onTouchEnd = (e: any) => {
        state.ignoreEmulatedMouseEvents = true;
        if (state.onInteractOutside && state.isPointerDown && isValidOutsideEvent(e, ref)) {
          state.isPointerDown = false;
          state.onInteractOutside(e);
        }
      };

      document.addEventListener('mousedown', onPointerDown, true);
      document.addEventListener('mouseup', onMouseUp, true);
      document.addEventListener('touchstart', onPointerDown, true);
      document.addEventListener('touchend', onTouchEnd, true);

      return () => {
        document.removeEventListener('mousedown', onPointerDown, true);
        document.removeEventListener('mouseup', onMouseUp, true);
        document.removeEventListener('touchstart', onPointerDown, true);
        document.removeEventListener('touchend', onTouchEnd, true);
      };
    }
  }, [ref, state, isDisabled]);
}

/**
 * Checks if a given event is valid to be considered an "outside" interaction.
 * @param event The event to check.
 * @param ref The ref to the element.
 * @returns If the event is valid and outside of the element.
 */
function isValidOutsideEvent(event: any, ref: RefObject<Element>) {
  if (event.button > 0) {
    return false;
  }

  // if the event target is no longer in the document.
  if (event.target) {
    const {ownerDocument} = event.target;
    if (!ownerDocument || !ownerDocument.documentElement.contains(event.target)) {
      return false;
    }
  }

  return ref.current && !ref.current.contains(event.target);
}
