import {useEffect, useRef} from 'react';

/**
 * This hook returns the value of a state or a prop from the previous render.
 * @value the value to be stored.
 */
export function usePrevious<TValue>(value: TValue): TValue | undefined;

/**
 * This hook returns the value of a state or a prop from the previous render.
 * @value the value to be stored.
 * @init the initialization value.
 */
export function usePrevious<TValue>(value: TValue, init: TValue): TValue;

export function usePrevious<TValue>(value: TValue, init?: TValue) {
  /**
   * The ref object is a generic container whose current property is mutable
   * and can hold any value, similar to an instance property on a class.
   */
  const ref = useRef(init);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  /** Return previous value (happens before update in useEffect above). */
  return ref.current;
}
