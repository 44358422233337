import {Children, cloneElement, ReactElement, ReactNode, useRef} from 'react';

import {FocusRing} from '@inperium-corp/convergo-aria-focus';
import {useHover} from '@inperium-corp/convergo-aria-interactions';
import {LinkAriaProps, useLink} from '@inperium-corp/convergo-aria-text';
import {mergeProps} from '@inperium-corp/convergo-aria-utils';
import {useSlotProps} from '@inperium-corp/convergo-react-layout';
import {css, cx, Theme, useStyles} from '@inperium-corp/convergo-react-styles';
import {DOMProps, FontSize, FontWeight, SlotProps, StyleProps} from '@inperium-corp/convergo-types';

import {textStylesFactory} from './Text';

export interface LinkableProps extends SlotProps, DOMProps, LinkAriaProps, StyleProps {
  /**
   * The text size of the link.
   * @default medium
   */
  size?: FontSize;

  /**
   * The color of the link.
   * @default primary
   */
  color?: 'primary' | 'secondary';

  /**
   * The text weight of the link.
   * @default regular
   */
  weight?: FontWeight;

  /**
   * Uses the CSS text-transform attribute.
   */
  transform?: 'lowercase' | 'capitalize' | 'capitalize-first-letter';

  /**
   * The text that shall be rendered.
   */
  children?: ReactNode;

  /**
   * If the link should be hidden. Useful for
   * slots that can hide text based on the usecase.
   */
  isHidden?: boolean;
}

export const linkableStylesFactory = (theme: Theme) => {
  const baseStyles = textStylesFactory(theme);
  return {
    root: cx(
      baseStyles.root,
      css`
        outline: 0;
        border: none;
        > svg {
          margin-left: 4px;
        }
      `
    ),
    colors: {
      primary: css`
        color: ${theme.colorScheme.color.blue[500]};
        &.is-hovered {
          color: ${theme.colorScheme.color.blue[750]};
        }
      `,
      secondary: css`
        color: ${theme.colorScheme.color.blue[750]};
        &.is-hovered {
          color: ${theme.colorScheme.color.blue[850]};
        }
      `
    },
    sizes: baseStyles.sizes,
    weights: baseStyles.weights,
    transforms: baseStyles.transforms,
    isHidden: baseStyles.isHidden,
    isHovered: 'is-hovered',
    focusRing: css`
      text-decoration: underline;
    `
  };
};

/**
 * Creates a hyperlink to web pages, files, email addresses, locations in the same page, or anything else a URL can address.
 */
export function Linkable(props: LinkableProps) {
  const styles = useStyles(linkableStylesFactory);

  props = useSlotProps('link', props);
  const {size = 'medium', weight = 'regular', color = 'primary', className, children, transform, isHidden} = props;

  const {hoverProps, hoverState} = useHover({});

  const ref = useRef();
  const {linkProps} = useLink(
    {
      ...props,
      elementType: typeof children === 'string' ? 'span' : 'a'
    },
    ref
  );

  let contents;
  if (typeof children === 'string') {
    contents = <span>{children}</span>;
  } else {
    contents = Children.only(children) as ReactElement;
  }

  return (
    <FocusRing focusRingClassName={styles.focusRing}>
      {cloneElement(contents, {
        ...mergeProps(linkProps, hoverProps),
        ref,
        className: cx(
          styles.root,
          styles.sizes[size],
          styles.weights[weight],
          styles.colors[color],
          transform && styles.transforms[transform],
          isHidden && styles.isHidden,
          hoverState.isHovered && styles.isHovered,
          className
        )
      })}
    </FocusRing>
  );
}
