import {Children, cloneElement, ReactElement, ReactNode, RefObject} from 'react';
import Transition, {EndHandler, EnterHandler, ExitHandler, TransitionStatus} from 'react-transition-group/Transition';

export interface OpenTransitionProps {
  /**
   * Show the component; triggers the enter or exit states.
   */
  in?: boolean;

  /**
   * Normally a component is not transitioned if it is shown when the
   * `<Transition>` component mounts. If you want to transition on the first
   * mount set  appear to true, and the component will transition in as soon
   * as the `<Transition>` mounts. Note: there are no specific "appear" states.
   * Appear only adds an additional enter transition.
   */
  appear?: boolean;

  /**
   * By default the child component is mounted immediately along with the
   * parent Transition component. If you want to "lazy mount" the component on
   * the first `in={true}` you can set `mountOnEnter`. After the first enter
   * transition the component will stay mounted, even on "exited", unless you
   * also specify `unmountOnExit`.
   */
  mountOnEnter?: boolean;

  /**
   * By default the child component stays mounted after it reaches the
   * 'exited' state. Set `unmountOnExit` if you'd prefer to unmount the
   * component after it finishes exiting.
   */
  unmountOnExit?: boolean;

  /**
   * Callback fired before the "entering" status is applied. An extra
   * parameter `isAppearing` is supplied to indicate if the enter stage is
   * occurring on the initial mount.
   */
  onEnter?: EnterHandler<HTMLElement>;

  /**
   * Callback fired after the "entering" status is applied. An extra parameter
   * isAppearing is supplied to indicate if the enter stage is occurring on
   * the initial mount.
   */
  onEntering?: EnterHandler<HTMLElement>;

  /**
   * Callback fired after the "entered" status is applied. An extra parameter
   * isAppearing is supplied to indicate if the enter stage is occurring on
   * the initial mount.
   */
  onEntered?: EnterHandler<HTMLElement>;

  /**
   * Callback fired before the "exiting" status is applied.
   */
  onExit?: ExitHandler<HTMLElement>;

  /**
   * Callback fired after the "exiting" status is applied.
   */
  onExiting?: ExitHandler<HTMLElement>;

  /**
   * Callback fired after the "exited" status is applied.
   */
  onExited?: ExitHandler<HTMLElement>;

  /**
   * The children of the component that need to be transitioned.
   */
  children?: ReactNode;

  /**
   * A React reference to DOM element that need to transition: https://stackoverflow.com/a/51127130/4671932
   * When `nodeRef` prop is used, node is not passed to callback functions (e.g. onEnter) because user already has direct access to the node.
   * When changing `key` prop of `Transition` in a `TransitionGroup` a new `nodeRef` need to be provided to `Transition` with changed `key`
   * prop (@see https://github.com/reactjs/react-transition-group/blob/master/test/Transition-test.js).
   */
  nodeRef?: RefObject<HTMLElement>;

  /**
   * The duration of the transition, in milliseconds. Required unless addEndListener is provided.
   *
   * You may specify a single timeout for all transitions:
   * ```js
   *   timeout={500}
   * ```
   * or individually:
   * ```js
   * timeout={{
   *  appear: 500,
   *  enter: 300,
   *  exit: 500,
   * }}
   * ```
   * - appear defaults to the value of `enter`
   * - enter defaults to `0`
   * - exit defaults to `0`.
   */
  timeout?: number | {appear?: number; enter?: number; exit?: number};

  /**
   * Add a custom transition end trigger. Called with the transitioning DOM
   * node and a done callback. Allows for more fine grained transition end
   * logic. Note: Timeouts are still used as a fallback if provided.
   */
  addEndListener?: EndHandler<HTMLElement>;
}

const OPEN_STATES: {[key in TransitionStatus]?: boolean} = {
  entering: false,
  entered: true
};

/**
 * The Transition component lets you describe a transition from one component state
 * to another over time with a simple declarative API. Most commonly it's used to
 * animate the mounting and unmounting of a component, but can also be used to
 * describe in-place transition states as well. The OpenTransition component
 * implements the Transition component.
 * @param props The props for the transition.
 */
export function OpenTransition(props: OpenTransitionProps) {
  return (
    <Transition timeout={{enter: 0, exit: 350}} {...props}>
      {(state: TransitionStatus) =>
        Children.map(
          props.children,
          (child) => child && cloneElement(child as ReactElement, {isOpen: !!OPEN_STATES[state]})
        )
      }
    </Transition>
  );
}
