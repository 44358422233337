import {createContext, MutableRefObject} from 'react';

import {PressAriaProps} from './usePress';

export interface PressResponderContextProps extends PressAriaProps<any> {
  /**
   * A method to register a PressResponderProvider.
   */
  register(): void;

  /**
   * The ref to the PressResponderProvider.
   */
  ref?: MutableRefObject<any>;
}

/**
 * A context that makes its child components respond to user presses.
 */
export const PressResponderContext = createContext<PressResponderContextProps | null>(null);

PressResponderContext.displayName = 'PressResponderContext';
