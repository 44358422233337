import {darkColorScheme} from './vars/darkColorScheme';
import {darkHighContrastColorScheme} from './vars/darkHighContrastColorScheme';
import {globalValues} from './vars/global';
import {largeScale} from './vars/largeScale';
import {lightColorScheme} from './vars/lightColorScheme';
import {lightHighContrastColorScheme} from './vars/lightHighContrastColorScheme';
import {mediumScale} from './vars/mediumScale';
import {ThemeConfiguration} from './vars/types';

export const inperiumTheme: ThemeConfiguration = {
  global: globalValues,
  light: lightColorScheme,
  lightHighContrast: lightHighContrastColorScheme,
  dark: darkColorScheme,
  darkHighContrast: darkHighContrastColorScheme,
  medium: mediumScale,
  large: largeScale
};
