/*
 * Copyright 2022 Inperium Corp. All rights reserved.
 */

export const accountingOutlined = "\uf101";
export const accountingSolid = "\uf102";
export const activityOutlined = "\uf103";
export const activitySolid = "\uf104";
export const addPhoneOutlined = "\uf105";
export const addPhoneSolid = "\uf106";
export const alignCenterOutlined = "\uf107";
export const alignCenterSolid = "\uf108";
export const alignLeftOutlined = "\uf109";
export const alignLeftSolid = "\uf10a";
export const alignRightOutlined = "\uf10b";
export const alignRightSolid = "\uf10c";
export const apiKeysOutlined = "\uf10d";
export const apiKeysSolid = "\uf10e";
export const arrowDownOutlined = "\uf10f";
export const arrowDownSolid = "\uf110";
export const arrowLeftOutlined = "\uf111";
export const arrowLeftSolid = "\uf112";
export const arrowRightOutlined = "\uf113";
export const arrowRightSolid = "\uf114";
export const arrowUpOutlined = "\uf115";
export const arrowUpSolid = "\uf116";
export const audioFileOutlined = "\uf117";
export const audioFileSolid = "\uf118";
export const audioLibraryOutlined = "\uf119";
export const audioLibrarySolid = "\uf11a";
export const autoReceptionistOutlined = "\uf11b";
export const autoReceptionistSolid = "\uf11c";
export const bellOutlined = "\uf11d";
export const bellSolid = "\uf11e";
export const boldOutlined = "\uf11f";
export const boldSolid = "\uf120";
export const brandingOutlined = "\uf121";
export const brandingSolid = "\uf122";
export const calendarOutlined = "\uf123";
export const calendarSolid = "\uf124";
export const callForwardingOutlined = "\uf125";
export const callForwardingSolid = "\uf126";
export const callHistorySolid = "\uf127";
export const callOutlined = "\uf128";
export const callQueueSolid = "\uf129";
export const callSolid = "\uf12a";
export const cardOutlined = "\uf12b";
export const cardSolid = "\uf12c";
export const caretDownOutlined = "\uf12d";
export const caretDownSolid = "\uf12e";
export const caretLeftAltOutlined = "\uf12f";
export const caretLeftAltSolid = "\uf130";
export const caretLeftOutlined = "\uf131";
export const caretLeftSolid = "\uf132";
export const caretRightAltOutlined = "\uf133";
export const caretRightAltSolid = "\uf134";
export const caretRightOutlined = "\uf135";
export const caretRightSolid = "\uf136";
export const caretUpOutlined = "\uf137";
export const caretUpSolid = "\uf138";
export const chartOutlined = "\uf139";
export const chartSolid = "\uf13a";
export const checkboxCheckedOutlined = "\uf13b";
export const checkboxCheckedSolid = "\uf13c";
export const checkboxUncheckedOutlined = "\uf13d";
export const checkboxUncheckedSolid = "\uf13e";
export const clockOutlined = "\uf13f";
export const clockSolid = "\uf140";
export const cogOutlined = "\uf141";
export const cogSolid = "\uf142";
export const coinsOutlined = "\uf143";
export const collapseOutlined = "\uf144";
export const companyOutlined = "\uf145";
export const companySolid = "\uf146";
export const contactSolid = "\uf147";
export const contactsOutlined = "\uf148";
export const contactsSolid = "\uf149";
export const copyOutlined = "\uf14a";
export const copySolid = "\uf14b";
export const creditCardOutlined = "\uf14c";
export const creditCardSolid = "\uf14d";
export const crossAltOutlined = "\uf14e";
export const crossAltSolid = "\uf14f";
export const crossOutlined = "\uf150";
export const crossSolid = "\uf151";
export const dashboardOutlined = "\uf154";
export const dashboardSolid = "\uf155";
export const dashOutlined = "\uf152";
export const dashSolid = "\uf153";
export const dealOutlined = "\uf156";
export const dealSolid = "\uf157";
export const deleteFormattingOutlined = "\uf158";
export const deleteFormattingSolid = "\uf159";
export const dotSolid = "\uf15a";
export const downloadAltOutlined = "\uf15b";
export const downloadOutlined = "\uf15c";
export const downloadSolid = "\uf15d";
export const duplicateOutlined = "\uf15e";
export const duplicateSolid = "\uf15f";
export const editOutlined = "\uf160";
export const editSolid = "\uf161";
export const emailOpenOutlined = "\uf162";
export const emailOpenSolid = "\uf163";
export const emailOutlined = "\uf164";
export const emailSolid = "\uf165";
export const eraseOutlined = "\uf166";
export const eraseSolid = "\uf167";
export const exclamationMarkCircleOutlined = "\uf168";
export const exitOutlined = "\uf169";
export const exitSolid = "\uf16a";
export const externalLinkOutlined = "\uf16b";
export const externalLinkSolid = "\uf16c";
export const eyeOffOutlined = "\uf16d";
export const eyeOffSolid = "\uf16e";
export const eyeOutlined = "\uf16f";
export const eyeSolid = "\uf170";
export const facebookOutlined = "\uf171";
export const facebookSolid = "\uf172";
export const fileOutlined = "\uf173";
export const fileSolid = "\uf174";
export const filterOutlined = "\uf175";
export const filterSolid = "\uf176";
export const flagSolid = "\uf177";
export const folderOutlined = "\uf178";
export const folderSolid = "\uf179";
export const forwardOutlined = "\uf17a";
export const geopositionSolid = "\uf17b";
export const graphSolid = "\uf17c";
export const heading1Outlined = "\uf17d";
export const heading1Solid = "\uf17e";
export const heading2Outlined = "\uf17f";
export const heading2Solid = "\uf180";
export const heading3Outlined = "\uf181";
export const heading3Solid = "\uf182";
export const heartOutlined = "\uf183";
export const heartSolid = "\uf184";
export const importExportOutlined = "\uf185";
export const importExportSolid = "\uf186";
export const inboxOutlined = "\uf187";
export const inboxSolid = "\uf188";
export const incomingCallOutlined = "\uf189";
export const incomingCallSolid = "\uf18a";
export const infoOutlined = "\uf18b";
export const infoSolid = "\uf18c";
export const inperiumOutlined = "\uf18d";
export const inperiumSolid = "\uf18e";
export const instagramOutlined = "\uf18f";
export const instagramSolid = "\uf190";
export const integrationsOutlined = "\uf191";
export const integrationsSolid = "\uf192";
export const invisibleSolid = "\uf193";
export const invoiceOutlined = "\uf194";
export const italicOutlined = "\uf195";
export const italicSolid = "\uf196";
export const ivrOutlined = "\uf197";
export const ivrSolid = "\uf198";
export const keypadSolid = "\uf199";
export const lightingOutlined = "\uf19a";
export const lightingSolid = "\uf19b";
export const lightningSolid = "\uf19c";
export const linkedinOutlined = "\uf19f";
export const linkedinSolid = "\uf1a0";
export const linkOutlined = "\uf19d";
export const linkSolid = "\uf19e";
export const listOrderedOutlined = "\uf1a1";
export const listOrderedSolid = "\uf1a2";
export const listOutlined = "\uf1a3";
export const listSolid = "\uf1a4";
export const listUnorderedOutlined = "\uf1a5";
export const listUnorderedSolid = "\uf1a6";
export const locationSolid = "\uf1a7";
export const lockOutlined = "\uf1a8";
export const lockSolid = "\uf1a9";
export const meetingSolid = "\uf1aa";
export const menuOutlined = "\uf1ab";
export const menuSolid = "\uf1ac";
export const messagesOutlined = "\uf1ad";
export const messagesSolid = "\uf1ae";
export const microphoneSolid = "\uf1af";
export const minusOutlined = "\uf1b0";
export const minusSolid = "\uf1b1";
export const missedCallOutlined = "\uf1b2";
export const missedCallSolid = "\uf1b3";
export const moonOutlined = "\uf1b4";
export const moonSolid = "\uf1b5";
export const moreAltOutlined = "\uf1b6";
export const moreAltSolid = "\uf1b7";
export const moreOutlined = "\uf1b8";
export const moreSolid = "\uf1b9";
export const moveAltOutlined = "\uf1ba";
export const muteSolid = "\uf1bb";
export const noteSolid = "\uf1bc";
export const notificationsSolid = "\uf1bd";
export const numberPortingOutlined = "\uf1be";
export const numberPortingSolid = "\uf1bf";
export const outgoingCallOutlined = "\uf1c0";
export const outgoingCallSolid = "\uf1c1";
export const paperClipOutlined = "\uf1c2";
export const paragraphOutlined = "\uf1c3";
export const paragraphSolid = "\uf1c4";
export const pauseSolid = "\uf1c5";
export const paymentsOutlined = "\uf1c6";
export const paymentsSolid = "\uf1c7";
export const personalOutlined = "\uf1c8";
export const personalSolid = "\uf1c9";
export const phoneHoldSolid = "\uf1ca";
export const phoneNumberSolid = "\uf1cb";
export const phoneSolid = "\uf1cc";
export const phoneTubeOutlined = "\uf1cd";
export const phoneTubeSolid = "\uf1ce";
export const pieChartOutlined = "\uf1cf";
export const pieChartSolid = "\uf1d0";
export const pipelineOutlined = "\uf1d1";
export const pipelineSolid = "\uf1d2";
export const playSolid = "\uf1d3";
export const plusOutlined = "\uf1d4";
export const plusSolid = "\uf1d5";
export const printSolid = "\uf1d6";
export const productOutlined = "\uf1d7";
export const productSolid = "\uf1d8";
export const propertyOutlined = "\uf1d9";
export const propertySolid = "\uf1da";
export const questionMarkCircleOutlined = "\uf1db";
export const questionMarkCircleSolid = "\uf1dc";
export const questionOutlined = "\uf1dd";
export const questionSolid = "\uf1de";
export const quoteOutlined = "\uf1df";
export const quoteSolid = "\uf1e0";
export const radioCheckedOutlined = "\uf1e1";
export const radioUncheckedOutlined = "\uf1e2";
export const recordOutlined = "\uf1e3";
export const refreshOutlined = "\uf1e4";
export const renameOutlined = "\uf1e5";
export const renameSolid = "\uf1e6";
export const replyAllSolid = "\uf1e7";
export const replySolid = "\uf1e8";
export const revenueOutlined = "\uf1e9";
export const revenueSolid = "\uf1ea";
export const rolesOutlined = "\uf1eb";
export const rolesSolid = "\uf1ec";
export const roundOutlined = "\uf1ed";
export const roundSolid = "\uf1ee";
export const saveOutlined = "\uf1ef";
export const saveSolid = "\uf1f0";
export const scaleSolid = "\uf1f1";
export const searchOutlined = "\uf1f2";
export const searchSolid = "\uf1f3";
export const securityOutlined = "\uf1f4";
export const securitySolid = "\uf1f5";
export const sellOutlined = "\uf1f6";
export const sellSolid = "\uf1f7";
export const sendOutlined = "\uf1f8";
export const sendSolid = "\uf1f9";
export const shareOutlined = "\uf1fa";
export const shieldOutlined = "\uf1fb";
export const shieldSolid = "\uf1fc";
export const snippetsOutlined = "\uf1fd";
export const snippetsSolid = "\uf1fe";
export const sortOutlined = "\uf1ff";
export const spamBlockingOutlined = "\uf200";
export const spamBlockingSolid = "\uf201";
export const speakerOffSolid = "\uf202";
export const speakerSolid = "\uf203";
export const starOutlined = "\uf204";
export const starSolid = "\uf205";
export const stopSolid = "\uf206";
export const subscriptionsOutlined = "\uf207";
export const subscriptionsSolid = "\uf208";
export const sunOutlined = "\uf209";
export const sunSolid = "\uf20a";
export const talkOutlined = "\uf20b";
export const talkSolid = "\uf20c";
export const tapeOutlined = "\uf20d";
export const taskSolid = "\uf20e";
export const teamsOutlined = "\uf20f";
export const teamsSolid = "\uf210";
export const templateOutlined = "\uf211";
export const templateSolid = "\uf212";
export const textToSpeechOutlined = "\uf213";
export const tickAltOutlined = "\uf214";
export const tickAltSolid = "\uf215";
export const tickOutlined = "\uf216";
export const tickSolid = "\uf217";
export const tilesSolid = "\uf218";
export const trackingOutlined = "\uf219";
export const trackingSolid = "\uf21a";
export const transferCallSolid = "\uf21b";
export const trashOutlined = "\uf21c";
export const trashSolid = "\uf21d";
export const trimOutlined = "\uf21e";
export const turnOffOutlined = "\uf21f";
export const twitterOutlined = "\uf220";
export const twitterSolid = "\uf221";
export const underlineOutlined = "\uf222";
export const underlineSolid = "\uf223";
export const uploadOutlined = "\uf224";
export const uploadSolid = "\uf225";
export const userOutlined = "\uf226";
export const userSolid = "\uf227";
export const usersOutlined = "\uf228";
export const usersSolid = "\uf229";
export const viewBoardOutlined = "\uf22a";
export const viewListOutlined = "\uf22b";
export const warningOutlined = "\uf22c";
export const warningSolid = "\uf22d";
export const welcomeSolid = "\uf22e";
export const wireOutlined = "\uf22f";
export const youtubeOutlined = "\uf230";
export const youtubeSolid = "\uf231";
export const zoomOutlined = "\uf232";
