import {useCallback, useEffect, useRef} from 'react';

interface GlobalListeners {
  /**
   * A method to attach a global listener to the target element.
   * @param element The target element.
   * @param type The type of event to listen for.
   * @param listener The listener function.
   * @param options Additional event listener options.
   */
  addGlobalListener<K extends keyof DocumentEventMap>(
    element: EventTarget,
    type: K,
    listener: (this: Document, ev: DocumentEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions
  ): void;

  /**
   * A method to attach a global listener to the target element.
   * @param element The target element.
   * @param type The type of event to listen for.
   * @param listener The listener function.
   * @param options Additional event listener options.
   */
  addGlobalListener(
    element: EventTarget,
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions
  ): void;

  /**
   * A method to remove a global listener from the target element.
   * @param element The target element.
   * @param type The type of event that is listened for.
   * @param listener The listener function.
   * @param options Additional event listener options.
   */
  removeGlobalListener<K extends keyof DocumentEventMap>(
    element: EventTarget,
    type: K,
    listener: (this: Document, ev: DocumentEventMap[K]) => any,
    options?: boolean | EventListenerOptions
  ): void;

  /**
   * A method to remove a global listener from the target element.
   * @param element The target element.
   * @param type The type of event that is listened for.
   * @param listener The listener function.
   * @param options Additional event listener options.
   */
  removeGlobalListener(
    element: EventTarget,
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions
  ): void;

  /**
   * A method to remove all global listeners from the target element.
   * @param element The target element.
   * @param type The type of event that is listened for.
   * @param listener The listener function.
   * @param options Additional event listener options.
   */
  removeGlobalListener(
    element: EventTarget,
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions
  ): void;

  /**
   * A method to remove all global listeners from the target element.
   */
  removeAllGlobalListeners(): void;
}

/**
 * A hook to add and remove global event listener to specific elements.
 * @returns The methods to add or remove the global event listeners.
 */
export function useGlobalListeners(): GlobalListeners {
  const globalListeners = useRef(new Map());

  let addGlobalListener = useCallback(
    (eventTarget: EventTarget, type: string, listener: any, options: AddEventListenerOptions) => {
      // Make sure we remove the listener after it is called with the `once` option.
      let fn = options?.once
        ? (...args: any) => {
            globalListeners.current.delete(listener);
            listener(...args);
          }
        : listener;
      globalListeners.current.set(listener, {type, eventTarget, fn, options});
      eventTarget.addEventListener(type, listener, options);
    },
    []
  );

  const removeGlobalListener = useCallback(
    (eventTarget: EventTarget, type: string, listener: any, options: boolean | EventListenerOptions) => {
      const fn = globalListeners.current.get(listener)?.fn || listener;
      eventTarget.removeEventListener(type, fn, options);
      globalListeners.current.delete(listener);
    },
    []
  );

  const removeAllGlobalListeners = useCallback(() => {
    globalListeners.current.forEach((value, key) => {
      removeGlobalListener(value.eventTarget, value.type, key, value.options);
    });
  }, [removeGlobalListener]);

  useEffect(() => {
    return removeAllGlobalListeners;
  }, [removeAllGlobalListeners]);

  return {addGlobalListener, removeGlobalListener, removeAllGlobalListeners};
}
