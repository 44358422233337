import {useEffect, useState} from 'react';

import {useIsSSR} from '@inperium-corp/convergo-aria-ssr';
import {Locale} from '@inperium-corp/convergo-types';

import {getDefaultLocale} from './getDefaultLocale';

let currentLocale = getDefaultLocale();
const listeners = new Set<(locale: Locale) => void>();

function updateLocale() {
  currentLocale = getDefaultLocale();
  for (const listener of listeners) {
    listener(currentLocale);
  }
}

/**
 * Returns the current browser/system language, and updates when it changes.
 * @returns The default locale of the user.
 */
export function useDefaultLocale(): Locale {
  const isSSR = useIsSSR();
  const [defaultLocale, setDefaultLocale] = useState(currentLocale);

  useEffect(() => {
    if (listeners.size === 0) {
      window.addEventListener('languagechange', updateLocale);
    }
    listeners.add(setDefaultLocale);

    return () => {
      listeners.delete(setDefaultLocale);
      if (listeners.size === 0) {
        window.removeEventListener('languagechange', updateLocale);
      }
    };
  }, []);

  // We cannot determine the browser's language on the server, so default to
  // en-US. This will be updated after hydration on the client to the correct value.
  if (isSSR) {
    return {
      locale: 'en-US',
      writingDirection: 'ltr'
    };
  }

  return defaultLocale;
}
