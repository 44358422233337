import {Children, isValidElement, ReactElement, ReactNode, useRef} from 'react';

import {Modal} from '@inperium-corp/convergo-react-overlays';

import {DialogContext} from './DialogContext';

export interface DialogContainerProps {
  /**
   * The Dialog to display.
   */
  children: ReactNode;

  /**
   * Handler that is called when the 'x' button of a dismissable Dialog is clicked.
   */
  onDismiss?: () => void;

  /**
   * The type of Dialog that should be rendered. See the visual options below for examples of each.
   * @default 'modal'
   */
  type?: 'modal' | 'fullscreen' | 'fullscreenTakeover' | 'drawer';

  /**
   * Whether the Dialog is dismissable.
   */
  isDismissable?: boolean;

  /**
   * Whether pressing the escape key to close the dialog should be disabled.
   */
  isKeyboardDismissDisabled?: boolean;
}

export function DialogContainer(props: DialogContainerProps) {
  const {children, type = 'modal', onDismiss, isDismissable, isKeyboardDismissDisabled} = props;

  const childArray = Children.toArray(children);
  if (childArray.length > 1) {
    throw new Error('Only a single child can be passed to DialogContainer.');
  }

  const lastChild = useRef<ReactElement>(null);
  const child = isValidElement(childArray[0]) ? childArray[0] : null;
  if (child) {
    lastChild.current = child;
  }

  const context = {
    type,
    onClose: onDismiss,
    isDismissable
  };

  return (
    <Modal
      isOpen={!!child}
      onClose={onDismiss}
      type={type}
      isDismissable={isDismissable}
      isKeyboardDismissDisabled={isKeyboardDismissDisabled}>
      <DialogContext.Provider value={context}>{lastChild.current}</DialogContext.Provider>
    </Modal>
  );
}
