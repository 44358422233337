import {FocusEvent, HTMLAttributes, useCallback, useRef} from 'react';

import {useSyntheticBlurEvent} from './utils';

export interface FocusWithinAriaProps<T> {
  /**
   * Whether the focus within events should be disabled.
   */
  isDisabled?: boolean;

  /**
   * Handler that is called when the target element or a descendant receives focus.
   */
  onFocusWithin?: (e: FocusEvent<T>) => void;

  /**
   * Handler that is called when the target element and all descendants lose focus.
   */
  onBlurWithin?: (e: FocusEvent<T>) => void;

  /**
   * Handler that is called when the the focus within state changes.
   */
  onFocusWithinChange?: (isFocusWithin: boolean) => void;
}

export interface FocusWithinAria<T> {
  /**
   * Props to spread onto the target element.
   */
  focusWithinProps: HTMLAttributes<T>;
}

/**
 * Handles focus events for the target and its descendants.
 * @param props The props to configure the hook.
 * @returns The focus within props.
 */
export function useFocusWithin<T extends HTMLElement>(props: FocusWithinAriaProps<T>): FocusWithinAria<T> {
  const {isDisabled, onBlurWithin, onFocusWithin, onFocusWithinChange} = props;

  const state = useRef({
    isFocusWithin: false
  });

  const onBlur = useCallback(
    (e: FocusEvent<T>) => {
      // We don't want to trigger onBlurWithin and then immediately onFocusWithin again
      // when moving focus inside the element. Only trigger if the currentTarget doesn't
      // include the relatedTarget (where focus is moving).
      if (state.current.isFocusWithin && !(e.currentTarget as Element).contains(e.relatedTarget as Element)) {
        state.current.isFocusWithin = false;

        if (onBlurWithin) {
          onBlurWithin(e);
        }

        if (onFocusWithinChange) {
          onFocusWithinChange(false);
        }
      }
    },
    [onBlurWithin, onFocusWithinChange, state]
  );

  const onSyntheticFocus = useSyntheticBlurEvent(onBlur);
  const onFocus = useCallback(
    (e: FocusEvent<T>) => {
      if (!state.current.isFocusWithin) {
        if (onFocusWithin) {
          onFocusWithin(e);
        }

        if (onFocusWithinChange) {
          onFocusWithinChange(true);
        }

        state.current.isFocusWithin = true;
        onSyntheticFocus(e);
      }
    },
    [onFocusWithin, onFocusWithinChange, onSyntheticFocus]
  );

  if (isDisabled) {
    return {
      focusWithinProps: {
        onFocus: null,
        onBlur: null
      }
    };
  }

  return {
    focusWithinProps: {
      onFocus,
      onBlur
    }
  };
}
