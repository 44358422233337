import {forwardRef, RefObject} from 'react';

import {useOverlay} from '@inperium-corp/convergo-aria-overlays';
import {useFallbackRef} from '@inperium-corp/convergo-aria-utils';
import {useSlotProps} from '@inperium-corp/convergo-react-layout';
import {ModalProps} from '@inperium-corp/convergo-types';

import {ModalWrapper} from './ModalWrapper';
import {Overlay} from './Overlay';
import {Underlay} from './Underlay';

export const Modal = forwardRef((props: ModalProps, ref?: RefObject<HTMLDivElement>) => {
  props = useSlotProps('modal', props);
  const {children, onClose, type, ...otherProps} = props;

  ref = useFallbackRef<HTMLDivElement>(ref);

  const {overlayProps, underlayProps} = useOverlay(props, ref);

  return (
    <Overlay {...otherProps}>
      <Underlay {...underlayProps} />
      <ModalWrapper onClose={onClose} type={type} ref={ref} overlayProps={overlayProps}>
        {children}
      </ModalWrapper>
    </Overlay>
  );
});
