import {HTMLAttributes, RefObject, useCallback, useRef, useState} from 'react';

import {useLocale} from '@inperium-corp/convergo-aria-i18n';
import {useLayoutEffect} from '@inperium-corp/convergo-aria-ssr';
import {translatePlacement} from '@inperium-corp/convergo-aria-utils';
import {Alignment, Placement, PlacementDirection, PositionProps} from '@inperium-corp/convergo-types';

import {calculatePosition, PositionResult} from './calculatePosition';
import {useCloseOnScroll} from './useCloseOnScroll';

export interface OverlayPositionAriaProps<TPlacement extends Placement = Placement> extends PositionProps<TPlacement> {
  /**
   * Element that serves as the positioning boundary.
   * @default document.body
   */
  boundaryElement?: HTMLElement;

  /**
   * The ref for the element which the overlay positions itself with respect to.
   */
  targetRef: RefObject<HTMLElement>;

  /**
   * The ref for the overlay element.
   */
  overlayRef: RefObject<HTMLElement>;

  /**
   * A ref for the scrollable region within the overlay.
   * @default overlayRef
   */
  scrollRef?: RefObject<HTMLElement>;

  /**
   * Whether the overlay should update its position automatically.
   * @default true
   */
  shouldUpdatePosition?: boolean;

  /**
   * Handler that is called when the overlay should close.
   */
  onClose?: () => void;

  /**
   * The default alignment of placement.
   * @default end
   */
  defaultAlignment?: Alignment;

  /**
   * The maxHeight specified for the overlay element.
   * By default, it will take all space up to the current viewport height.
   */
  maxHeight?: number;
}

export interface OverlayPositionAria<TDirection> {
  /**
   * Props for the overlay container element.
   */
  overlayProps: HTMLAttributes<Element>;

  /**
   * Props for the overlay tip arrow if any.
   */
  arrowProps: HTMLAttributes<Element>;

  /**
   * Placement of the overlay with respect to the overlay trigger.
   */
  direction: TDirection;

  /**
   * A method to update the position of the overlay programmatically.
   */
  updatePosition(): void;
}

const visualViewport = typeof window !== 'undefined' && window.visualViewport;

/**
 * Handles positioning overlays like popovers and menus relative to a trigger
 * element, and updating the position when the window resizes.
 * @param props The props to be applied to the overlay position hook.
 * @returns The aria props to be spread on the overlay elements to position them.
 */
export function useOverlayPosition<TPlacement extends Placement>(
  props: OverlayPositionAriaProps<TPlacement>
): OverlayPositionAria<PlacementDirection<TPlacement>> {
  const {writingDirection} = useLocale();
  const {
    targetRef,
    overlayRef,
    scrollRef = overlayRef,
    placement = 'bottom',
    containerPadding = 12,
    shouldFlip = true,
    boundaryElement = typeof document !== 'undefined' ? document.body : null,
    offset = 0,
    crossOffset = 0,
    shouldUpdatePosition = true,
    isOpen = true,
    onClose,
    defaultAlignment = 'center',
    maxHeight
  } = props;

  const [position, setPosition] = useState<PositionResult>({
    position: {},
    arrowOffsetLeft: undefined,
    arrowOffsetTop: undefined,
    maxHeight: undefined,
    direction: undefined
  });

  const deps = [
    shouldUpdatePosition,
    placement,
    overlayRef.current,
    targetRef.current,
    scrollRef.current,
    containerPadding,
    shouldFlip,
    boundaryElement,
    offset,
    crossOffset,
    isOpen,
    maxHeight
  ];

  const updatePosition = useCallback(() => {
    if (
      shouldUpdatePosition === false ||
      !isOpen ||
      !overlayRef.current ||
      !targetRef.current ||
      !scrollRef.current ||
      !boundaryElement
    ) {
      return;
    }

    setPosition(
      calculatePosition({
        placement: translatePlacement(placement, writingDirection),
        overlayNode: overlayRef.current,
        targetNode: targetRef.current,
        scrollNode: scrollRef.current,
        padding: containerPadding,
        shouldFlip,
        boundaryElement,
        offset,
        crossOffset,
        defaultAlignment: translatePlacement(defaultAlignment, writingDirection),
        maxHeight
      })
    );
    // We are declaring the deps above, so we can disable the ESlint rule.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  // Update the position when anything changes.
  // We are declaring the deps above, so we can disable the ESlint rule.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(updatePosition, deps);

  // Update the position when the window is being resized.
  useResize(updatePosition);

  // Reposition the overlay and do not close on scroll while the visual viewport is resizing.
  // This will ensure that overlays adjust their positioning when the iOS virtual keyboard appears.
  const isResizing = useRef(false);
  useLayoutEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    const onResize = () => {
      isResizing.current = true;
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        isResizing.current = false;
      }, 500);

      updatePosition();
    };

    visualViewport?.addEventListener('resize', onResize);

    return () => {
      visualViewport?.removeEventListener('resize', onResize);
    };
  }, [updatePosition]);

  const close = useCallback(() => {
    if (!isResizing.current) {
      onClose();
    }
  }, [onClose, isResizing]);

  // When scrolling a parent scrollable region of the trigger (other than the body),
  // we hide the popover. Otherwise, its position would be incorrect.
  useCloseOnScroll({isOpen, onClose: onClose ? close : undefined}, targetRef);

  return {
    overlayProps: {
      style: {
        position: 'absolute',
        zIndex: 100000, // Should match the z-index in ModalTrigger!
        ...position.position,
        maxHeight: position.maxHeight
      }
    },
    direction: position.direction,
    arrowProps: {
      style: {
        left: position.arrowOffsetLeft,
        top: position.arrowOffsetTop
      }
    },
    updatePosition
  };
}

/**
 * A hook that executes a method when the window is being resized.
 * @param onResize The method to execute on resize.
 */
function useResize(onResize: () => void) {
  useLayoutEffect(() => {
    window.addEventListener('resize', onResize, false);

    return () => {
      window.removeEventListener('resize', onResize, false);
    };
  }, [onResize]);
}
