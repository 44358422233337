import {
  createContext,
  forwardRef,
  HTMLAttributes,
  MutableRefObject,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useRef
} from 'react';

import {useKeyboard} from '@inperium-corp/convergo-aria-interactions';
import {mergeProps, useSyncRef} from '@inperium-corp/convergo-aria-utils';
import {FocusableDOMProps, FocusableProps} from '@inperium-corp/convergo-types';

import {focusSafely} from './focusSafely';
import {useFocus} from './useFocus';

export interface FocusableAriaProps<T extends HTMLElement> extends FocusableProps<T>, FocusableDOMProps {
  /**
   * Whether focus should be disabled.
   */
  isDisabled?: boolean;
}

export interface FocusableAria<T extends HTMLElement> {
  /**
   * Props to spread onto the target element.
   */
  focusableProps: HTMLAttributes<T>;
}

/**
 * Make an element focusable and capable of auto focus by using this hook.
 * @param props The props to configure the hook.
 * @param ref A ref object to the element.
 * @returns The props to make the element focusable.
 */
export function useFocusable<T extends HTMLElement>(props: FocusableAriaProps<T>, ref: RefObject<T>): FocusableAria<T> {
  const {focusProps} = useFocus<T>(props);
  const {keyboardProps} = useKeyboard<T>(props);
  const interactionMethods = mergeProps(focusProps, keyboardProps);
  const domProps = useFocusableContext(ref);
  const interactionAttributes = props.isDisabled ? {} : domProps;
  const autoFocusRef = useRef(props.autoFocus);

  useEffect(() => {
    if (autoFocusRef.current && ref.current) {
      focusSafely(ref.current);
    }
    autoFocusRef.current = false;
  }, [ref]);

  return {
    focusableProps: mergeProps(
      {
        ...interactionMethods,
        tabIndex: props.excludeFromTabOrder && !props.isDisabled ? -1 : undefined
      },
      interactionAttributes
    )
  };
}

export interface FocusableContextProps<T extends HTMLElement> extends FocusableProviderProps<T> {
  /**
   * The ref to the FocusableProvider.
   */
  ref?: MutableRefObject<T | null>;
}

export interface FocusableProviderProps<T extends HTMLElement> extends HTMLAttributes<T> {
  /**
   * The child element to provide DOM props to.
   */
  children?: ReactNode;
}

const FocusableContext = createContext<FocusableContextProps<any> | null>(null);

FocusableContext.displayName = 'FocusableContext';

/**
 * Provides DOM props to the nearest focusable child.
 */
export const FocusableProvider = forwardRef(
  (props: FocusableProviderProps<HTMLElement>, ref: MutableRefObject<HTMLElement>) => {
    const {children, ...otherProps} = props;
    const context = {
      ...otherProps,
      ref
    };

    return <FocusableContext.Provider value={context}>{children}</FocusableContext.Provider>;
  }
);

/**
 * Provides DOM props to the nearest focusable child.
 * @param ref The ref to the parent element.
 * @returns The focusable context props.
 */
export function useFocusableContext<T extends HTMLElement>(ref: RefObject<T>): FocusableContextProps<T> {
  const context = useContext(FocusableContext) || {};

  useSyncRef(ref, context?.ref);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {ref: _, ...otherProps} = context;

  return otherProps;
}
