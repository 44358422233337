// Adapted from https://github.com/testing-library/jest-dom and https://github.com/vuejs/vue-test-utils-next/

/**
 * Checks if an element is visible to the user.
 * @param element The element to evaluate for visibility.
 * @param childElement An optional child element.
 * @returns Whether the element is visible.
 */
export function isElementVisible(element: Element, childElement?: Element): boolean {
  return (
    element.nodeName !== '#comment' &&
    isStyleVisible(element) &&
    isAttributeVisible(element, childElement) &&
    (!element.parentElement || isElementVisible(element.parentElement, element))
  );
}

/**
 * Checks if an element is visible based on its style.
 * @param element The element to evaluate for visibility.
 * @returns Whether the element is visible.
 */
function isStyleVisible(element: Element) {
  if (!(element instanceof HTMLElement) && !(element instanceof SVGElement)) {
    return false;
  }

  const {display, visibility} = element.style;

  let isVisible = display !== 'none' && visibility !== 'hidden' && visibility !== 'collapse';

  if (isVisible) {
    const {getComputedStyle} = element.ownerDocument.defaultView;
    const {display: computedDisplay, visibility: computedVisibility} = getComputedStyle(element);

    isVisible = computedDisplay !== 'none' && computedVisibility !== 'hidden' && computedVisibility !== 'collapse';
  }

  return isVisible;
}

/**
 * Checks if an element is visible based on its attributes.
 * @param element The element to evaluate for visibility.
 * @param childElement An optional child element.
 * @returns Whether the element is visible.
 */
function isAttributeVisible(element: Element, childElement?: Element) {
  return (
    !element.hasAttribute('hidden') &&
    (element.nodeName === 'DETAILS' && childElement && childElement.nodeName !== 'SUMMARY'
      ? element.hasAttribute('open')
      : true)
  );
}
