/* eslint-disable */
export const translation = {
  timing_out_modal: {
    still_here: 'Noch da?',
    explanation: 'Sie waren für einige Zeit inaktiv.',
    log_you_out_in: 'Wir müssen Sie ausloggen in',
    im_here: `Weiter`,
    logout: 'Ausloggen'
  }
};
