import {blue, gray, green, neutral, red, slate, violet, yellow} from '../generateColors';

const colorVariables = {
  blue: {
    50: blue('98%'),
    100: blue('96%'),
    150: blue('91%'),
    200: blue('86%'),
    300: blue('76%'),
    400: blue('66%'),
    500: blue('56%'),
    600: blue('45%'),
    700: blue('34%'),
    750: blue('29%'),
    800: blue('23%'),
    850: blue('18%'),
    900: blue('12%')
  },
  gray: {
    50: gray('97%'),
    100: gray('93%'),
    150: gray('89%'),
    200: gray('85%'),
    300: gray('77%'),
    400: gray('69%'),
    500: gray('61%'),
    600: gray('52%'),
    700: gray('43%'),
    750: gray('39%'),
    800: gray('35%'),
    850: gray('30%'),
    900: gray('25%')
  },
  neutral: {
    50: neutral('97%'),
    100: neutral('93%'),
    150: neutral('84%'),
    200: neutral('74%'),
    300: neutral('66%'),
    400: neutral('58%'),
    500: neutral('48%'),
    600: neutral('40%'),
    700: neutral('33%'),
    750: neutral('25%'),
    800: neutral('18%'),
    850: neutral('13%'),
    900: neutral('0%')
  },
  slate: {
    50: slate('10%', '97%'),
    100: slate('10%', '93%'),
    150: slate('10%', '84%'),
    200: slate('10%', '74%'),
    300: slate('10%', '66%'),
    400: slate('10%', '58%'),
    500: slate('10%', '50%'),
    600: slate('13%', '42%'),
    700: slate('15%', '33%'),
    750: slate('15%', '25%'),
    800: slate('15%', '18%'),
    850: slate('15%', '13%'),
    900: slate('15%', '1%')
  },
  green: {
    50: green('95%'),
    100: green('90%'),
    150: green('85%'),
    200: green('80%'),
    300: green('70%'),
    400: green('60%'),
    500: green('50%'),
    600: green('41%'),
    700: green('32%'),
    750: green('24%'),
    800: green('19%'),
    850: green('14%'),
    900: green('6%')
  },
  yellow: {
    50: yellow('91%'),
    100: yellow('86%'),
    150: yellow('83%'),
    200: yellow('78%'),
    300: yellow('71%'),
    400: yellow('63%'),
    500: yellow('55%'),
    600: yellow('45%'),
    700: yellow('36%'),
    750: yellow('32%'),
    800: yellow('27%'),
    850: yellow('23%'),
    900: yellow('18%')
  },
  red: {
    50: red('95%'),
    100: red('92%'),
    150: red('87%'),
    200: red('83%'),
    300: red('75%'),
    400: red('67%'),
    500: red('59%'),
    600: red('50%'),
    700: red('41%'),
    750: red('37%'),
    800: red('33%'),
    850: red('28%'),
    900: red('23%')
  },
  violet: {
    50: violet('92%'),
    100: violet('86%'),
    150: violet('80%'),
    200: violet('76%'),
    300: violet('68%'),
    400: violet('60%'),
    500: violet('52%'),
    600: violet('43%'),
    700: violet('34%'),
    750: violet('29%'),
    800: violet('25%'),
    850: violet('20%'),
    900: violet('16%')
  },
  white: 'rgb(255, 255, 255)',
  black: 'rgb(0, 0, 0)'
};

const textVariables = {
  color: colorVariables.blue[800]
};

const headingVariables = {
  color: colorVariables.blue[800]
};

const actionGroupVariables = {
  button: {
    color: colorVariables.blue[800],
    background: colorVariables.gray[100],
    backgroundActive: colorVariables.white,
    backgroundHovered: colorVariables.gray[50],
    backgroundActiveHovered: colorVariables.white
  }
};

const alertVariables = {
  severities: {
    success: {
      background: colorVariables.green[300],
      borderColor: colorVariables.green[300],
      color: colorVariables.green[700]
    },
    error: {
      background: colorVariables.red[150],
      borderColor: colorVariables.red[150],
      color: colorVariables.red[750]
    },
    info: {
      background: colorVariables.blue[150],
      borderColor: colorVariables.blue[150],
      color: colorVariables.blue[600]
    },
    warning: {
      background: colorVariables.yellow[100],
      borderColor: colorVariables.yellow[100],
      color: colorVariables.yellow[700]
    }
  },
  closeButton: {
    background: 'transparent',
    backgroundActive: 'transparent',
    borderColor: 'transparent',
    borderColorActive: colorVariables.blue[750]
  }
};

const dividerVariables = {
  background: colorVariables.gray[300]
};

const sideNavigationVariables = {
  background: colorVariables.gray[50],
  item: {
    color: colorVariables.blue[800],
    colorHover: colorVariables.blue[800],
    colorSelected: colorVariables.blue[800],
    colorDisabled: colorVariables.blue[300],
    colorKeyFocus: colorVariables.blue[800],
    background: colorVariables.gray[50],
    backgroundHover: colorVariables.gray[100],
    backgroundSelected: colorVariables.gray[150],
    backgroundDisabled: colorVariables.gray[50],
    backgroundDown: colorVariables.gray[200],
    backgroundKeyFocus: colorVariables.gray[100],
    borderColor: 'transparent',
    borderColorKeyFocus: colorVariables.blue[500]
  },
  header: {
    color: colorVariables.gray[500]
  }
};

export const lightColorScheme = {
  name: 'light',
  color: colorVariables,
  text: textVariables,
  heading: headingVariables,
  actionGroup: actionGroupVariables,
  alert: alertVariables,
  divider: dividerVariables,
  sideNavigation: sideNavigationVariables
} as const;
