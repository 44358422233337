import {createContext} from 'react';

import {IInperiumAuthInstance} from './IInperiumAuthInstance';

/**
 * The application wide context that holds an Inperium authentication instance.
 */
export const InperiumAuthContext = createContext<IInperiumAuthInstance>({
  initialized: false,
  authenticated: false,
  setTokens: () => undefined,
  login: () => Promise.reject(),
  refreshTokens: () => Promise.reject(),
  logout: () => undefined,
  redirectToLogin: () => undefined,
  iframe: false,
  config: {
    loginUri: '',
    afterLoginRedirectUri: '',
    afterLogoutRedirectUri: '',
    logoutCountdownSeconds: 1.5 * 60,
    hubUrl: ''
  },
  isUserActive: false,
  iframeLocalStorageSupported: false
});
