/**
 * Returns cookie by it's name
 * @param name cookie name
 */
export const getCookie = (name: string) => {
  const matches =
    typeof document !== 'undefined'
      ? // eslint-disable-next-line no-useless-escape
        document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`))
      : null;
  if (!matches) {
    return undefined;
  }
  try {
    return decodeURIComponent(matches[1]);
  } catch (error) {
    return matches[1];
  }
};
