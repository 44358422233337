import {HTMLAttributes, ReactNode, useContext, useMemo, useState} from 'react';

import {ModalContext} from './ModalContext';

export interface ModalProviderProps extends HTMLAttributes<HTMLElement> {
  /**
   * The children of the modal provider.
   */
  children: ReactNode;
}

/**
 * Each ModalProvider tracks how many modals are open in its subtree. On mount, the modals
 * trigger `addModal` to increment the count, and trigger `removeModal` on unmount to decrement it.
 * This is done recursively so that all parent providers are incremented and decremented.
 * If the modal count is greater than zero, we add `aria-hidden` to this provider to hide its
 * subtree from screen readers. This is done using React context in order to account for things
 * like portals, which can cause the React tree and the DOM tree to differ significantly in structure.
 */
export function ModalProvider(props: ModalProviderProps) {
  const {children} = props;

  const parent = useContext(ModalContext);

  const [modalCount, setModalCount] = useState(0);

  const context = useMemo(
    () => ({
      parent,
      modalCount,
      addModal() {
        setModalCount((count) => count + 1);
        if (parent) {
          parent.addModal();
        }
      },
      removeModal() {
        setModalCount((count) => count - 1);
        if (parent) {
          parent.removeModal();
        }
      }
    }),
    [parent, modalCount]
  );

  return <ModalContext.Provider value={context}>{children}</ModalContext.Provider>;
}
