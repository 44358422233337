import {createContext, HTMLAttributes} from 'react';

export interface DialogContextProps extends HTMLAttributes<HTMLElement> {
  /**
   * The type of dialog.
   */
  type: 'modal' | 'popover' | 'tray' | 'fullscreen' | 'fullscreenTakeover' | 'drawer';

  /**
   * If the dialog is dismissable.
   */
  isDismissable?: boolean;

  /**
   * A method executed on closing the dialog.
   */
  onClose?: () => void;
}

export const DialogContext = createContext<DialogContextProps>(null);
