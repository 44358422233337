import {focusSafely} from './focusSafely';

/**
 * Programmatically focuses an element in the browser safely.
 * @param element The element to focus.
 * @param scroll If the browser should scroll to the element.
 */
export function focusElement(element: HTMLElement | null, scroll = false): void {
  if (element != null && !scroll) {
    try {
      focusSafely(element);
    } catch (error) {
      // Ignore errors.
    }
  } else if (element != null) {
    try {
      element.focus();
    } catch (error) {
      // Ignore errors.
    }
  }
}
