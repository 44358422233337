import {useMemo, useRef} from 'react';

import {useLocale} from '../useLocale';
import {DateFormatter} from './DateFormatter';

export interface DateFormatterOptions extends Intl.DateTimeFormatOptions {
  calendar?: string;
}

/**
 * Provides localized date formatting for the current locale. Automatically updates
 * when the locale changes, and handles caching of the date formatter for performance.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 * @param options Formatting options.
 * @returns The date formatter.
 */
export function useDateFormatter(options?: DateFormatterOptions): DateFormatter {
  // Reuse last options object if it is shallowly equal, which allows the useMemo result to also be reused.
  const lastOptions = useRef(null);
  if (options && lastOptions.current && isEqual(options, lastOptions.current)) {
    options = lastOptions.current;
  }

  lastOptions.current = options;

  const {locale} = useLocale();
  return useMemo(() => new DateFormatter(locale, options), [locale, options]);
}

function isEqual(a: DateFormatterOptions, b: DateFormatterOptions) {
  if (a === b) {
    return true;
  }

  const aKeys = Object.keys(a) as (keyof DateFormatterOptions)[];
  const bKeys = Object.keys(b) as (keyof DateFormatterOptions)[];
  if (aKeys.length !== bKeys.length) {
    return false;
  }

  for (const key of aKeys) {
    if (b[key] !== a[key]) {
      return false;
    }
  }

  return true;
}
