import {createContext} from 'react';

import {FocusManager, ScopeRef} from './FocusScopeProvider';

export interface FocusContext {
  scopeRef: ScopeRef;
  focusManager: FocusManager;
}

export const FocusScopeContext = createContext<FocusContext>(null);
