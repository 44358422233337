import {useCallback, useEffect, useRef, useState} from 'react';

export type InterceptValue<T> = (prevSate: T) => T;

const isCallback = <T>(value: T | InterceptValue<T>): value is InterceptValue<T> => typeof value === 'function';

/**
 * A hook to use a controlled state for a component in an accessible manner.
 * @param value The value of the component.
 * @param defaultValue The default value of the component.
 * @param onChange An onChange handler for the component.
 */
export function useControlledState<T, U extends unknown[] = any[]>(
  value?: T,
  defaultValue?: T,
  onChange?: (value: T | undefined, ...args: U) => void | undefined
): [value: T, setValue: (value: T | InterceptValue<T>, ...args: U) => void, isControlled: boolean] {
  const [stateValue, setStateValue] = useState<T>(value || defaultValue);

  const isControlled = value !== undefined;

  const ref = useRef(isControlled);
  const wasControlled = ref.current;

  // Internal state reference for useCallback
  const stateRef = useRef(stateValue);

  if (wasControlled !== isControlled) {
    console.warn(
      `WARN: A component changed from ${wasControlled ? 'controlled' : 'uncontrolled'} to ${
        isControlled ? 'controlled' : 'uncontrolled'
      }.`
    );
  }

  ref.current = isControlled;

  // Use ref here to make the dispatch callback immutable as React does.
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const handleChange = useCallback((value: T, ...args: U) => {
    if (onChangeRef.current) {
      if (!Object.is(stateRef.current, value)) {
        onChangeRef.current(value, ...args);
      }
    }
  }, []);

  /**
   * This supports functional updates https://reactjs.org/docs/hooks-reference.html#functional-updates
   * when someone using useControlledState calls setControlledState(myFunc)
   * this will call our useState setState with a function as well which invokes myFunc and calls onChange with the value from myFunc
   * if we're in an uncontrolled state, then we also return the value of myFunc which to setState looks as though it was just called with myFunc from the beginning
   * otherwise we just return the controlled value, which won't cause a rerender because React knows to bail out when the value is the same.
   */
  const setValueControlled = useCallback(
    (value: T | InterceptValue<T>, ...args: U) => {
      const interceptValue = isCallback(value) ? value : () => value;

      const interceptedValue = interceptValue(stateRef.current);
      handleChange(interceptedValue, ...args);
    },
    [handleChange]
  );

  const onChangeCallbackRef = useRef(null);

  /**
   * Call onChange handler only on the next render after value is set for uncontrolled variant
   * to avoid "Cannot update a component ... While rendering a different component" error.
   */
  useEffect(() => {
    onChangeCallbackRef.current?.();
    onChangeCallbackRef.current = null;
  });

  const callbackEffect = useCallbackEffect();

  const setValueUncontrolled = useCallback(
    (value: T | InterceptValue<T>, ...args: U) => {
      const interceptValue = isCallback(value) ? value : () => value;

      setStateValue((oldValue: T) => {
        const interceptedValue = interceptValue(oldValue);

        if (!Object.is(interceptedValue, stateRef.current)) {
          callbackEffect(() => {
            handleChange(interceptedValue, ...args);
            stateRef.current = interceptedValue;
          });
        }

        return interceptedValue;
      });
    },
    [handleChange, callbackEffect]
  );

  // If a controlled component's value prop changes, we need to update stateRef
  if (isControlled) {
    stateRef.current = value;
  } else {
    value = stateValue;
  }

  return [value, isControlled ? setValueControlled : setValueUncontrolled, isControlled];
}

const useCallbackEffect = () => {
  const callbackRef = useRef<() => void>();

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current();
      callbackRef.current = null;
    }
  });

  return useCallback((callback: () => void) => {
    callbackRef.current = callback;
  }, []);
};
