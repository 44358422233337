import {FocusEvent, HTMLAttributes, useCallback} from 'react';

import {FocusEvents} from '@inperium-corp/convergo-types';

import {useSyntheticBlurEvent} from './utils';

export interface FocusAriaProps<T extends HTMLElement> extends FocusEvents<T> {
  /**
   * Whether the focus events should be disabled.
   */
  isDisabled?: boolean;
}

export interface FocusAria<T extends HTMLElement> {
  /**
   * Props to spread onto the target element.
   */
  focusProps: HTMLAttributes<T>;
}

/**
 * Returns methods to handle focus events for the immediate target.
 * Focus events on child elements will be ignored.
 * @param props The props to configure the hook.
 * @returns The desired methods.
 */
export function useFocus<T extends HTMLElement>(props: FocusAriaProps<T>): FocusAria<T> {
  const {isDisabled, onFocus: onFocusProp, onBlur: onBlurProp, onFocusChange} = props;

  const onBlur: FocusAriaProps<T>['onBlur'] = useCallback(
    (e: FocusEvent<T>) => {
      if (e.target === e.currentTarget) {
        if (onBlurProp) {
          onBlurProp(e);
        }

        if (onFocusChange) {
          onFocusChange(false);
        }

        return true;
      }
    },
    [onBlurProp, onFocusChange]
  );

  const onSyntheticFocus = useSyntheticBlurEvent(onBlur);

  const onFocus: FocusAriaProps<T>['onFocus'] = useCallback(
    (e: FocusEvent<T>) => {
      if (e.target === e.currentTarget) {
        if (onFocusProp) {
          onFocusProp(e);
        }

        if (onFocusChange) {
          onFocusChange(true);
        }

        onSyntheticFocus(e);
      }
    },
    [onFocusChange, onFocusProp, onSyntheticFocus]
  );

  return {
    focusProps: {
      onFocus: !isDisabled && (onFocusProp || onFocusChange || onBlurProp) ? onFocus : undefined,
      onBlur: !isDisabled && (onBlurProp || onFocusChange) ? onBlur : null
    }
  };
}
