const dimensionValues = {
  scaleFactor: 1,
  size: {
    0: '0px',
    5: '0.5px',
    10: '1px',
    25: '2px',
    40: '3px',
    50: '4px',
    65: '5px',
    75: '6px',
    85: '7px',
    100: '8px',
    115: '9px',
    125: '10px',
    130: '11px',
    150: '12px',
    160: '13px',
    175: '14px',
    200: '16px',
    225: '18px',
    250: '20px',
    275: '22px',
    300: '24px',
    325: '26px',
    350: '28px',
    400: '32px',
    450: '36px',
    500: '40px',
    550: '44px',
    600: '48px',
    675: '54px',
    700: '56px',
    800: '64px',
    900: '72px',
    1000: '80px',
    1200: '96px',
    1250: '100px',
    1600: '128px',
    1700: '136px',
    2000: '160px',
    2400: '192px',
    3000: '240px',
    3400: '272px',
    3600: '288px',
    4600: '368px',
    5000: '400px',
    6000: '480px'
  },
  fontSize: {
    25: '1rem',
    50: '1.1rem',
    75: '1.2rem',
    100: '1.4rem',
    150: '1.5rem',
    200: '1.6rem',
    300: '1.8rem',
    400: '2rem',
    500: '2.2rem',
    600: '2.4rem',
    700: '2.8rem',
    800: '3.2rem',
    900: '3.6rem',
    1000: '4rem',
    1100: '4.5rem',
    1200: '5rem',
    1300: '6rem'
  }
};

const fontValues = {
  size: {
    small: dimensionValues.fontSize[75],
    medium: dimensionValues.fontSize[100],
    large: dimensionValues.fontSize[200],
    xlarge: dimensionValues.fontSize[400],
    '2xlarge': dimensionValues.fontSize[700],
    '3xlarge': dimensionValues.fontSize[900]
  },
  lineHeight: {
    small: dimensionValues.fontSize[300],
    medium: dimensionValues.fontSize[500],
    large: dimensionValues.fontSize[600],
    xlarge: dimensionValues.fontSize[700],
    '2xlarge': dimensionValues.fontSize[900],
    '3xlarge': dimensionValues.fontSize[1200]
  }
};

const borderRadiusValues = {
  xsmall: dimensionValues.size[10],
  small: dimensionValues.size[25],
  regular: dimensionValues.size[50],
  medium: dimensionValues.size[100],
  large: dimensionValues.size[200]
} as const;

const actionGroupValues = {
  gap: dimensionValues.size[25],
  borderRadius: dimensionValues.size[115],
  button: {
    fontSize: fontValues.size.medium,
    lineHeight: fontValues.lineHeight.medium,
    borderRadius: dimensionValues.size[85]
  },
  text: {
    fontSize: dimensionValues.fontSize[200],
    marginX: dimensionValues.size[150],
    marginY: dimensionValues.size[65]
  },
  icon: {
    fontSize: dimensionValues.fontSize[100],
    marginX: dimensionValues.size[150],
    marginY: dimensionValues.size[115]
  },
  menu: {
    gap: dimensionValues.size[225],
    fontSize: dimensionValues.fontSize[200]
  },
  item: {
    borderRadius: dimensionValues.size[115],
    marginX: dimensionValues.size[25],
    marginY: dimensionValues.size[25],
    paddingX: dimensionValues.size[25],
    paddingY: dimensionValues.size[25]
  }
};

const alertValues = {
  borderRadius: dimensionValues.size[50],
  borderWidth: dimensionValues.size[10],
  paddingX: dimensionValues.size[125],
  paddingY: dimensionValues.size[125],
  gap: dimensionValues.size[100],
  fontSize: fontValues.size.medium,
  closeButton: {
    borderRadius: dimensionValues.size[50],
    borderWidth: dimensionValues.size[25],
    paddingX: dimensionValues.size[25],
    paddingY: dimensionValues.size[25]
  }
};

const actionButtonVariables = {
  height: dimensionValues.size[100],
  minWidth: dimensionValues.size[100],
  borderRadius: dimensionValues.size[50],
  borderWidth: dimensionValues.size[10],
  fontSize: fontValues.size.medium,
  lineHeight: fontValues.lineHeight.medium,
  text: {
    paddingX: dimensionValues.size[25],
    paddingY: dimensionValues.size[25]
  },
  icon: {
    paddingX: dimensionValues.size[25],
    paddingY: dimensionValues.size[25]
  },
  holdIcon: {
    paddingX: dimensionValues.size[25],
    paddingY: dimensionValues.size[25]
  }
};

const calendarVariables = {
  gap: dimensionValues.size[400],
  minWidth: dimensionValues.size[3000],
  maxWidth: dimensionValues.size[3400],
  borderWidthReset: dimensionValues.size[0],
  borderRadiusReset: dimensionValues.size[0],
  button: {
    gap: dimensionValues.size[50]
  },
  focusRing: {
    gap: dimensionValues.size[10]
  },
  title: {
    fontSize: fontValues.size.large,
    lineHeight: fontValues.lineHeight.large
  },
  day: {
    height: dimensionValues.size[500],
    width: dimensionValues.size[500],
    paddingX: dimensionValues.size[0],
    paddingY: dimensionValues.size[40],
    fontSize: fontValues.size.medium,
    fontSizeHan: fontValues.size.small,
    lineHeight: fontValues.lineHeight.medium
  }
};

const dividerVariables = {
  small: {
    height: dimensionValues.size[5],
    borderRadius: dimensionValues.size[5]
  },
  medium: {
    height: dimensionValues.size[25],
    borderRadius: dimensionValues.size[10]
  },
  large: {
    height: dimensionValues.size[50],
    borderRadius: dimensionValues.size[25]
  }
};

const mainNavigationVariables = {
  width: dimensionValues.size[10],
  paddingX: dimensionValues.size[0],
  paddingY: dimensionValues.size[40]
};

const sideNavigationVariables = {
  minWidth: dimensionValues.size[3000],
  paddingX: dimensionValues.size[300],
  paddingY: dimensionValues.size[225],
  item: {
    gapX: 0,
    gapY: dimensionValues.size[25],
    paddingX: dimensionValues.size[150],
    paddingY: dimensionValues.size[115],
    height: dimensionValues.size[400],
    borderRadius: borderRadiusValues.medium,
    fontSize: fontValues.size.small
  },
  icon: {
    gap: dimensionValues.size[125],
    fontSize: fontValues.size.medium
  },
  focusRing: {
    size: dimensionValues.size[10]
  },
  header: {
    paddingX: dimensionValues.size[150],
    paddingY: 0,
    gapTop: dimensionValues.size[100],
    gapBottom: dimensionValues.size[100],
    height: dimensionValues.size[225],
    borderRadius: borderRadiusValues.medium,
    fontSize: fontValues.size.small
  }
};

export const mediumScale = {
  name: 'medium',
  dimension: dimensionValues,
  font: fontValues,
  borderRadius: borderRadiusValues,
  actionGroup: actionGroupValues,
  alert: alertValues,
  actionButton: actionButtonVariables,
  calendar: calendarVariables,
  divider: dividerVariables,
  mainNavigation: mainNavigationVariables,
  sideNavigation: sideNavigationVariables
} as const;
