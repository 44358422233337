import {HTMLAttributes, KeyboardEvent, PointerEvent, RefObject, SyntheticEvent, useEffect} from 'react';

import {useFocusWithin} from '@inperium-corp/convergo-aria-focus';
import {useInteractOutside} from '@inperium-corp/convergo-aria-interactions';

export interface OverlayAriaProps {
  /**
   * Whether the overlay is currently open.
   */
  isOpen?: boolean;

  /**
   * Handler that is called when the overlay should close.
   */
  onClose?: () => void;

  /**
   * Whether to close the overlay when the user interacts outside it.
   * @default false
   */
  isDismissable?: boolean;

  /**
   * Whether the overlay should close when focus is lost or moves outside it.
   */
  shouldCloseOnBlur?: boolean;

  /**
   * Whether pressing the escape key to close the overlay should be disabled.
   * @default false
   */
  isKeyboardDismissDisabled?: boolean;

  /**
   * When user interacts with the argument element outside of the overlay ref,
   * return true if onClose should be called.  This gives you a chance to filter
   * out interaction with elements that should not dismiss the overlay.
   * By default, onClose will always be called on interaction outside the overlay ref.
   * @param element The element the user interacted with outside of the overlay.
   * @returns Whether the overlay should be closed.
   */
  shouldCloseOnInteractOutside?: (element: HTMLElement) => boolean;
}

interface OverlayAria {
  /**
   * Props to apply to the overlay container element.
   */
  overlayProps: HTMLAttributes<HTMLElement>;

  /**
   * Props to apply to the underlay element, if any.
   */
  underlayProps: HTMLAttributes<HTMLElement>;
}

const visibleOverlays: RefObject<HTMLElement>[] = [];

/**
 * Provides the behavior for overlays such as dialogs, popovers, and menus.
 * Hides the overlay when the user interacts outside it, when the `Escape`
 * key is pressed, or optionally, on blur. Only the top-most overlay will
 * close at once.
 * @param props The props to be applied to the overlay.
 * @param ref The ref to the overlay.
 * @returns The aria props to be spread on the overlay.
 */
export function useOverlay(props: OverlayAriaProps, ref: RefObject<HTMLElement>): OverlayAria {
  const {
    onClose,
    shouldCloseOnBlur,
    isOpen,
    isDismissable = false,
    isKeyboardDismissDisabled = false,
    shouldCloseOnInteractOutside
  } = props;

  // Add the overlay ref to the stack of visible overlays on mount, and remove on unmount.
  useEffect(() => {
    if (isOpen) {
      visibleOverlays.push(ref);
    }

    return () => {
      const index = visibleOverlays.indexOf(ref);
      if (index >= 0) {
        visibleOverlays.splice(index, 1);
      }
    };
  }, [isOpen, ref]);

  /**
   * Only hide the overlay when it is the topmost visible overlay in the stack.
   */
  const onHide = () => {
    if (visibleOverlays[visibleOverlays.length - 1] === ref && onClose) {
      onClose();
    }
  };

  /**
   * Executed when the user starts to interact with the outside of the overlay.
   * @param event The event to handle.
   */
  const onInteractOutsideStart = (event: SyntheticEvent<Element>) => {
    if (!shouldCloseOnInteractOutside || shouldCloseOnInteractOutside(event.target as HTMLElement)) {
      if (visibleOverlays[visibleOverlays.length - 1] === ref) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
  };

  /**
   * Executed when the user interacts with the outside of the overlay.
   * @param event The event to handle.
   */
  const onInteractOutside = (event: SyntheticEvent<Element>) => {
    if (!shouldCloseOnInteractOutside || shouldCloseOnInteractOutside(event.target as HTMLElement)) {
      if (visibleOverlays[visibleOverlays.length - 1] === ref) {
        event.stopPropagation();
        event.preventDefault();
      }
      onHide();
    }
  };

  /**
   * Executed when the user presses any key. We specifically deal with the `Escape`
   * key in this method. All other clicks will be ignored.
   * @param event The event to handle.
   */
  const onKeyDown = (event: KeyboardEvent<Element>) => {
    if (event.key === 'Escape' && !isKeyboardDismissDisabled) {
      event.stopPropagation();
      event.preventDefault();
      onHide();
    }
  };

  // Handle clicking outside the overlay to close it.
  useInteractOutside({onInteractOutside: isDismissable ? onInteractOutside : null, onInteractOutsideStart}, ref);

  const {focusWithinProps} = useFocusWithin({
    isDisabled: !shouldCloseOnBlur,
    onBlurWithin: (e) => {
      if (!shouldCloseOnInteractOutside || shouldCloseOnInteractOutside(e.relatedTarget as HTMLElement)) {
        onClose();
      }
    }
  });

  const onPointerDownUnderlay = (e: PointerEvent<Element>) => {
    // fixes a firefox issue that starts text selection https://bugzilla.mozilla.org/show_bug.cgi?id=1675846
    if (e.target === e.currentTarget) {
      e.preventDefault();
    }
  };

  return {
    overlayProps: {
      onKeyDown,
      ...focusWithinProps
    },
    underlayProps: {
      onPointerDown: onPointerDownUnderlay
    }
  };
}
