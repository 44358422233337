import {CSSProperties, HTMLAttributes, useMemo, useState} from 'react';

import {useFocus} from '@inperium-corp/convergo-aria-focus';

import {VisuallyHiddenProps} from './VisuallyHidden';

export interface VisuallyHiddenAriaProps extends VisuallyHiddenProps {}

export interface VisuallyHiddenAria {
  /**
   * The visually hidden props to be spread on the target element.
   */
  visuallyHiddenProps: HTMLAttributes<HTMLElement>;
}

const styles: CSSProperties = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  margin: '0 -1px -1px 0',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  width: 1,
  whiteSpace: 'nowrap'
};

/**
 * Provides props for an element that hides its children visually
 * but keeps content visible to assistive technology.
 */
export function useVisuallyHidden(props: VisuallyHiddenProps = {}): VisuallyHiddenAria {
  const {style, isFocusable} = props;

  const [isFocused, setFocused] = useState(false);

  const {focusProps} = useFocus({
    isDisabled: !isFocusable,
    onFocusChange: setFocused
  });

  // If focused, don't hide the element.
  const combinedStyles = useMemo(() => {
    if (isFocused) {
      return style;
    }

    if (style) {
      return {...styles, ...style};
    }

    return styles;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  return {
    visuallyHiddenProps: {
      ...focusProps,
      style: combinedStyles
    }
  };
}
