const animationValues = {
  easeInOut: 'cubic-bezier(.45, 0, .40, 1)',
  easeIn: 'cubic-bezier(.50, 0, 1, 1)',
  easeOut: 'cubic-bezier(0, 0, 0.40, 1)',
  linear: 'cubic-bezier(0, 0, 1, 1)',
  duration: {
    0: '0ms',
    100: '130ms',
    200: '160ms',
    300: '190ms',
    400: '220ms',
    500: '250ms',
    600: '300ms',
    700: '350ms',
    800: '400ms',
    900: '450ms',
    1000: '500ms',
    2000: '1000ms',
    4000: '2000ms'
  }
};

const opacityValues = {
  4: 0.04,
  5: 0.05,
  6: 0.06,
  7: 0.07,
  8: 0.08,
  10: 0.1,
  15: 0.15,
  20: 0.2,
  25: 0.25,
  30: 0.3,
  40: 0.4,
  42: 0.42,
  50: 0.5,
  60: 0.6,
  80: 0.8,
  90: 0.9,
  100: 1
};

const staticValues = {
  colors: {
    white: 'rgb(255, 255, 255)',
    black: 'rgb(0, 0, 0)'
  },
  dimension: {
    size: {
      0: '0px',
      10: '1px',
      25: '2px',
      40: '3px',
      50: '4px',
      65: '5px',
      100: '8px',
      115: '9px',
      125: '10px',
      130: '11px',
      150: '12px',
      160: '13px',
      175: '14px',
      200: '16px',
      225: '18px',
      250: '20px',
      300: '24px',
      400: '32px',
      450: '36px',
      500: '40px',
      550: '44px',
      600: '48px',
      700: '56px',
      800: '64px',
      900: '72px',
      1000: '80px',
      1200: '96px',
      1700: '136px',
      2400: '192px',
      2600: '208px',
      3400: '272px',
      3600: '288px',
      4600: '368px',
      5000: '400px',
      6000: '480px'
    },
    fontSize: {
      50: '1.1rem',
      75: '1.2rem',
      100: '1.4rem',
      150: '1.5rem',
      200: '1.6rem',
      300: '1.8rem',
      400: '2.0rem',
      500: '2.2rem',
      600: '2.5rem',
      700: '2.8rem',
      800: '3.2rem',
      900: '3.6rem',
      1000: '4.0rem'
    },
    percent: {
      50: '50%',
      100: '100%'
    },
    breakpoint: {
      xsmall: '0',
      small: '630px',
      medium: '768px',
      large: '1024px',
      xlarge: '1230px'
    },
    grid: {
      columns: 12,
      fluidWidth: '100%',
      fixedMaxWidth: '1280px'
    }
  }
};

const fontValues = {
  family: {
    base: 'Inter, -apple-system, system-ui, sans-serif',
    serif: "'Source Serif Pro', Georgia, serif",
    code: "'Fira Code', monospace"
  },
  size: {
    small: '1.2rem',
    medium: '1.4rem',
    large: '1.6rem',
    xlarge: '2.1rem',
    '2xlarge': '2.7rem',
    '3xlarge': '3.6rem'
  },
  weight: {
    thin: 100,
    ultraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900
  },
  style: {
    regular: 'normal',
    italic: 'italic'
  },
  letterSpacing: {
    none: '0em',
    small: '0.0125em',
    han: '0.05em',
    medium: '0.06em'
  },
  lineHeight: {
    small: '1.8rem',
    medium: '2.2rem',
    large: '2.4rem',
    xlarge: '2.8rem',
    '2xlarge': '3.6rem',
    '3xlarge': '4.8rem'
  }
};

const borderSizeValues = {
  thin: staticValues.dimension.size[10],
  thick: staticValues.dimension.size[25],
  thicker: staticValues.dimension.size[50]
};

const focusRingValues = {
  gap: staticValues.dimension.size[25],
  size: staticValues.dimension.size[25]
};

export const globalValues = {
  animation: animationValues,
  opacity: opacityValues,
  static: staticValues,
  font: fontValues,
  borderSize: borderSizeValues,
  focusRing: focusRingValues
} as const;
