import {HTMLAttributes} from 'react';

import {AriaLabelingProps, DOMProps} from '@inperium-corp/convergo-types';

interface Options {
  /**
   * If labelling associated aria properties should be included in the filter.
   */
  labelable?: boolean;

  /**
   * A Set of other property names that should be included in the filter.
   */
  includedPropNames?: Set<string>;
}

const propRegex = /^(data-.*)$/;
const DOMPropNames = new Set(['id']);
const labelablePropNames = new Set(['aria-label', 'aria-labelledby', 'aria-describedby', 'aria-details']);

/**
 * Filters out all props that aren't valid DOM props or defined via override prop obj.
 * @param props The component props to be filtered.
 * @param options Props to override.
 */
export function filterDOMProps<T extends Record<string, any>>(
  props: (DOMProps & AriaLabelingProps) | T,
  options: Options = {}
): DOMProps & AriaLabelingProps {
  const {labelable, includedPropNames} = options;
  const filteredProps: HTMLAttributes<HTMLElement> & DOMProps = {};

  for (const prop in props) {
    if (
      Object.prototype.hasOwnProperty.call(props, prop) &&
      (DOMPropNames.has(prop) ||
        (labelable && labelablePropNames.has(prop)) ||
        includedPropNames?.has(prop) ||
        propRegex.test(prop))
    ) {
      // @ts-expect-error
      filteredProps[prop] = props[prop];
    }
  }

  return filteredProps;
}
