import {CookieOptions} from './CookieOptions';

const aliases: Record<string, string> = {
  path: 'path',
  domain: 'domain',
  expires: 'expires',
  maxAge: 'max-age',
  sameSite: 'samesite',
  secure: 'secure'
};

const transformations: Record<string, (value: any) => string> = {
  maxAge: (value: string | Date): string => (value instanceof Date ? value.toUTCString() : value)
};

/**
 * Sets a cookie to the browser of the user.
 * @param name cookie name
 * @param value cookie value
 * @param options cookie options https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
 */
export const setCookie = (name: string, value: string, options: CookieOptions = {}) => {
  options = {
    path: '/',
    // TODO Safari doesn't allow to set js cookies more than for 7 days
    maxAge: 86400 * 365,
    ...options
  };

  let cookie = `${encodeURIComponent(name)}=${value}`;

  Object.entries(options).forEach(([optionKey, optionValue]) => {
    if (optionValue) {
      cookie += `; ${aliases[optionKey]}`;
      if (optionValue !== true) {
        const transformValue = transformations[optionKey];
        cookie += `=${transformValue ? transformValue(optionValue) : optionValue}`;
      }
    }
  });

  document.cookie = cookie;
};
