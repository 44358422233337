function testUserAgent(re: RegExp) {
  if (typeof window === 'undefined' || window.navigator == null) {
    return false;
  }
  return (
    // @ts-expect-error the window.navigator types are not great because they
    // are not supported in all browsers yet. Thats why we provide the fallback.
    window.navigator['userAgentData']?.brands.some((brand: {brand: string; version: string}) => re.test(brand.brand)) ||
    re.test(window.navigator.userAgent)
  );
}

function testPlatform(re: RegExp) {
  return typeof window !== 'undefined' && window.navigator != null
    ? // @ts-expect-error the window.navigator types are not great because they
      // are not supported in all browsers yet. Thats why we provide the fallback.
      re.test((window.navigator['userAgentData'] || window.navigator).platform)
    : false;
}

/**
 * Checks if the platform of the user is a Mac.
 * @returns If the platform is a Mac.
 */
export function isMac() {
  return testPlatform(/^Mac/i);
}

/**
 * Checks if the platform of the user is an iPhone.
 * @returns If the platform is an iPhone.
 */
export function isIPhone() {
  return testPlatform(/^iPhone/i);
}

/**
 * Checks if the platform of the user is an iPad.
 * @returns If the platform is an iPad.
 */
export function isIPad() {
  return (
    testPlatform(/^iPad/i) ||
    // iPadOS 13 lies and says it's a Mac, but we can distinguish by detecting touch support.
    (isMac() && navigator.maxTouchPoints > 1)
  );
}

/**
 * Checks if the platform of the user is iOS.
 * @returns If the platform is iOS.
 */
export function isIOS() {
  return isIPhone() || isIPad();
}

/**
 * Checks if the platform of the user is an Apple device.
 * @returns If the platform is an Apple device.
 */
export function isAppleDevice() {
  return isMac() || isIOS();
}

/**
 * Checks if the platform of the user is using WebKit.
 * @returns If the platform is using WebKit.
 */
export function isWebKit() {
  return testUserAgent(/AppleWebKit/i) && !isChrome();
}

/**
 * Checks if the platform of the user is Chrome.
 * @returns If the platform is Chrome.
 */
export function isChrome() {
  return testUserAgent(/Chrome/i);
}

/**
 * Checks if the platform of the user is Android.
 * @returns If the platform is Android.
 */
export function isAndroid() {
  return testUserAgent(/Android/i);
}
