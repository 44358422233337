import {useCallback, useLayoutEffect as useReactLayoutEffect} from 'react';

import {useValueEffect} from '../useValueEffect';
import {useId} from './useId';

const useLayoutEffect = typeof window !== 'undefined' ? useReactLayoutEffect : () => {};

/**
 * Used to generate an id that is passed down through Slots. Since slots create
 * props for elements that may only optionally rendered, we dont know if the id
 * will actually be used. So we check after render,  if that id is rendered so we
 * know if we can use it in places such as labelledby.
 * @param depArray - When to recalculate if the id is in the DOM.
 */
export function useSlotId(depArray: ReadonlyArray<any> = []): string {
  const id = useId();
  const [resolvedId, setResolvedId] = useValueEffect(id);
  const updateId = useCallback(() => {
    setResolvedId(function* () {
      yield id;

      yield document.getElementById(id) ? id : null;
    });
  }, [id, setResolvedId]);

  useLayoutEffect(updateId, [id, updateId, ...depArray]);

  return resolvedId;
}
