import {useCallback, useMemo} from 'react';

type TimerType = 'refreshTokens' | 'loginTimingOut' | 'logout';

const timers = new Map<TimerType, number | null>();

export const useAuthorizationTimers = () => {
  const clearTimeout = useCallback((type: TimerType) => {
    const timeoutId = timers.get(type);
    if (timeoutId) {
      global.clearTimeout(timeoutId);
      timers.set(type, null);
    }
  }, []);

  const setTimeout = useCallback(
    (type: TimerType, handler: TimerHandler, timeout: number) => {
      clearTimeout(type);

      timers.set(type, global.setTimeout(handler, timeout));

      return () => clearTimeout(type);
    },
    [clearTimeout]
  );

  return useMemo(() => ({setTimeout}), [setTimeout]);
};
