import {forwardRef, RefObject} from 'react';

import {useOverlay} from '@inperium-corp/convergo-aria-overlays';
import {useFallbackRef} from '@inperium-corp/convergo-aria-utils';
import {useSlotProps} from '@inperium-corp/convergo-react-layout';
import {TrayProps} from '@inperium-corp/convergo-types';

import {Overlay} from './Overlay';
import {TrayWrapper} from './TrayWrapper';
import {Underlay} from './Underlay';

export const Tray = forwardRef((props: TrayProps, ref?: RefObject<HTMLDivElement>) => {
  props = useSlotProps('tray', props);
  const {children, onClose, isFixedHeight, isNonModal, style, className, ...otherProps} = props;

  ref = useFallbackRef<HTMLDivElement>(ref);

  const {overlayProps} = useOverlay({...props, isDismissable: true}, ref);

  return (
    <Overlay {...otherProps}>
      <Underlay />
      <TrayWrapper
        style={style}
        className={className}
        onClose={onClose}
        ref={ref}
        overlayProps={overlayProps}
        isFixedHeight={isFixedHeight}
        isNonModal={isNonModal}
      >
        {children}
      </TrayWrapper>
    </Overlay>
  );
});
