import {SyntheticEvent} from 'react';

import {BaseEvent} from '@inperium-corp/convergo-types';

/**
 * This function wraps a React event handler to make stopPropagation the default,
 * and support continuePropagation instead.
 * @param handler The event handler method.
 * @returns The updated event handler method.
 */
export function createEventHandler<T extends SyntheticEvent>(handler: (e: BaseEvent<T>) => void): (e: T) => void {
  if (!handler) {
    return;
  }

  let shouldStopPropagation = true;
  return (e: T) => {
    const event: BaseEvent<T> = {
      ...e,
      preventDefault() {
        e.preventDefault();
      },
      isDefaultPrevented() {
        return e.isDefaultPrevented();
      },
      stopPropagation() {
        console.error(
          'stopPropagation is now the default behavior for events in Convergo. You can use continuePropagation() to revert this behavior.'
        );
      },
      continuePropagation() {
        shouldStopPropagation = false;
      }
    };

    handler(event);

    if (shouldStopPropagation) {
      e.stopPropagation();
    }
  };
}
