import {forwardRef, HTMLAttributes, ReactNode, RefObject} from 'react';

import {useModal, useOverlay} from '@inperium-corp/convergo-aria-overlays';
import {mergeProps} from '@inperium-corp/convergo-aria-utils';
import {css, cx, Theme, useStyles} from '@inperium-corp/convergo-react-styles';
import {Direction} from '@inperium-corp/convergo-types';

import {PopoverArrow, PopoverArrowDirection} from './PopoverArrow';

interface PopoverWrapperProps extends HTMLAttributes<HTMLElement> {
  /**
   * The children of the popover wrapper.
   */
  children: ReactNode;

  /**
   * The placement of the popover wrapper.
   * @default 'bottom'
   */
  direction?: Direction;

  /**
   * Additional props passed to the popover arrow element.
   */
  arrowProps?: HTMLAttributes<SVGSVGElement>;

  /**
   * Whether the arrow should be hidden.
   */
  hideArrow?: boolean;

  /**
   * If the popover is opened.
   */
  isOpen?: boolean;

  /**
   * A method executed on click of the close button.
   */
  onClose?: () => void;

  /**
   * If the popover should close on blur.
   */
  shouldCloseOnBlur?: boolean;

  /**
   * Whether the popover is dismissable via the keyboard.
   */
  isKeyboardDismissDisabled?: boolean;

  /**
   * Whether the popover is not used as a modal.
   */
  isNonModal?: boolean;

  /**
   * Whether the popover is dismissable.
   */
  isDismissable?: boolean;
}

export const popoverWrapperStylesFactory = (theme: Theme, props: Pick<PopoverWrapperProps, 'hideArrow'>) => ({
  root: css`
    border-color: ${theme.colorScheme.color.gray[600]};
    border-radius: 9px;
    box-sizing: border-box;
    box-shadow: 0px 8px 20px rgba(15, 30, 84, 0.2);
    display: inline-flex;
    flex-direction: column;
    min-height: 32px;
    min-width: 32px;
    outline: none;
    pointer-events: none;
    position: absolute;
    // Do not change the opacity or visibility settings!!!
    // This is very important so that the focus can move
    // inside of the popover when the focus is being opened.
    opacity: 0.01111;
    visibility: visible !important;
    transition: transform 0.13s ease-in-out, opacity 0.13s ease-in-out, visibility 0ms linear 0.13s;
  `,
  directions: {
    top: css`
      margin-bottom: ${props?.hideArrow ? '0px' : '2px'};
      transform: translateY(-6px);
      > svg {
        left: 50%;
        margin-left: -12px;
        top: 100%;
      }
    `,
    bottom: css`
      margin-top: ${props?.hideArrow ? '0px' : '2px'};
      transform: translateY(6px);
      > svg {
        left: 50%;
        bottom: 100%;
        margin-left: -12px;
        transform: scaleY(-1);
      }
    `,
    left: css`
      margin-right: ${props?.hideArrow ? '0px' : '2px'};
      transform: translateX(-6px);
      > svg {
        left: 100%;
        margin-top: -12px;
        top: 50%;
      }
    `,
    right: css`
      margin-left: ${props?.hideArrow ? '0px' : '2px'};
      transform: translateX(6px);
      > svg {
        transform: scaleX(-1);
        top: 50%;
        right: 100%;
        margin-top: -12px;
      }
    `
  },
  isOpen: css`
    opacity: 0.9999;
    pointer-events: auto;
    transition-delay: 0ms;
    visibility: visible;
  `
});

/**
 * Arrow placement can be done pointing right or down because those paths start at 0, x or y. Because the
 * other two don't, they start at a fractional pixel value, it introduces rounding differences between browsers and
 * between display types (retina with subpixels vs not retina). By flipping them with CSS we can ensure that
 * the path always starts at 0 so that it perfectly overlaps the popover's border.
 * See bottom of file for more explanation.
 */
const arrowDirection: {[key in Direction]: PopoverArrowDirection} = {
  left: 'right',
  right: 'right',
  top: 'bottom',
  bottom: 'bottom'
};

export const PopoverWrapper = forwardRef((props: PopoverWrapperProps, ref: RefObject<HTMLDivElement>) => {
  const {
    children,
    direction = 'bottom',
    arrowProps,
    isOpen,
    hideArrow,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldCloseOnBlur,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isKeyboardDismissDisabled,
    isNonModal,
    className,
    isDismissable,
    ...otherProps
  } = props;

  const styles = useStyles(popoverWrapperStylesFactory, {hideArrow});

  const {overlayProps} = useOverlay({...props, isDismissable: isDismissable && isOpen}, ref);
  const {modalProps} = useModal({isDisabled: isNonModal});

  return (
    <div
      {...mergeProps(otherProps, overlayProps, modalProps)}
      ref={ref}
      className={cx(styles.root, styles.directions[direction], isOpen && styles.isOpen, className)}
      role='presentation'
      data-testid='popover'
    >
      {children}
      {hideArrow ? null : <PopoverArrow {...arrowProps} direction={arrowDirection[direction]} />}
    </div>
  );
});
