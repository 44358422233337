import {useCallback, useContext} from 'react';

import {UniqueIdentifierContext} from './UniqueIdentifierContext';

/**
 * Generates a unique ID that is safe to be used in an UniqueIdentifierContext.
 * @param defaultId The default id to be used if provided.
 * @returns A unique ID string.
 */
export function useIdGenerator() {
  const context = useContext(UniqueIdentifierContext);

  return useCallback(
    (defaultId?: string) => {
      if (defaultId) {
        return defaultId;
      }

      return `inperium-convergo-aria-${context.prefix}-${++context.current}`;
    },
    [context]
  );
}
