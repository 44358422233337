import {useCallback, useEffect, useMemo, useState} from 'react';

/**
 * Internal hook to get and set values from local storage as strings
 * @param key
 * @param defaultValue
 */
const useLocalStorageString = (key: string, defaultValue: string): [string, (value: string) => void] => {
  /** Using useState hook to trigger re-rendering on value change */
  const [storageValue, setStorageValue] = useState(() => {
    try {
      const item = typeof window !== 'undefined' ? window.localStorage.getItem(key) : undefined;
      return item || defaultValue;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error on useStorage value initialization', error);
      return defaultValue;
    }
  });

  const setValue = useCallback(
    (value: string) => {
      try {
        window.localStorage.setItem(key, value);

        /**
         * By default event is triggered only for other pages with the same domain.
         * This means that in case we're using the same storage value in several components on the same page they won't be in sync.
         * To fix that we dispatch storage event manually to get all values in sync.
         */
        window.dispatchEvent(
          new StorageEvent('storage', {
            key,
            newValue: value
          })
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Error on useStorage.setValue', error);
      }
    },
    [key]
  );

  /** This effect synchronizes storage values */
  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.key === key) {
        // @ts-expect-error that's fine because we are restricting type with setValue method.
        setStorageValue(e.newValue);
      }
    };

    window.addEventListener('storage', listener);

    return () => window.removeEventListener('storage', listener);
  }, [key]);

  return [storageValue, setValue];
};

/**
 * A hook to retrieve and store data in the local browser storage.
 * @param key The key of the item to be stored in the local storage.
 * @param defaultValue The initial or default value of the item.
 */
export const useLocalStorage = <TValue>(key: string, defaultValue: TValue): [TValue, (value: TValue) => void] => {
  const [stringValue, setStringValue] = useLocalStorageString(key, JSON.stringify(defaultValue));
  const value = useMemo(() => JSON.parse(stringValue), [stringValue]);
  const setValue = useCallback(
    (value: TValue) => {
      setStringValue(JSON.stringify(value));
    },
    [setStringValue]
  );
  return [value, setValue];
};
