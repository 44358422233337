import {HTMLAttributes, RefObject, useEffect, useMemo, useRef, useState} from 'react';

import {
  focusWithoutScrolling,
  isVirtualClick,
  mergeProps,
  useGlobalListeners
} from '@inperium-corp/convergo-aria-utils';
import {PointerType, PressEvents} from '@inperium-corp/convergo-types';

import {disableTextSelection, restoreTextSelection} from './textSelection';
import {usePressResponder} from './usePressResponder';

export interface PressAriaState<T extends HTMLElement> {
  /**
   * Whether the target is currently pressed.
   */
  isPressed: boolean;

  /**
   * Whether emulated mouse events are being ignored. Even if a browser supports touch,
   * the browser must still emulate mouse events so content that assumes mouse-only input
   * will work as is without direct modification.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Using_Touch_Events
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Supporting_both_TouchEvent_and_MouseEvent
   */
  ignoreEmulatedMouseEvents: boolean;

  /**
   * Whether a click after a press is being ignored.
   */
  ignoreClickAfterPress: boolean;

  /**
   * Whether a press start event was fired.
   */
  didFirePressStart: boolean;

  /**
   * The id of the active pointer.
   */
  activePointerId: any;

  /**
   * The current target element.
   */
  target: T | null;

  /**
   * If the pointer is over the current target.
   */
  isOverTarget: boolean;

  /**
   * The type of the active pointer.
   */
  pointerType: PointerType;

  /**
   * The current user selection.
   */
  userSelect?: string;
}

export interface EventBase {
  /**
   * The current target of the event.
   */
  currentTarget: EventTarget;

  /**
   * Whether the 'shift' key was pressed.
   */
  shiftKey: boolean;

  /**
   * Whether the 'ctrl' key was pressed.
   */
  ctrlKey: boolean;

  /**
   * Whether the 'meta' key was pressed.
   */
  metaKey: boolean;

  /**
   * Whether the 'alt' key was pressed.
   */
  altKey: boolean;
}

export interface PressAriaProps<T extends HTMLElement> extends PressEvents<T> {
  /**
   * Whether the target is in a controlled press state (event.g. An overlay it triggers is open).
   */
  isPressed?: boolean;

  /**
   * Whether the press events should be disabled.
   */
  isDisabled?: boolean;

  /**
   * Whether the target should not receive focus on press.
   */
  preventFocusOnPress?: boolean;

  /**
   * Whether press events should be canceled when the pointer leaves the target while pressed.
   * By default, this is `false`, which means if the pointer returns back over the target while
   * still pressed, onPressStart will be fired again. If set to `true`, the press is canceled
   * when the pointer leaves the target and onPressStart will not be fired if the pointer returns.
   */
  shouldCancelOnPointerExit?: boolean;

  /**
   * Whether text selection should be enabled on the pressable element.
   */
  allowTextSelectionOnPress?: boolean;
}

export interface PressAria<T extends HTMLElement> {
  /**
   * Props to spread on the target element.
   */
  pressProps: HTMLAttributes<T>;

  /**
   * The state of the hovered element.
   */
  pressState: {
    /**
     * Whether the target is currently pressed.
     */
    isPressed: boolean;
  };
}

/**
 * Handles press interactions across mouse, touch, keyboard, and screen readers.
 * It normalizes behavior across browsers and platforms, and handles many nuances
 * of dealing with pointer and keyboard events.
 * @param props The props to configure the hook.
 * @param ref The ref to the element to handle press events for.
 * @returns The press aria properties.
 */
export function usePress<T extends HTMLElement>(props: PressAriaProps<T>, ref?: RefObject<T>): PressAria<T> {
  const {
    isDisabled,
    onPress,
    onPressChange,
    onPressEnd,
    onPressStart,
    onPressUp,
    isPressed: isPressedProp,
    preventFocusOnPress,
    shouldCancelOnPointerExit,
    allowTextSelectionOnPress,
    ...domProps
  } = usePressResponder(props, ref);
  const propsRef = useRef<PressAriaProps<T>>(null);
  propsRef.current = {
    onPress,
    onPressChange,
    onPressStart,
    onPressEnd,
    onPressUp,
    isDisabled,
    shouldCancelOnPointerExit
  };

  const [isPressed, setPressed] = useState(false);

  const stateRef = useRef<PressAriaState<T>>({
    isPressed: false,
    ignoreEmulatedMouseEvents: false,
    ignoreClickAfterPress: false,
    didFirePressStart: false,
    activePointerId: null,
    target: null,
    isOverTarget: false,
    pointerType: null
  });

  const {addGlobalListener, removeAllGlobalListeners} = useGlobalListeners();

  const pressProps = useMemo(() => {
    const state = stateRef.current;

    const triggerPressStart = (originalEvent: EventBase, pointerType: PointerType) => {
      const {onPressStart, onPressChange, isDisabled} = propsRef.current;
      if (isDisabled || state.didFirePressStart) {
        return;
      }

      if (onPressStart) {
        onPressStart({
          type: 'pressstart',
          pointerType,
          target: originalEvent.currentTarget as T,
          shiftKey: originalEvent.shiftKey,
          metaKey: originalEvent.metaKey,
          ctrlKey: originalEvent.ctrlKey,
          altKey: originalEvent.altKey
        });
      }

      if (onPressChange) {
        onPressChange(true);
      }

      state.didFirePressStart = true;
      setPressed(true);
    };

    const triggerPressEnd = (originalEvent: EventBase, pointerType: PointerType, wasPressed = true) => {
      const {onPressEnd, onPressChange, onPress, isDisabled} = propsRef.current;

      if (!state.didFirePressStart) {
        return;
      }

      state.ignoreClickAfterPress = true;
      state.didFirePressStart = false;

      if (onPressEnd) {
        onPressEnd({
          type: 'pressend',
          pointerType,
          target: originalEvent.currentTarget as T,
          shiftKey: originalEvent.shiftKey,
          metaKey: originalEvent.metaKey,
          ctrlKey: originalEvent.ctrlKey,
          altKey: originalEvent.altKey
        });
      }

      if (onPressChange) {
        onPressChange(false);
      }

      setPressed(false);

      if (onPress && wasPressed && !isDisabled) {
        onPress({
          type: 'press',
          pointerType,
          target: originalEvent.currentTarget as T,
          shiftKey: originalEvent.shiftKey,
          metaKey: originalEvent.metaKey,
          ctrlKey: originalEvent.ctrlKey,
          altKey: originalEvent.altKey
        });
      }
    };

    const triggerPressUp = (originalEvent: EventBase, pointerType: PointerType) => {
      const {onPressUp, isDisabled} = propsRef.current;

      if (isDisabled) {
        return;
      }

      if (onPressUp) {
        onPressUp({
          type: 'pressup',
          pointerType,
          target: originalEvent.currentTarget as T,
          shiftKey: originalEvent.shiftKey,
          metaKey: originalEvent.metaKey,
          ctrlKey: originalEvent.ctrlKey,
          altKey: originalEvent.altKey
        });
      }
    };

    const cancel = (event: EventBase) => {
      if (state.isPressed) {
        if (state.isOverTarget) {
          triggerPressEnd(createEvent(state.target, event), state.pointerType, false);
        }
        state.isPressed = false;
        state.isOverTarget = false;
        state.activePointerId = null;
        state.pointerType = null;
        removeAllGlobalListeners();
        if (!allowTextSelectionOnPress) {
          restoreTextSelection(state.target);
        }
      }
    };

    const pressProps: HTMLAttributes<HTMLElement> = {
      onKeyDown(event) {
        if (isValidKeyboardEvent(event.nativeEvent) && event.currentTarget.contains(event.target as HTMLElement)) {
          if (shouldPreventDefaultKeyboard(event.target as Element)) {
            event.preventDefault();
          }
          event.stopPropagation();

          // If the event is repeating, it may have started on a different element
          // after which focus moved to the current element. Ignore these events and
          // only handle the first key down event.
          if (!state.isPressed && !event.repeat) {
            state.target = event.currentTarget as T;
            state.isPressed = true;
            triggerPressStart(event, 'keyboard');

            // Focus may move before the key up event, so register the event on the document
            // instead of the same element where the key down event occurred.
            addGlobalListener(document, 'keyup', onKeyUp, false);
          }
        }
      },
      onKeyUp(event) {
        if (
          isValidKeyboardEvent(event.nativeEvent) &&
          !event.repeat &&
          event.currentTarget.contains(event.target as HTMLElement)
        ) {
          triggerPressUp(createEvent(state.target, event), 'keyboard');
        }
      },
      onClick(event) {
        if (event && !event.currentTarget.contains(event.target as HTMLElement)) {
          return;
        }

        if (event && event.button === 0) {
          event.stopPropagation();
          if (isDisabled) {
            event.preventDefault();
          }

          // If triggered from a screen reader or by using element.click(),
          // trigger as if it were a keyboard click.
          if (
            !state.ignoreClickAfterPress &&
            !state.ignoreEmulatedMouseEvents &&
            (state.pointerType === 'virtual' || isVirtualClick(event.nativeEvent))
          ) {
            // Ensure the element receives focus (VoiceOver on iOS does not do this)
            if (!isDisabled && !preventFocusOnPress) {
              focusWithoutScrolling(event.currentTarget);
            }

            triggerPressStart(event, 'virtual');
            triggerPressUp(event, 'virtual');
            triggerPressEnd(event, 'virtual');
          }

          state.ignoreEmulatedMouseEvents = false;
          state.ignoreClickAfterPress = false;
        }
      }
    };

    const onKeyUp = (event: KeyboardEvent) => {
      if (state.isPressed && isValidKeyboardEvent(event)) {
        if (shouldPreventDefaultKeyboard(event.target as Element)) {
          event.preventDefault();
        }
        event.stopPropagation();

        state.isPressed = false;
        const target = event.target as HTMLElement;
        triggerPressEnd(createEvent(state.target, event), 'keyboard', state.target.contains(target));
        removeAllGlobalListeners();

        // If the target is a link, trigger the click method to open the URL,
        // but defer triggering pressEnd until onClick event handler.
        if (
          (state.target.contains(target) && isHTMLAnchorLink(state.target)) ||
          state.target.getAttribute('role') === 'link'
        ) {
          state.target.click();
        }
      }
    };

    if (typeof PointerEvent !== 'undefined') {
      pressProps.onPointerDown = (event) => {
        // Only handle left clicks, and ignore events that bubbled through portals.
        if (event.button !== 0 || !event.currentTarget.contains(event.target as HTMLElement)) {
          return;
        }

        // iOS safari fires pointer events from VoiceOver with incorrect coordinates/target.
        // Ignore and let the onClick handler take care of it instead.
        // https://bugs.webkit.org/show_bug.cgi?id=222627
        // https://bugs.webkit.org/show_bug.cgi?id=223202
        if (isVirtualPointerEvent(event.nativeEvent)) {
          state.pointerType = 'virtual';
          return;
        }

        // Due to browser inconsistencies, especially on mobile browsers, we prevent
        // default on pointer down and handle focusing the pressable element ourselves.
        if (shouldPreventDefault(event.currentTarget as HTMLElement)) {
          event.preventDefault();
        }

        state.pointerType = event.pointerType;

        event.stopPropagation();
        if (!state.isPressed) {
          state.isPressed = true;
          state.isOverTarget = true;
          state.activePointerId = event.pointerId;
          state.target = event.currentTarget as T;

          if (!isDisabled && !preventFocusOnPress) {
            focusWithoutScrolling(event.currentTarget);
          }

          if (!allowTextSelectionOnPress) {
            disableTextSelection(state.target);
          }

          triggerPressStart(event, state.pointerType);

          addGlobalListener(document, 'pointermove', onPointerMove, false);
          addGlobalListener(document, 'pointerup', onPointerUp, false);
          addGlobalListener(document, 'pointercancel', onPointerCancel, false);
        }
      };

      pressProps.onMouseDown = (event) => {
        if (!event.currentTarget.contains(event.target as HTMLElement)) {
          return;
        }

        if (event.button === 0) {
          // Chrome and Firefox on touch Windows devices require mouse down events
          // to be canceled in addition to pointer events, or an extra asynchronous
          // focus event will be fired.
          if (shouldPreventDefault(event.currentTarget as HTMLElement)) {
            event.preventDefault();
          }

          event.stopPropagation();
        }
      };

      pressProps.onPointerUp = (event) => {
        // iOS fires pointerup with zero width and height, so check the pointerType recorded during pointerdown.
        if (!event.currentTarget.contains(event.target as HTMLElement) || state.pointerType === 'virtual') {
          return;
        }

        // Only handle left clicks
        // Safari on iOS sometimes fires pointerup events, even
        // when the touch isn't over the target, so double check.
        if (event.button === 0 && isOverTarget(event, event.currentTarget)) {
          triggerPressUp(event, state.pointerType || event.pointerType);
        }
      };

      // Safari on iOS < 13.2 does not implement pointerenter/pointerleave events correctly.
      // Use pointer move events instead to implement our own hit testing.
      // See https://bugs.webkit.org/show_bug.cgi?id=199803
      const onPointerMove = (event: PointerEvent) => {
        if (event.pointerId !== state.activePointerId) {
          return;
        }

        if (isOverTarget(event, state.target)) {
          if (!state.isOverTarget) {
            state.isOverTarget = true;
            triggerPressStart(createEvent(state.target, event as EventBase), state.pointerType as PointerType);
          }
        } else if (state.isOverTarget) {
          state.isOverTarget = false;
          triggerPressEnd(createEvent(state.target, event as EventBase), state.pointerType as PointerType, false);
          if (propsRef.current.shouldCancelOnPointerExit) {
            cancel(event);
          }
        }
      };

      const onPointerUp = (event: PointerEvent) => {
        if (event.pointerId === state.activePointerId && state.isPressed && event.button === 0) {
          if (isOverTarget(event, state.target)) {
            triggerPressEnd(createEvent(state.target, event as EventBase), state.pointerType as PointerType);
          } else if (state.isOverTarget) {
            triggerPressEnd(createEvent(state.target, event as EventBase), state.pointerType as PointerType, false);
          }

          state.isPressed = false;
          state.isOverTarget = false;
          state.activePointerId = null;
          state.pointerType = null;
          removeAllGlobalListeners();
          if (!allowTextSelectionOnPress) {
            restoreTextSelection(state.target);
          }
        }
      };

      let onPointerCancel = (event: PointerEvent) => {
        cancel(event);
      };

      pressProps.onDragStart = (event) => {
        if (!event.currentTarget.contains(event.target as HTMLElement)) {
          return;
        }

        // Safari does not call onPointerCancel when a drag starts, whereas Chrome and Firefox do.
        cancel(event);
      };
    } else {
      pressProps.onMouseDown = (event) => {
        // Only handle left clicks
        if (event.button !== 0 || !event.currentTarget.contains(event.target as HTMLElement)) {
          return;
        }

        // Due to browser inconsistencies, especially on mobile browsers, we prevent
        // default on mouse down and handle focusing the pressable element ourselves.
        if (shouldPreventDefault(event.currentTarget as HTMLElement)) {
          event.preventDefault();
        }

        event.stopPropagation();
        if (state.ignoreEmulatedMouseEvents) {
          return;
        }

        state.isPressed = true;
        state.isOverTarget = true;
        state.target = event.currentTarget as T;
        state.pointerType = isVirtualClick(event.nativeEvent) ? 'virtual' : 'mouse';

        if (!isDisabled && !preventFocusOnPress) {
          focusWithoutScrolling(event.currentTarget);
        }

        triggerPressStart(event, state.pointerType);

        addGlobalListener(document, 'mouseup', onMouseUp, false);
      };

      pressProps.onMouseEnter = (event) => {
        if (!event.currentTarget.contains(event.target as HTMLElement)) {
          return;
        }

        event.stopPropagation();
        if (state.isPressed && !state.ignoreEmulatedMouseEvents) {
          state.isOverTarget = true;
          triggerPressStart(event, state.pointerType);
        }
      };

      pressProps.onMouseLeave = (event) => {
        if (!event.currentTarget.contains(event.target as HTMLElement)) {
          return;
        }

        event.stopPropagation();
        if (state.isPressed && !state.ignoreEmulatedMouseEvents) {
          state.isOverTarget = false;
          triggerPressEnd(event, state.pointerType, false);
          if (propsRef.current.shouldCancelOnPointerExit) {
            cancel(event);
          }
        }
      };

      pressProps.onMouseUp = (event) => {
        if (!event.currentTarget.contains(event.target as HTMLElement)) {
          return;
        }

        if (!state.ignoreEmulatedMouseEvents && event.button === 0) {
          triggerPressUp(event, state.pointerType);
        }
      };

      const onMouseUp = (event: MouseEvent) => {
        // Only handle left clicks.
        if (event.button !== 0) {
          return;
        }

        state.isPressed = false;
        removeAllGlobalListeners();

        if (state.ignoreEmulatedMouseEvents) {
          state.ignoreEmulatedMouseEvents = false;
          return;
        }

        if (isOverTarget(event, state.target)) {
          triggerPressEnd(createEvent(state.target, event), state.pointerType);
        } else if (state.isOverTarget) {
          triggerPressEnd(createEvent(state.target, event), state.pointerType, false);
        }

        state.isOverTarget = false;
      };

      pressProps.onTouchStart = (event) => {
        if (!event.currentTarget.contains(event.target as HTMLElement)) {
          return;
        }

        event.stopPropagation();
        const touch = getTouchFromEvent(event.nativeEvent);
        if (!touch) {
          return;
        }
        state.activePointerId = touch.identifier;
        state.ignoreEmulatedMouseEvents = true;
        state.isOverTarget = true;
        state.isPressed = true;
        state.target = event.currentTarget as T;
        state.pointerType = 'touch';

        // Due to browser inconsistencies, especially on mobile browsers, we prevent default
        // on the emulated mouse event and handle focusing the pressable element ourselves.
        if (!isDisabled && !preventFocusOnPress) {
          focusWithoutScrolling(event.currentTarget);
        }

        if (!allowTextSelectionOnPress) {
          disableTextSelection(state.target);
        }

        triggerPressStart(event, state.pointerType);

        addGlobalListener(window, 'scroll', onScroll, true);
      };

      pressProps.onTouchMove = (event) => {
        if (!event.currentTarget.contains(event.target as HTMLElement)) {
          return;
        }

        event.stopPropagation();
        if (!state.isPressed) {
          return;
        }

        const touch = getTouchById(event.nativeEvent, state.activePointerId);
        if (touch && isOverTarget(touch, event.currentTarget)) {
          if (!state.isOverTarget) {
            state.isOverTarget = true;
            triggerPressStart(event, state.pointerType);
          }
        } else if (state.isOverTarget) {
          state.isOverTarget = false;
          triggerPressEnd(event, state.pointerType, false);
          if (propsRef.current.shouldCancelOnPointerExit) {
            cancel(event);
          }
        }
      };

      pressProps.onTouchEnd = (event) => {
        if (!event.currentTarget.contains(event.target as HTMLElement)) {
          return;
        }

        event.stopPropagation();
        if (!state.isPressed) {
          return;
        }

        const touch = getTouchById(event.nativeEvent, state.activePointerId);
        if (touch && isOverTarget(touch, event.currentTarget)) {
          triggerPressUp(event, state.pointerType);
          triggerPressEnd(event, state.pointerType);
        } else if (state.isOverTarget) {
          triggerPressEnd(event, state.pointerType, false);
        }

        state.isPressed = false;
        state.activePointerId = null;
        state.isOverTarget = false;
        state.ignoreEmulatedMouseEvents = true;
        if (!allowTextSelectionOnPress) {
          restoreTextSelection(state.target);
        }
        removeAllGlobalListeners();
      };

      pressProps.onTouchCancel = (event) => {
        if (!event.currentTarget.contains(event.target as HTMLElement)) {
          return;
        }

        event.stopPropagation();
        if (state.isPressed) {
          cancel(event);
        }
      };

      const onScroll = (event: Event) => {
        if (state.isPressed && (event.target as HTMLElement).contains(state.target)) {
          cancel({
            currentTarget: state.target,
            shiftKey: false,
            ctrlKey: false,
            metaKey: false,
            altKey: false
          });
        }
      };

      pressProps.onDragStart = (event) => {
        if (!event.currentTarget.contains(event.target as HTMLElement)) {
          return;
        }

        cancel(event);
      };
    }

    return pressProps;
  }, [addGlobalListener, isDisabled, preventFocusOnPress, removeAllGlobalListeners, allowTextSelectionOnPress]);

  // Remove user-select: none in case component unmounts immediately after pressStart.
  useEffect(() => {
    return () => {
      if (!allowTextSelectionOnPress) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        restoreTextSelection(stateRef.current.target);
      }
    };
  }, [allowTextSelectionOnPress]);

  return {
    pressProps: mergeProps(domProps, pressProps),
    pressState: {
      isPressed: isPressedProp || isPressed
    }
  };
}

/**
 * Checks whether a specific element is a valid HTML anchor link.
 * @param target The element to check.
 * @returns If the element is a valid HTML anchor link.
 */
function isHTMLAnchorLink(element: HTMLElement): boolean {
  return element.tagName === 'A' && element.hasAttribute('href');
}

/**
 * Checks whether a specific event is a valid keyboard event.
 * @param event The event to check.
 * @returns If the event is a valid keyboard event.
 */
function isValidKeyboardEvent(event: KeyboardEvent): boolean {
  const {key, code, target} = event;
  const element = target as HTMLElement;
  const {tagName, isContentEditable} = element;
  const role = element.getAttribute('role');

  // Accessibility for keyboards. Space and Enter only.
  // "Spacebar" is for IE 11.
  return (
    (key === 'Enter' || key === ' ' || key === 'Spacebar' || code === 'Space') &&
    tagName !== 'INPUT' &&
    tagName !== 'TEXTAREA' &&
    isContentEditable !== true &&
    // A link with a valid href should be handled natively,
    // unless it also has role='button' and was triggered using Space.
    (!isHTMLAnchorLink(element) || (role === 'button' && key !== 'Enter')) &&
    // An element with role='link' should only trigger with Enter key.
    !(role === 'link' && key !== 'Enter')
  );
}

/**
 * Gets the touch object from an event.
 * @see Touch
 * @param event The event to get the touch object from.
 * @returns The touch object.
 */
function getTouchFromEvent(event: TouchEvent): Touch | null {
  const {targetTouches} = event;
  if (targetTouches.length > 0) {
    return targetTouches[0];
  }
  return null;
}

/**
 * Gets a touch object by the id of its pointer.
 * @see Touch
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Pointer_events
 * @param event The event to get the touch object from.
 * @param pointerId The id of the pointer.
 * @returns The touch object.
 */
function getTouchById(event: TouchEvent, pointerId: null | number): null | Touch {
  const {changedTouches} = event;
  for (let i = 0; i < changedTouches.length; i += 1) {
    const touch = changedTouches[i];
    if (touch.identifier === pointerId) {
      return touch;
    }
  }
  return null;
}

/**
 * Creates an event object for a target element.
 * @param target The target element.
 * @param event The event object.
 * @returns The event object.
 */
function createEvent(target: HTMLElement, event: EventBase): EventBase {
  return {
    currentTarget: target,
    shiftKey: event.shiftKey,
    ctrlKey: event.ctrlKey,
    metaKey: event.metaKey,
    altKey: event.altKey
  };
}

interface Rect {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

interface EventPoint {
  clientX: number;
  clientY: number;
  width?: number;
  height?: number;
  radiusX?: number;
  radiusY?: number;
}

function getPointClientRect(point: EventPoint): Rect {
  const offsetX = point.width / 2 || point.radiusX || 0;
  const offsetY = point.height / 2 || point.radiusY || 0;

  return {
    top: point.clientY - offsetY,
    right: point.clientX + offsetX,
    bottom: point.clientY + offsetY,
    left: point.clientX - offsetX
  };
}

function areRectanglesOverlapping(a: Rect, b: Rect) {
  // check if they cannot overlap on x axis
  if (a.left > b.right || b.left > a.right) {
    return false;
  }
  // check if they cannot overlap on y axis
  if (a.top > b.bottom || b.top > a.bottom) {
    return false;
  }
  return true;
}

/**
 * Checks if a pointer is over a target element.
 * @param point The point to check.
 * @param target The target to check.
 * @returns If the pointer is over the target.
 */
function isOverTarget(point: EventPoint, target: HTMLElement) {
  const rect = target.getBoundingClientRect();
  const pointRect = getPointClientRect(point);
  return areRectanglesOverlapping(rect, pointRect);
}

/**
 * Checks if we should trigger the prevent default method on the event.
 * @param target The target element.
 * @returns If we should call event.preventDefault().
 */
function shouldPreventDefault(target: HTMLElement) {
  // We cannot prevent default if the target is a draggable element.
  return !target.draggable;
}

/**
 * Checks whether prevent default should be triggered for a keyboard event.
 * @param target The target.
 * @returns Whether prevent default should be triggered.
 */
function shouldPreventDefaultKeyboard(target: Element) {
  return !(
    (target.tagName === 'INPUT' || target.tagName === 'BUTTON') &&
    (target as HTMLButtonElement | HTMLInputElement).type === 'submit'
  );
}

/**
 * Checks if an event is created by a virtual pointer.
 * @param event The event.
 * @returns If the event is created by a virtual pointer.
 */
function isVirtualPointerEvent(event: PointerEvent) {
  // If the pointer size is zero, then we assume it's from a screen reader.
  // Android TalkBack double tap will sometimes return a event with width and height of 1
  // and pointerType === 'mouse' so we need to check for a specific combination of event attributes.
  // Cannot use "event.pressure === 0" as the sole check due to Safari pointer events always returning pressure === 0
  // instead of .5, see https://bugs.webkit.org/show_bug.cgi?id=206216
  // Talkback double tap from Windows Firefox touch screen press
  return (
    (event.width === 0 && event.height === 0) ||
    (event.width === 1 &&
      event.height === 1 &&
      event.pressure === 0 &&
      event.detail === 0 &&
      event.pointerType === 'mouse')
  );
}
