import {useCallback, useMemo} from 'react';

import {useLocale} from '../useLocale';
import {MessageDictionary, TranslationFiles} from './MessageDictionary';
import {MessageFormatter} from './MessageFormatter';

export type FormatMessage = (key: string, variables?: {[key: string]: any}) => string;

const cache = new WeakMap();
function getCachedDictionary(strings: TranslationFiles) {
  let dictionary = cache.get(strings);
  if (!dictionary) {
    dictionary = new MessageDictionary(strings);
    cache.set(strings, dictionary);
  }

  return dictionary;
}

/**
 * Handles formatting ICU Message strings to create localized strings for the current locale.
 * Automatically updates when the locale changes, and handles caching of messages for performance.
 * @param strings - A mapping of languages to strings by key.
 */
export function useMessageFormatter(strings: TranslationFiles): FormatMessage {
  const {locale} = useLocale();
  const dictionary = useMemo(() => getCachedDictionary(strings), [strings]);
  const formatter = useMemo(() => new MessageFormatter(locale, dictionary), [locale, dictionary]);
  return useCallback((key, variables) => formatter.format(key, variables), [formatter]);
}
