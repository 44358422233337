import {ColorScheme, Scale, ThemeConfiguration} from './types';

// TODO: Come up with a better implementation for this. The components like
// input, table, calendar etc. are just temporarily placed here. This will change
// as we upgrade these components to support dark mode. Its just to make this PR
// in smaller steps and not to break anything.
export const utilsFactory = (colorScheme: ThemeConfiguration[ColorScheme], scale: ThemeConfiguration[Scale]) => ({
  font: {
    inherit: `
      font-family: inherit;
      font-size: inherit;
      font-style: inherit;
      font-weight: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      color: inherit;
    `
  },
  input: {
    default: `
      --border-color: ${colorScheme.color.gray[300]};
      position: relative;
      background: white;
      color: ${colorScheme.color.gray[600]};
      border-width: 0.1rem;
      border-style: solid;
      border-color: var(--border-color);
      box-shadow: none;
      outline: none;
      transform: translateX(0);
      transition: box-shadow 0.13s ease-out, margin 0.13s ease-out;
      margin: 0.2rem;
    `,
    focused: `
      --focus-ring-color: ${colorScheme.color.blue[400]};
      color: ${colorScheme.color.blue[800]};
      box-shadow: var(--focus-ring-color) 0 0 0 0.2rem;
    `,
    focusRing: `
      box-shadow: var(--focus-ring-color) 0 0 0 0.2rem;
    `,
    hovered: `
      --border-color: ${colorScheme.color.gray[500]};
      color: ${colorScheme.color.gray[800]};
    `,
    invalid: `
      --border-color: ${colorScheme.color.red[750]};
      --focus-ring-color: ${colorScheme.color.red[400]};
    `,
    disabled: `
      --border-color: ${colorScheme.color.gray[150]};
      color: ${colorScheme.color.gray[150]};
    `,
    readOnly: `
      cursor: default;
    `,
    helpMessage: {
      info: {
        color: colorScheme.color.gray[600]
      },
      error: {
        color: colorScheme.color.red[750]
      }
    },
    sizes: {
      small: {
        fontSize: scale.font.size.small,
        lineHeight: scale.font.lineHeight.small,
        padding: '0.5rem 1.2rem',
        borderRadius: '0.7rem'
      },
      medium: {
        fontSize: scale.font.size.medium,
        lineHeight: scale.font.lineHeight.medium,
        padding: '0.7rem 1.5rem',
        borderRadius: '0.9rem'
      },
      large: {
        fontSize: scale.font.size.large,
        lineHeight: scale.font.lineHeight.large,
        padding: '1.1rem 1.8rem',
        borderRadius: '1.2rem'
      }
    }
  },
  calendar: {
    // Calendar
    borderColorKeyFocus: colorScheme.color.blue[400],
    borderSizeKeyFocus: '2px',
    // Title font
    titleFontColor: colorScheme.color.blue[750],
    // Day background
    dayBackground: 'transparent',
    dayBackgroundDisabled: 'transparent',
    dayBackgroundHovered: colorScheme.color.gray[150],
    dayBackgroundPressed: colorScheme.color.gray[200],
    dayBackgroundSelected: colorScheme.color.blue[100],
    dayBackgroundSelectedCap: colorScheme.color.blue[400],
    dayBackgroundSelectedCapHovered: colorScheme.color.blue[500],
    dayBackgroundSelectedCapInvalid: colorScheme.color.red[500],
    dayBackgroundSelectedCapInvalidHovered: colorScheme.color.red[600],
    dayBackgroundSelectedCapInvalidPressed: colorScheme.color.red[700],
    dayBackgroundSelectedCapPressed: colorScheme.color.blue[600],
    dayBackgroundSelectedHovered: colorScheme.color.blue[200],
    dayBackgroundSelectedInvalid: colorScheme.color.red[300],
    dayBackgroundToday: colorScheme.color.gray[200],
    dayBackgroundTodayHovered: colorScheme.color.gray[300],
    dayBackgroundTodayPressed: colorScheme.color.gray[400],
    // Day border
    dayBorderColor: 'transparent',
    dayBorderColorDisabled: 'transparent',
    dayBorderColorHovered: colorScheme.color.gray[150],
    dayBorderColorPressed: colorScheme.color.gray[200],
    dayBorderColorSelected: colorScheme.color.blue[100],
    dayBorderColorToday: colorScheme.color.gray[100],
    // Day color
    dayColor: colorScheme.color.blue[750],
    dayColorDisabled: colorScheme.color.gray[500],
    dayColorHovered: colorScheme.color.blue[750],
    dayColorPressed: colorScheme.color.blue[800],
    dayColorSelected: colorScheme.color.blue[800],
    dayColorSelectedCap: colorScheme.color.white,
    dayColorSelectedCapHovered: colorScheme.color.white,
    dayColorSelectedHovered: colorScheme.color.blue[900],
    dayColorToday: colorScheme.color.blue[750]
  },
  listView: {
    background: colorScheme.color.white,
    backgroundDown: colorScheme.color.gray[100],
    backgroundHover: colorScheme.color.blue[100],
    backgroundSelected: colorScheme.color.blue[200],
    backgroundSelectedHover: colorScheme.color.blue[200],
    backgroundSelectedKeyFocus: colorScheme.color.blue[200],
    backgroundKeyFocus: colorScheme.color.blue[200],
    badgeBackground: colorScheme.color.blue[200],
    borderColor: colorScheme.color.gray[200],
    borderColorKeyFocus: colorScheme.color.blue[200],
    borderRadius: '4px',
    borderWidth: '1px',
    color: colorScheme.color.blue[750],
    colorDisabled: colorScheme.color.gray[150],
    focusRingColor: colorScheme.color.blue[400],
    dropZoneBorderColor: colorScheme.color.blue[400],
    focusIndicatorColor: colorScheme.color.blue[600],
    focusIndicatorWidth: '2px',
    fontSize: '1.4rem',
    itemTitleColor: colorScheme.color.blue[750],
    itemTitleFontSize: '1.6rem',
    itemDescriptionColor: colorScheme.color.blue[700],
    itemDescriptionFontSize: '1.2rem',
    padding: '8px 16px',
    fontWeight: '400',
    lineHeight: '2.2rem',
    verticalAlignment: 'middle',
    compact: {
      itemPaddingTop: '4px',
      itemMinHeight: '32px'
    },
    regular: {
      itemPaddingTop: '6px',
      itemMinHeight: '40px'
    },
    spacious: {
      itemPaddingTop: '8px',
      itemMinHeight: '48px'
    }
  },
  table: {
    animationDuration: '200ms',
    background: colorScheme.color.white,
    borderColor: colorScheme.color.gray[200],
    borderColorFocus: colorScheme.color.blue[400],
    borderRadius: '4px',
    borderRadiusKeyFocus: '0',
    borderWidth: '1px',
    cellBorderColor: colorScheme.color.gray[200],
    highlightSelectedColor: colorScheme.color.blue[400],
    cellBorderColorKeyFocus: colorScheme.color.blue[400],
    cellCheckboxPaddingRight: '8px',
    cellCheckboxVerticalAlignment: 'middle',
    cellCompactPaddingY: '6px',
    cellFontSize: '1.4rem',
    cellFontColor: colorScheme.color.blue[750],
    cellBackground: colorScheme.color.white,
    cellFontWeight: '400',
    cellLineHeight: '2.2rem',
    cellPaddingX: '16px',
    cellRegularPaddingY: '10px',
    cellSpaciousPaddingY: '14px',
    cellVerticalAlignment: 'middle',
    dividerBorderColor: colorScheme.color.gray[200],
    dividerBorderColorActive: colorScheme.color.blue[500],
    dividerBorderSize: '1px',
    headerBackground: colorScheme.color.gray[100],
    headerBorderRadius: '0',
    headerFontColor: colorScheme.color.blue[750],
    headerFontColorDown: colorScheme.color.blue[800],
    headerFontColorHover: colorScheme.color.blue[850],
    headerFontColorKeyFocus: colorScheme.color.blue[850],
    headerFontSize: '1.4rem',
    headerFontWeight: '700',
    headerLetterSpacing: '0.05rem',
    headerLineHeight: '2.2rem',
    headerMinHeight: '34px',
    headerPaddingX: '16px',
    headerPaddingY: '8px',
    headerSortIconColor: colorScheme.color.blue[750],
    headerSortIconColorHover: colorScheme.color.blue[800],
    headerSortIconColorDown: colorScheme.color.gray[500],
    headerSortIconColorKeyFocus: colorScheme.color.blue[400],
    headerSortIconGap: '4px',
    headerSortIconWidth: '12px',
    highlightSelected: colorScheme.color.blue[400],
    rowBorderRadius: '0',
    rowStickyFocusIndicatorWidth: '2px',
    rowBackgroundSelected: colorScheme.color.blue[200],
    rowBackgroundSelectedKeyFocus: colorScheme.color.blue[200],
    rowBackgroundSelectedHover: colorScheme.color.blue[200],
    rowBackgroundHover: colorScheme.color.gray[50],
    rowBackgroundDown: colorScheme.color.blue[100],
    optionFocusIndicatorColor: colorScheme.color.blue[400],
    focusIndicatorColor: colorScheme.color.blue[200]
  },
  dialog: {
    entryAnimation: {
      duration: '250ms',
      delay: '160ms'
    },
    exitAnimation: {
      duration: '0ms',
      delay: '0ms'
    },
    backgroundEntryAnimation: {
      duration: '300ms',
      delay: '0ms'
    },
    backgroundExitAnimation: {
      duration: '190ms',
      delay: '160ms'
    }
  },
  dropIndicator: {
    circleSize: '2px',
    borderSize: '1px',
    borderColor: colorScheme.color.blue[150],
    circleBorderColor: colorScheme.color.blue[150]
  }
});
