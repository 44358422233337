import {ReactNode} from 'react';

import {ThemeProvider} from '@emotion/react';

import {Theme} from './vars/types';

export interface InperiumThemeProviderProps {
  /** The theme to apply to the child context. */
  theme: Theme;
  /** The children to render as part of the theme provider. */
  children?: ReactNode;
}

/**
 * The global Inperium theme provider component. Wrap your application in this component
 * to make sure you can access all theme related functionality of our component library.
 */
export function InperiumThemeProvider({children, ...props}: InperiumThemeProviderProps) {
  return <ThemeProvider {...props}>{children}</ThemeProvider>;
}
