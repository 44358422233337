import {forwardRef, RefObject, useCallback, useState} from 'react';
import ReactDOM from 'react-dom';

import {ConvergoProvider} from '@inperium-corp/convergo-react-provider';
import {OverlayProps} from '@inperium-corp/convergo-types';

import {OpenTransition} from './OpenTransition';

export const Overlay = forwardRef((props: OverlayProps, ref: RefObject<HTMLDivElement>) => {
  const {children, isOpen, container, onEnter, onEntering, onEntered, onExit, onExiting, onExited} = props;
  const [isExited, setExited] = useState(!isOpen);

  const handleEntered = useCallback(() => {
    setExited(false);
    if (onEntered) {
      onEntered();
    }
  }, [onEntered]);

  const handleExited = useCallback(() => {
    setExited(true);
    if (onExited) {
      onExited();
    }
  }, [onExited]);

  // Don't un-render the overlay while it's transitioning out.
  const mountOverlay = isOpen || !isExited;
  if (!mountOverlay) {
    // Don't bother showing anything if we don't have to.
    return null;
  }

  const contents = (
    <ConvergoProvider ref={ref} style={{background: 'transparent', isolation: 'isolate'}}>
      <OpenTransition
        in={isOpen}
        appear
        onExit={onExit}
        onExiting={onExiting}
        onExited={handleExited}
        onEnter={onEnter}
        onEntering={onEntering}
        onEntered={handleEntered}>
        {children}
      </OpenTransition>
    </ConvergoProvider>
  );

  return ReactDOM.createPortal(contents, container || document.body);
});
