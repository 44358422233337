import {useLocale} from './useLocale';

const cache = new Map<string, Intl.Collator>();

/**
 * Provides localized string collation for the current locale. Automatically updates
 * when the locale changes, and handles caching of the collator for performance.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator
 * @param options Collator options.
 * @returns The collator.
 */
export function useCollator(options?: Intl.CollatorOptions): Intl.Collator {
  const {locale} = useLocale();

  const cacheKey =
    locale +
    (options
      ? Object.entries(options)
          .sort((a, b) => (a[0] < b[0] ? -1 : 1))
          .join()
      : '');
  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }

  const formatter = new Intl.Collator(locale, options);
  cache.set(cacheKey, formatter);

  return formatter;
}
