// Portions of the code in this file are based on code from ICU.

import {CalendarDate} from '../CalendarDate';
import {AnyCalendarDate} from '../types';
import {fromExtendedYear, getExtendedYear, GregorianCalendar} from './GregorianCalendar';

const BUDDHIST_ERA_START = -543;

/**
 * The Buddhist calendar is the same as the Gregorian calendar, but counts years
 * starting from the birth of Buddha in 543 BC (Gregorian). It supports only one
 * era, identified as 'BE'.
 */
export class BuddhistCalendar extends GregorianCalendar {
  identifier = 'buddhist';

  fromJulianDay(jd: number): CalendarDate {
    const gregorianDate = super.fromJulianDay(jd);
    const year = getExtendedYear(gregorianDate.era, gregorianDate.year);
    return new CalendarDate(this, year - BUDDHIST_ERA_START, gregorianDate.month, gregorianDate.day);
  }

  toJulianDay(date: AnyCalendarDate) {
    return super.toJulianDay(toGregorian(date));
  }

  getEras() {
    return ['BE'];
  }

  getDaysInMonth(date: AnyCalendarDate): number {
    return super.getDaysInMonth(toGregorian(date));
  }

  balanceDate() {}
}

function toGregorian(date: AnyCalendarDate) {
  const [era, year] = fromExtendedYear(date.year + BUDDHIST_ERA_START);
  return new CalendarDate(era, year, date.month, date.day);
}
