import {createContext} from 'react';

export interface ModalContextValue {
  /**
   * The parent ModalContext that contains this ModalContext. This allows us
   * to support stacked modals on top of each other. Each ModalProvider tracks
   * how many modals are open in its subtree.
   */
  parent: ModalContextValue | null;

  /**
   * The number of modals that are currently open.
   */
  modalCount: number;

  /**
   * A method to add a new modal to the context.
   */
  addModal: () => void;

  /**
   * A method to remove an opened modal from the context.
   */
  removeModal: () => void;
}

/**
 * A context provider for implementing accessible modals.
 * @see ModalProvider for more details.
 */
export const ModalContext = createContext<ModalContextValue>(null);
