import {Children, cloneElement, ReactElement} from 'react';

import {mergeProps} from '@inperium-corp/convergo-aria-utils';
import {cx} from '@inperium-corp/convergo-react-styles';

import {useFocusRing} from './useFocusRing';

export interface FocusRingProps {
  /**
   * The child element to which the Focus Ring CSS classes shall be applied to.
   */
  children: ReactElement;

  /**
   * The class name to apply when the element is focused.
   */
  focusClassName?: string;

  /**
   * The class name to apply when the element has keyboard focus.
   */
  focusRingClassName?: string;

  /**
   * Whether to show the focus ring when something
   * inside the container element has focus (true), or
   * only if the container itself has focus (false).
   * @default false
   */
  within?: boolean;

  /**
   * Whether the element is a text input.
   */
  isTextInput?: boolean;

  /**
   * Whether the element will be auto focused.
   */
  autoFocus?: boolean;
}

/**
 * A utility component that applies a CSS class when an element has keyboard focus.
 * Focus rings are visible only when the user is interacting with a keyboard,
 * not with a mouse, touch, or other input methods.
 */
export function FocusRing(props: FocusRingProps) {
  const {children, focusClassName, focusRingClassName} = props;
  const {focusProps, focusState} = useFocusRing(props);
  const child = Children.only(children);

  const classes = [];
  if (child.props.className) {classes.push(child.props.className);}
  if (focusClassName && focusState.isFocused) {classes.push(focusClassName);}
  if (focusRingClassName && focusState.isFocusVisible) {classes.push(focusRingClassName);}
  const classNames = classes.length > 0 ? cx(classes) : undefined;

  return cloneElement(
    child,
    mergeProps(child.props, {
      ...focusProps,
      className: classNames
    })
  );
}
