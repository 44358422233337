import {FC} from 'react';
import {IInperiumAuthConfig, InperiumAuthProvider} from '@inperium-corp/auth';

export const authConfig: IInperiumAuthConfig = {
  afterLoginRedirectUri: `${process.env.REACT_APP_ACCOUNT_URL}/start`,
  afterLogoutRedirectUri: `${process.env.REACT_APP_ACCOUNT_URL}/login`,
  loginUri: `${process.env.REACT_APP_ACCOUNT_URL}/login`,
  loginTimingOutSeconds: 240 * 60,
  logoutCountdownSeconds: 2 * 60,
  hubUrl: process.env.REACT_APP_AUTH_URL,
  baseApiUrl: process.env.REACT_APP_API_HUB_URL
};

export const App: FC = () => {
  return <InperiumAuthProvider {...authConfig} />;
};
