import {HTMLAttributes, useCallback, useRef, useState} from 'react';

import {useFocus} from './useFocus';
import {isFocusVisible, useFocusVisibleListener} from './useFocusVisible';
import {useFocusWithin} from './useFocusWithin';

export interface FocusRingAriaProps {
  /**
   * Whether to show the focus ring when something
   * inside the container element has focus (true), or
   * only if the container itself has focus (false).
   * @default 'false'
   */
  within?: boolean;

  /**
   * Whether the element is a text input.
   */
  isTextInput?: boolean;

  /**
   * Whether the element will be auto focused.
   */
  autoFocus?: boolean;
}

export interface FocusRingAriaState {
  /**
   * Whether the element is currently focused.
   */
  isFocused: boolean;

  /**
   * Whether keyboard focus should be visible.
   */
  isFocusVisible: boolean;
}

export interface FocusRingAria {
  /**
   * Props to apply to the container element with the focus ring.
   */
  focusProps: HTMLAttributes<HTMLElement>;

  /**
   * Props to apply to the container element with the focus ring.
   */
  focusState: FocusRingAriaState;
}

/**
 * Determines whether a focus ring should be shown to indicate keyboard focus.
 * Focus rings are visible only when the user is interacting with a keyboard,
 * not with a mouse, touch, or other input methods.
 * @param props The props to configure the hook.
 * @returns The focus ring props.
 */
export function useFocusRing(props: FocusRingAriaProps = {}): FocusRingAria {
  const {autoFocus = false, isTextInput, within} = props;
  const state = useRef({
    isFocused: false,
    isFocusVisible: autoFocus || isFocusVisible()
  });
  const [isFocused, setFocused] = useState(false);
  const [isFocusVisibleState, setFocusVisible] = useState(
    () => state.current.isFocused && state.current.isFocusVisible
  );

  const updateState = useCallback(() => setFocusVisible(state.current.isFocused && state.current.isFocusVisible), []);

  const onFocusChange = useCallback(
    (isFocused: boolean) => {
      state.current.isFocused = isFocused;
      setFocused(isFocused);
      updateState();
    },
    [updateState]
  );

  useFocusVisibleListener(
    (isFocusVisible) => {
      state.current.isFocusVisible = isFocusVisible;
      updateState();
    },
    [],
    {isTextInput}
  );

  const {focusProps} = useFocus({
    isDisabled: within,
    onFocusChange
  });

  const {focusWithinProps} = useFocusWithin({
    isDisabled: !within,
    onFocusWithinChange: onFocusChange
  });

  return {
    focusState: {
      isFocused,
      isFocusVisible: state.current.isFocused && isFocusVisibleState
    },
    focusProps: within ? focusWithinProps : focusProps
  };
}
