import {useSlotProps} from '@inperium-corp/convergo-react-layout';
import {css, cx, Theme, useStyles} from '@inperium-corp/convergo-react-styles';
import {SlotProps, StyleProps} from '@inperium-corp/convergo-types';

interface UnderlayProps extends SlotProps, StyleProps {
  /**
   * Whether the underlay is open.
   */
  isOpen?: boolean;
}

export const underlayStylesFactory = (theme: Theme) => ({
  root: css`
    background: hsla(215, 23%, 93%, 0.5);
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    // Exit animation
    transition: opacity ${theme.utils.dialog.backgroundExitAnimation.duration} cubic-bezier(0.5, 0, 1, 1)
        ${theme.utils.dialog.backgroundExitAnimation.delay},
      visibility 0ms linear
        calc(
          ${theme.utils.dialog.backgroundExitAnimation.delay} + ${theme.utils.dialog.backgroundExitAnimation.duration}
        );
  `,
  isOpen: css`
    opacity: 0.9999;
    pointer-events: auto;
    visibility: visible;
    // Entry animation
    transition: opacity ${theme.utils.dialog.backgroundEntryAnimation.duration} cubic-bezier(0, 0, 0.4, 1)
      ${theme.utils.dialog.backgroundEntryAnimation.delay};
  `
});

export function Underlay(props: UnderlayProps) {
  const styles = useStyles(underlayStylesFactory);

  props = useSlotProps('underlay', props);
  const {isOpen, className} = props;

  return <div className={cx(styles.root, isOpen && styles.isOpen, className)} />;
}
