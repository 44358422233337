import {useControlledState} from '@inperium-corp/convergo-state-utils';
import {OpenStateProps, OverlayTriggerState} from '@inperium-corp/convergo-types';

/**
 * Manages open state. Tracks whether the state is open, and provides
 * methods to open, close and toggle this state.
 */
export function useOpenState(props: OpenStateProps = {}): OverlayTriggerState {
  const [isOpen, setOpen] = useControlledState(props.isOpen, props.default || false, props.onOpenChange);

  return {
    isOpen,
    setOpen,
    open() {
      setOpen(true);
    },
    close() {
      setOpen(false);
    },
    toggle() {
      setOpen(!isOpen);
    }
  };
}
