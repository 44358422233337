import {forwardRef, HTMLAttributes, ReactNode, RefObject} from 'react';

import {useModal, usePreventScroll} from '@inperium-corp/convergo-aria-overlays';
import {mergeProps, useViewportSize} from '@inperium-corp/convergo-aria-utils';
import {css, cx, Theme, useStyles} from '@inperium-corp/convergo-react-styles';

export interface TrayWrapperProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * The children of the tray wrapper.
   */
  children: ReactNode;

  /**
   * A method executed on click of the close button.
   */
  onClose?: () => void;

  /**
   * If the tray should close on blur.
   */
  shouldCloseOnBlur?: boolean;

  /**
   * Whether the tray is dismissable via the keyboard.
   */
  isKeyboardDismissDisabled?: boolean;

  /**
   * Whether the tray has a fixed height.
   */
  isFixedHeight?: boolean;

  /**
   * Whether the tray is not a modal.
   */
  isNonModal?: boolean;

  /**
   * Whether the Tray is currently opened.
   */
  isOpen?: boolean;

  /**
   * Props applied to the overlay of the tray.
   */
  overlayProps: HTMLAttributes<HTMLElement>;
}

export const trayWrapperStylesFactory = (theme: Theme) => ({
  root: css`
    display: flex;
    inset-inline-start: 0;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    position: fixed;
    width: 100%;
    z-index: 2;
    height: var(--convergo-visual-viewport-height);
  `,
  tray: css`
    background: ${theme.global.static.colors.white};
    border-radius: 9px 9px 0 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    max-height: calc(var(--convergo-visual-viewport-height) - 20px);
    max-width: 375px;
    outline: none;
    overflow: hidden;
    padding-bottom: 0px;
    position: absolute;
    width: 100%;
    // Do not change the opacity or visibility settings!!!
    // This is very important so that the focus can move
    // inside of the tray when the focus is being opened.
    opacity: 0.01111;
    visibility: visible !important;
    pointer-events: none;
    transform: translateY(100%);
    // Exit animation
    transition: opacity ${theme.utils.dialog.exitAnimation.duration} cubic-bezier(0.5, 0, 1, 1)
        ${theme.utils.dialog.exitAnimation.delay},
      transform ${theme.utils.dialog.exitAnimation.duration} cubic-bezier(0.5, 0, 1, 1)
        ${theme.utils.dialog.exitAnimation.delay};
  `,
  isOpen: css`
    opacity: 0.9999;
    pointer-events: auto;
    visibility: visible;
    transform: translateY(0);
    // Entry animation
    transition: transform ${theme.utils.dialog.entryAnimation.duration} cubic-bezier(0, 0, 0.4, 1)
        ${theme.utils.dialog.entryAnimation.delay},
      opacity ${theme.utils.dialog.entryAnimation.duration} cubic-bezier(0, 0, 0.4, 1)
        ${theme.utils.dialog.entryAnimation.delay};
  `,
  isFixedHeight: css`
    top: 65px;
  `
});

export const TrayWrapper = forwardRef((props: TrayWrapperProps, ref: RefObject<HTMLDivElement>) => {
  const styles = useStyles(trayWrapperStylesFactory);

  const {children, isOpen, isFixedHeight, isNonModal, overlayProps, className, ...otherProps} = props;

  usePreventScroll();

  const {modalProps} = useModal({
    isDisabled: isNonModal
  });

  // We need to measure the window's height in JS rather than using percentages in CSS
  // so that contents (e.g. menu) can inherit the max-height properly. Using percentages
  // does not work properly because there is nothing to base the percentage on.
  // We cannot use vh units because mobile browsers adjust the window height dynamically
  // when the address bar/bottom toolbars show and hide on scroll and vh units are fixed.
  // Also, the visual viewport is smaller than the layout viewport when the virtual keyboard
  // is up, so use the VisualViewport API to ensure the tray is displayed above the keyboard.
  const viewport = useViewportSize();

  const style: any = {
    '--convergo-visual-viewport-height': viewport.height + 'px'
  };

  const domProps = mergeProps(otherProps, overlayProps);

  /* On mobile browsers, vh units are fixed based on the maximum height of the screen.
   * However, when you scroll, the toolbar and address bar shrink, making the viewport resize.
   * The visual viewport also shrinks when the keyboard is displayed. We use the VisualViewport
   * API to set the height of the Tray to ensure the height is correct.
   */
  return (
    <div className={styles.root} style={style}>
      <div
        {...domProps}
        {...modalProps}
        className={cx(styles.tray, isOpen && styles.isOpen, isFixedHeight && styles.isFixedHeight, className)}
        ref={ref}
        data-testid='tray'
      >
        {children}
      </div>
    </div>
  );
});
