export const features = [
  // General features
  'TEAM_MANAGEMENT',
  // Inperium Sell features
  'SELL',
  'SELL_VIEW_MANAGEMENT',
  'SELL_TEMPLATE_MANAGEMENT',
  'SELL_SNIPPET_MANAGEMENT',
  'SELL_SALES_GOALS',
  'SELL_REPORT_CUSTOMIZATION',
  'SELL_REPORT_BUILT_IN',
  'SELL_QUOTE_MANAGEMENT',
  'SELL_PROPERTY_MANAGEMENT',
  'SELL_PRODUCT_MANAGEMENT',
  'SELL_PIPELINE_MANAGEMENT',
  'SELL_PIPELINE_CUSTOM_STAGES',
  'SELL_MESSAGE_CENTER_EMAIL_SYNC',
  'SELL_INVOICE_PAYMENTS',
  'SELL_INVOICE_MANAGEMENT',
  'SELL_INTERNATIONAL_CALL_PACKAGE',
  'SELL_INTEGRATION_MARKETPLACE',
  'SELL_GLOBAL_SEARCH',
  'SELL_FORM_MANAGEMENT',
  'SELL_EMAIL_SIGNATURE_MANAGEMENT',
  'SELL_DEAL_MANAGEMENT',
  'SELL_DEAL_IMPORT',
  'SELL_DEAL_EXPORT',
  'SELL_DASHBOARD_CUSTOMIZATION',
  'SELL_DASHBOARD_BUILT_IN',
  'SELL_CONTACT_MERGING',
  'SELL_CONTACT_MANAGEMENT',
  'SELL_CONTACT_IMPORT',
  'SELL_CONTACT_EXPORT',
  'SELL_CONTACT_DATA_ENRICHMENT',
  'SELL_COMPANY_MERGING',
  'SELL_COMPANY_MANAGEMENT',
  'SELL_COMPANY_IMPORT',
  'SELL_COMPANY_EXPORT',
  'SELL_COMPANY_DATA_ENRICHMENT',
  'SELL_BRAND_CUSTOMIZATION',
  // Inperium Talk features
  'TALK',
  'TALK_AUTO_RECEPTIONISTS',
  'TALK_CALL_FORWARDING',
  'TALK_CALL_QUEUES',
  'TALK_CALL_RECORDING',
  'TALK_COLD_CALL_TRANSFER',
  'TALK_COMPANY_MAIN_PHONE_NUMBER',
  'TALK_CONFERENCE_CALLS',
  'TALK_CONTACT_MANAGEMENT',
  'TALK_CUSTOM_BUSINESS_HOURS',
  'TALK_DASHBOARD_BUILT_IN',
  'TALK_DOMESTIC_CALL_PACKAGE_L',
  'TALK_DOMESTIC_CALL_PACKAGE_M',
  'TALK_DOMESTIC_CALL_PACKAGE_S',
  'TALK_DOMESTIC_CALL_PACKAGE_XL',
  'TALK_DOMESTIC_CALL_PACKAGE',
  'TALK_INBOUND_DOMESTIC_CALL',
  'TALK_INBOUND_INTERNAL_CALL',
  'TALK_INBOUND_INTERNATIONAL_CALL',
  'TALK_INBOUND_SMS',
  'TALK_IVR_MENUS',
  'TALK_LOCAL_NUMBERS',
  'TALK_MULTI_LEVEL_IVR_MENU',
  'TALK_NUMBER_PORTING',
  'TALK_OUTBOUND_DOMESTIC_CALL',
  'TALK_OUTBOUND_INTERNAL_CALL',
  'TALK_OUTBOUND_INTERNATIONAL_CALL',
  'TALK_OUTBOUND_SMS',
  'TALK_PHONE_NUMBER_MANAGEMENT',
  'TALK_REPORT_BUILT_IN',
  'TALK_SHARED_CONTACTS',
  'TALK_SINGLE_LEVEL_IVR_MENU',
  'TALK_SMS_PACKAGE',
  'TALK_SPAM_BLOCKING',
  'TALK_SPEECH_TO_TEXT',
  'TALK_TEXT_TO_SPEECH',
  'TALK_TOLL_FREE_NUMBERS',
  'TALK_VOICEMAIL',
  'TALK_WARM_CALL_TRANSFER',
  'TALK_WELCOME_PAGE'
] as const;

export type Feature = typeof features[number];

/**
 * A type guard to check if a string is a valid feature.
 * @param feature the feature to check.
 * @returns true if the feature is valid, false otherwise.
 */
export function isFeature(feature: Feature): feature is Feature {
  return features.includes(feature);
}
