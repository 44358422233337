export function blue<T extends `${number}%`>(lightness: T): `hsla(218, 97%, ${typeof lightness}, 1)` {
  return `hsla(218, 97%, ${lightness}, 1)`;
}

export function gray<T extends `${number}%`>(lightness: T): `hsla(215, 23%, ${typeof lightness}, 1)` {
  return `hsla(215, 23%, ${lightness}, 1)`;
}

export function neutral<T extends `${number}%`>(lightness: T): `hsla(0, 0%, ${typeof lightness}, 1)` {
  return `hsla(0, 0%, ${lightness}, 1)`;
}

export function slate<T extends `${number}%`, S extends `${number}%`>(
  saturation: T,
  lightness: S
): `hsla(215, ${typeof saturation}, ${typeof lightness}, 1)` {
  return `hsla(215, ${saturation}, ${lightness}, 1)`;
}

export function green<T extends `${number}%`>(lightness: T): `hsla(132, 45%, ${typeof lightness}, 1)` {
  return `hsla(132, 45%, ${lightness}, 1)`;
}

export function yellow<T extends `${number}%`>(lightness: T): `hsla(36, 100%, ${typeof lightness}, 1)` {
  return `hsla(36, 100%, ${lightness}, 1)`;
}

export function red<T extends `${number}%`>(lightness: T): `hsla(360, 85%, ${typeof lightness}, 1)` {
  return `hsla(360, 85%, ${lightness}, 1)`;
}

export function violet<T extends `${number}%`>(lightness: T): `hsla(285, 73%, ${typeof lightness}, 1)` {
  return `hsla(285, 73%, ${lightness}, 1)`;
}
