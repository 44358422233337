import {useIsSSR} from '@inperium-corp/convergo-aria-ssr';

const MOBILE_SCREEN_WIDTH = 700;

/**
 * Checks if the device of the user is a mobile device.
 */
export function useIsMobileDevice(): boolean {
  const isSSR = useIsSSR();

  if (isSSR || typeof window === 'undefined') {
    return false;
  }

  return window.screen.width <= MOBILE_SCREEN_WIDTH;
}
