import {css, useStyles} from '@inperium-corp/convergo-react-styles';

export const wavingHandIconStylesFactory = () => ({
  root: css`
    height: 6rem;
    width: 6rem;
  `
});

export const WavingHandIcon = () => {
  const styles = useStyles(wavingHandIconStylesFactory);

  return (
    <svg
      className={styles.root}
      width='54'
      height='51'
      viewBox='0 0 54 51'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='32.625' cy='30.125' r='20.375' fill='#E4F1FF' stroke='#237CFC' strokeWidth='0.5' />
      <path
        d='M15.4637 4.88829C16.3746 4.36237 17.5394 4.67447 18.0653 5.58538L26.0008 19.33L27.1003 18.6952L21.0693 8.24932C20.5434 7.33841 20.8555 6.17363 21.7664 5.64772C22.6774 5.1218 23.8421 5.4339 24.3681 6.34481L27.0112 10.9228L27.0132 10.9262L34.2079 23.3881L35.3074 22.7533L30.2288 13.9567C29.5275 12.7422 29.9437 11.1892 31.1582 10.4879C31.7655 10.1373 32.542 10.3454 32.8926 10.9527L38.9236 21.3985C42.2544 27.1677 40.2777 34.5446 34.5086 37.8754C28.7395 41.2062 21.3625 39.2296 18.0317 33.4604L9.14415 18.0665C8.61823 17.1556 8.93033 15.9908 9.84124 15.4649C10.7522 14.9389 11.9169 15.251 12.4429 16.162L17.2041 24.4087L18.3036 23.7739L11.6378 12.2284C11.1119 11.3175 11.424 10.1527 12.3349 9.62677C13.2458 9.10085 14.4106 9.41295 14.9365 10.3239L21.6023 21.8694L22.7021 21.2345L14.7666 7.48989C14.2407 6.57898 14.5528 5.4142 15.4637 4.88829Z'
        fill='white'
        stroke='#237CFC'
        strokeWidth='0.5'
      />
      <path
        d='M32.7666 4.02246C35.3711 4.95992 37.5249 6.84135 38.8155 9.25398'
        stroke='#237CFC'
        strokeWidth='0.5'
        strokeLinecap='round'
      />
      <path
        d='M41.8272 7.94973C40.0825 4.53677 37.016 1.91178 33.3027 0.75'
        stroke='#237CFC'
        strokeWidth='0.5'
        strokeLinecap='round'
      />
      <path
        d='M4.36426 14.667C3.8739 17.3912 4.42631 20.1973 5.87043 22.5213'
        stroke='#237CFC'
        strokeWidth='0.5'
        strokeLinecap='round'
      />
      <path
        d='M3.2356 24.4774C1.15223 21.26 0.412212 17.2918 1.2627 13.4951'
        stroke='#237CFC'
        strokeWidth='0.5'
        strokeLinecap='round'
      />
    </svg>
  );
};
