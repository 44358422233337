/**
 * Keyboards, Assistive Technologies, and element.click() all produce a "virtual"
 * click event. This is a method of inferring such clicks. Every browser except
 * IE 11 only sets a zero value of "detail" for click events that are "virtual".
 * However, IE 11 uses a zero value for all click events. For IE 11 we rely on
 * the quirk that it produces click events that are of type PointerEvent, and
 * where only the "virtual" click lacks a pointerType field.
 * @param event The event to check.
 */
export function isVirtualClick(event: MouseEvent | PointerEvent): boolean {
  // JAWS/NVDA with Firefox.
  if ((event as any).mozInputSource === 0 && event.isTrusted) {
    return true;
  }

  return event.detail === 0 && !(event as PointerEvent).pointerType;
}
