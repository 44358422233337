import {HTMLAttributes, useContext, useEffect} from 'react';

import {ModalContext} from './ModalContext';

interface ModalAriaProps {
  /**
   * If the modal is disabled.
   */
  isDisabled?: boolean;
}

interface ModalAria {
  /**
   * Props for the modal content element.
   */
  modalProps: {
    /**
     * Data attribute marks the dom node as a modal for the aria-modal-polyfill.
     */
    'data-ismodal': boolean;
  } & HTMLAttributes<HTMLElement>;
}

/**
 * Provides the behavior and accessibility implementation for a modal component.
 * Hides content outside the current `<OverlayContainer>` from screen readers
 * on mount and restores it on unmount. Typically used by modal dialogs and
 * other types of overlays to ensure that only the top-most modal is
 * accessible at once.
 * @param props Props to be applied to the modal.
 * @returns The aria props to be spread on the modal.
 */
export function useModal(props: ModalAriaProps = {}): ModalAria {
  // Add aria-hidden to all parent providers on mount, and restore on unmount.
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('Modal is not contained within a provider');
  }

  useEffect(() => {
    if (props?.isDisabled || !context || !context.parent) {
      return;
    }

    // The immediate context is from the provider containing this modal, so we only
    // want to trigger aria-hidden on its parents not on the modal provider itself.
    context.parent.addModal();
    return () => {
      if (context && context.parent) {
        context.parent.removeModal();
      }
    };
  }, [context, context.parent, props?.isDisabled]);

  return {
    modalProps: {
      'data-ismodal': !props?.isDisabled
    }
  };
}
