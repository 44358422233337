import {forwardRef, ReactNode, RefObject} from 'react';

import {filterDOMProps} from '@inperium-corp/convergo-aria-utils';
import {useSlotProps} from '@inperium-corp/convergo-react-layout';
import {DOMProps, SlotProps, StyleProps} from '@inperium-corp/convergo-types';

export interface KeyboardProps extends SlotProps, DOMProps, StyleProps {
  /**
   * The keyboard command that shall be rendered.
   */
  children?: ReactNode;
}

/**
 * A Keyboard component shows a keyboard command available to the user.
 */
export const Keyboard = forwardRef((props: KeyboardProps, ref?: RefObject<HTMLElement>) => {
  props = useSlotProps('keyboard', props);
  const {children, ...otherProps} = props;

  return (
    <kbd {...filterDOMProps(otherProps)} dir='ltr' ref={ref}>
      {children}
    </kbd>
  );
});
