/**
 * Takes a value and forces it to the closest min/max if it's outside. Also forces it to the closest valid step.
 * @param value The value to clamp.
 * @param min The closest min value.
 * @param max The closest max value.
 * @returns The clamped value.
 */
export function clamp(value: number, min = -Infinity, max = Infinity): number {
  return Math.min(Math.max(value, min), max);
}
