import {Locale} from '@inperium-corp/convergo-types';

import {isRTL} from './isRTL';

/**
 * Gets the locale setting of the browser and otherwise falls back to en-US.
 * @returns The locale setting.
 */
export function getDefaultLocale(): Locale {
  // @ts-ignore
  let locale = (typeof navigator !== 'undefined' && (navigator.language || navigator.userLanguage)) || 'en-US';
  try {
    Intl.DateTimeFormat.supportedLocalesOf([locale]);
  } catch (_err) {
    locale = 'en-US';
  }
  return {
    locale,
    writingDirection: isRTL(locale) ? 'rtl' : 'ltr'
  };
}
