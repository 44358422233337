/* eslint-disable */
export const translation = {
  timing_out_modal: {
    still_here: 'Still here?',
    explanation: `You have been inactive for a while.
We have to log you out in {{leftTime}}`,
    im_here: `I'm here`,
    logout: 'Logout'
  }
};
