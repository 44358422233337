import {RefObject, useContext} from 'react';

import {mergeProps, useSyncRef} from '@inperium-corp/convergo-aria-utils';

import {PressResponderContext} from './PressResponderContext';
import {PressAriaProps} from './usePress';

/**
 * A context hook that makes its child components respond to user presses.
 * @see PressResponderProvider
 * @returns The press responder aria props.
 */
export function usePressResponder<T extends HTMLElement>(
  props: PressAriaProps<T>,
  ref: RefObject<T>
): PressAriaProps<T> {
  const context = useContext(PressResponderContext);

  if (context) {
    const {register, ...contextProps} = context;
    props = mergeProps(contextProps, props);
    register();
  }

  useSyncRef(ref, context?.ref);

  return props;
}
