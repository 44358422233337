import {RefObject, useCallback} from 'react';

import {useEvent} from '@inperium-corp/convergo-aria-utils';
import {ScrollEvent, ScrollEvents} from '@inperium-corp/convergo-types';

export interface ScrollWheelProps extends ScrollEvents {
  /**
   * Whether the scroll listener should be disabled.
   */
  isDisabled?: boolean;
}

/**
 * A hook that attaches scroll handlers for accessible scroll wheel behaviour.
 * @param props The scroll wheel props.
 * @param ref The ref to the element.
 */
export function useScrollWheel(props: ScrollWheelProps, ref: RefObject<HTMLElement>): void {
  const {onScroll, isDisabled} = props;

  const onScrollHandler = useCallback(
    (e: ScrollEvent) => {
      // If the ctrlKey is pressed, this is a zoom event, do nothing.
      if (e.ctrlKey) {
        return;
      }

      // stop scrolling the page
      e.preventDefault();
      e.stopPropagation();

      if (onScroll) {
        onScroll({deltaX: e.deltaX, deltaY: e.deltaY});
      }
    },
    [onScroll]
  );

  useEvent(ref, 'wheel', isDisabled ? null : onScrollHandler);
}
