// To support SSR, the auto incrementing id counter is stored in a context. This allows
// it to be reset on every request to ensure the client and server are consistent.
// There is also a prefix counter that is used to support async loading components
// Each async boundary must be wrapped in an SSR provider, which increments the prefix
// and resets the current id counter. This ensures that async loaded components have

import {createContext} from 'react';

// Consistent ids regardless  of the loading order.
export interface UniqueIdentifierContextValue {
  prefix: string;
  current: number;
}

// Default context value to use in case there is no UniqueIdentifierProvider. This is fine for
// client-only apps. In order to support multiple copies of Convergo Aria potentially
// being on the page at once, the prefix is set to a random number. UniqueIdentifier
// will reset this to zero for consistency between server and client, so in the
// SSR case multiple copies of Convergo Aria is not supported.
export const defaultUniqueIdentifierContext: UniqueIdentifierContextValue = {
  prefix: String(Math.round(Math.random() * 10000000000)),
  current: 0
};

export const UniqueIdentifierContext = createContext<UniqueIdentifierContextValue>(defaultUniqueIdentifierContext);
