import {forwardRef, HTMLAttributes, ReactNode, RefObject} from 'react';

import {css, cx, Theme, useStyles} from '@inperium-corp/convergo-react-styles';
import {SlotProps, StyleProps} from '@inperium-corp/convergo-types';

import {SlotsProvider, useSlotProps} from './SlotsContext';

export interface CardProps extends StyleProps, SlotProps, HTMLAttributes<HTMLElement> {
  /**
   * The content to be rendered in the card.
   */
  children: ReactNode;
}

const cardStylesFactory = (theme: Theme) => ({
  root: css`
    background: ${theme.global.static.colors.white};
    border: 1px solid ${theme.colorScheme.color.gray[100]};
    border-radius: 6px;
    > *:first-child {
      padding: 18px;
    }
    > * + * {
      padding: 0 18px 18px;
    }
    > *:last-child {
      padding: 18px;
    }
  `,
  header: css`
    display: flex;
  `,
  heading: css`
    margin: 0;
  `,
  content: css``,
  footer: css`
    background: ${theme.colorScheme.color.gray[50]};
    border-top: 1px solid ${theme.colorScheme.color.gray[100]};
    display: flex;
    align-items: center;
    justify-content: space-between;
  `
});

export const Card = forwardRef((props: CardProps, ref: RefObject<HTMLElement>) => {
  const styles = useStyles(cardStylesFactory);

  props = useSlotProps('card', props);
  const {className, children, ...otherProps} = props;

  return (
    <section {...otherProps} ref={ref} className={cx(styles.root, className)}>
      <SlotsProvider
        shouldPropagate
        slots={{
          header: {className: styles.header},
          heading: {className: styles.heading},
          content: {className: styles.content},
          footer: {className: styles.footer}
        }}
      >
        {children}
      </SlotsProvider>
    </section>
  );
});
