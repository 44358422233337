import {ReactElement, useEffect, useRef} from 'react';

import {PressResponderProvider} from '@inperium-corp/convergo-aria-interactions';
import {useOverlayPosition, useOverlayTrigger} from '@inperium-corp/convergo-aria-overlays';
import {useMediaQuery} from '@inperium-corp/convergo-aria-utils';
import {Modal, Popover, Tray} from '@inperium-corp/convergo-react-overlays';
import {useOpenState} from '@inperium-corp/convergo-state-overlays';
import {
  DialogClose,
  DialogTriggerBaseProps,
  DialogTriggerProps,
  PopoverTriggerProps
} from '@inperium-corp/convergo-types';

import {DialogContext} from './DialogContext';

/**
 * The DialogTrigger is as a wrapper around a Dialog and its corresponding trigger. It is linking
 * the Dialog's open state with the trigger's press state. Additionally, it allows you to customize
 * the type and positioning of the Dialog.
 */
export const DialogTrigger = (props: DialogTriggerProps) => {
  const {children, hideArrow, targetRef, isKeyboardDismissDisabled, defaultOpen, ...positionProps} = props;
  let {type = 'modal', mobileType, isDismissable} = props;

  if (!Array.isArray(children) || children.length > 2) {
    throw new Error('A DialogTrigger must have exactly 2 children');
  }

  // For popovers we want to show modals on the mobile device.
  if (!mobileType) {
    if (type === 'popover') {
      mobileType = 'modal';
    } else {
      mobileType = type;
    }
  }

  // On small devices, show a modal or tray instead of a popover.
  const isMobile = useMediaQuery('(max-width: 700px)');
  if (isMobile) {
    // Handle cases where desktop popovers need a close button for the mobile modal view.
    if (type !== 'modal' && mobileType === 'modal') {
      isDismissable = true;
    }

    type = mobileType;
  }

  const state = useOpenState({...props, default: defaultOpen});
  const wasOpen = useRef(false);
  wasOpen.current = state.isOpen;
  const isExiting = useRef(false);
  const onExiting = () => (isExiting.current = true);
  const onExited = () => (isExiting.current = false);

  useEffect(() => {
    return () => {
      if ((wasOpen.current || isExiting.current) && type !== 'popover' && type !== 'tray') {
        console.warn(
          'A DialogTrigger unmounted while open. This is likely due to being placed within a trigger that unmounts or inside a conditional. Consider using a DialogContainer instead.'
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [trigger, content] = children as [ReactElement, DialogClose];

  if (type === 'popover') {
    return (
      <PopoverTrigger
        {...positionProps}
        state={state}
        targetRef={targetRef}
        trigger={trigger}
        content={content}
        isKeyboardDismissDisabled={isKeyboardDismissDisabled}
        hideArrow={hideArrow} />
    );
  }

  const renderOverlay = () => {
    switch (type) {
      case 'fullscreen':
      case 'fullscreenTakeover':
      case 'modal':
      case 'drawer':
        return (
          <Modal
            isOpen={state.isOpen}
            isDismissable={type === 'modal' ? isDismissable : false}
            onClose={state.close}
            type={type}
            isKeyboardDismissDisabled={isKeyboardDismissDisabled}
            onExiting={onExiting}
            onExited={onExited}>
            {typeof content === 'function' ? content(state.close) : content}
          </Modal>
        );
      case 'tray':
        return (
          <Tray isOpen={state.isOpen} onClose={state.close} isKeyboardDismissDisabled={isKeyboardDismissDisabled}>
            {typeof content === 'function' ? content(state.close) : content}
          </Tray>
        );
    }
  };

  return (
    <DialogTriggerBase
      type={type}
      state={state}
      isDismissable={isDismissable}
      trigger={trigger}
      overlay={renderOverlay()} />
  );
};

// Support DialogTrigger inside components using CollectionBuilder.
DialogTrigger.getCollectionNode = function* (props: DialogTriggerProps) {
  const [trigger, content] = props.children as [ReactElement, DialogClose];
  yield {
    element: trigger,
    wrapper: (element: ReactElement) => (
      <DialogTrigger key={element.key} {...props}>
        {element}
        {content}
      </DialogTrigger>
    )
  };
};

const PopoverTrigger = (props: PopoverTriggerProps) => {
  const {
    state,
    targetRef,
    trigger,
    content,
    hideArrow,
    isKeyboardDismissDisabled,
    placement,
    containerPadding,
    offset,
    crossOffset,
    shouldFlip
  } = props;

  const triggerRef = useRef<HTMLElement>();
  const overlayRef = useRef<HTMLDivElement>();

  const {
    overlayProps: popoverProps,
    direction,
    arrowProps
  } = useOverlayPosition({
    targetRef: targetRef || triggerRef,
    overlayRef,
    placement,
    containerPadding,
    offset,
    crossOffset,
    shouldFlip,
    isOpen: state.isOpen
  });

  const {triggerProps, overlayProps} = useOverlayTrigger({type: 'dialog'}, state);

  const triggerPropsWithRef = {
    ...triggerProps,
    ref: targetRef ? undefined : triggerRef
  };

  const overlay = (
    <Popover
      isOpen={state.isOpen}
      style={popoverProps.style}
      ref={overlayRef}
      onClose={state.close}
      direction={direction}
      arrowProps={arrowProps}
      isKeyboardDismissDisabled={isKeyboardDismissDisabled}
      hideArrow={hideArrow}>
      {typeof content === 'function' ? content(state.close) : content}
    </Popover>
  );

  return (
    <DialogTriggerBase
      type='popover'
      state={state}
      triggerProps={triggerPropsWithRef}
      dialogProps={overlayProps}
      trigger={trigger}
      overlay={overlay} />
  );
};

const DialogTriggerBase = (props: DialogTriggerBaseProps) => {
  const {type, state, isDismissable, dialogProps = {}, triggerProps = {}, overlay, trigger} = props;
  const context = {
    type,
    onClose: state.close,
    isDismissable,
    ...dialogProps
  };

  return (
    <>
      <PressResponderProvider
        {...triggerProps}
        onPress={state.toggle}
        isPressed={state.isOpen && type !== 'modal' && type !== 'fullscreen' && type !== 'fullscreenTakeover'}>
        {trigger}
      </PressResponderProvider>
      <DialogContext.Provider value={context}>{overlay}</DialogContext.Provider>
    </>
  );
};
