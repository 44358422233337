import {ElementType, forwardRef, RefObject} from 'react';

import {useButton} from '@inperium-corp/convergo-aria-button';
import {FocusRing} from '@inperium-corp/convergo-aria-focus';
import {useHover} from '@inperium-corp/convergo-aria-interactions';
import {mergeProps, useFallbackRef} from '@inperium-corp/convergo-aria-utils';
import {Icon} from '@inperium-corp/convergo-react-icon';
import {SlotsProvider, useSlotProps} from '@inperium-corp/convergo-react-layout';
import {Spinner} from '@inperium-corp/convergo-react-spinner';
import {css, cx, Theme, useStyles} from '@inperium-corp/convergo-react-styles';
import {Text} from '@inperium-corp/convergo-react-text';
import {ButtonElement, ButtonProps} from '@inperium-corp/convergo-types';

export const buttonStylesFactory = (theme: Theme) => ({
  root: css`
    align-items: center;
    background-color: transparent;
    border: 1px solid;
    outline: none;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    position: relative;
    text-align: center;
    touch-action: none;
    transition: background-color 0.1s ease-out, box-shadow 0.2s;
    user-select: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: -1px;
      transform: translateX(0);
      transition: box-shadow 0.13s ease-out, margin 0.13s ease-out;
    }

    &:hover,
    &:focus {
      outline: none;
    }
  `,
  variants: {
    contained: {
      primary: css`
        background: ${theme.colorScheme.color.blue[500]};
        border-color: ${theme.colorScheme.color.blue[500]};
        color: ${theme.global.static.colors.white};

        &:hover,
        &.hover {
          background: ${theme.colorScheme.color.blue[600]};
          border-color: ${theme.colorScheme.color.blue[600]};
        }

        &:active,
        &.active {
          background: ${theme.colorScheme.color.blue[750]};
          border-color: ${theme.colorScheme.color.blue[750]};
        }
      `,
      secondary: css`
        background: ${theme.colorScheme.color.green[500]};
        border-color: ${theme.colorScheme.color.green[500]};
        color: ${theme.global.static.colors.white};

        &:hover,
        &.hover {
          background: ${theme.colorScheme.color.green[600]};
          border-color: ${theme.colorScheme.color.green[600]};
        }

        &:active,
        &.active {
          background: ${theme.colorScheme.color.green[700]};
          border-color: ${theme.colorScheme.color.green[700]};
        }
      `,
      danger: css`
        background: ${theme.colorScheme.color.red[500]};
        border-color: ${theme.colorScheme.color.red[500]};
        color: ${theme.global.static.colors.white};

        &:hover,
        &.hover {
          background: ${theme.colorScheme.color.red[750]};
          border-color: ${theme.colorScheme.color.red[750]};
        }

        &:active,
        &.active {
          background: ${theme.colorScheme.color.red[850]};
          border-color: ${theme.colorScheme.color.red[850]};
        }
      `
    },
    text: {
      primary: css`
        background: transparent;
        border-color: transparent;
        color: ${theme.colorScheme.color.blue[500]};

        &:hover,
        &.hover {
          background: ${theme.colorScheme.color.blue[100]};
          border-color: ${theme.colorScheme.color.blue[100]};
        }

        &:active,
        &.active {
          background: ${theme.colorScheme.color.blue[300]};
          border-color: ${theme.colorScheme.color.blue[300]};
        }
      `,
      secondary: css`
        background: transparent;
        border-color: transparent;
        color: ${theme.colorScheme.color.green[500]};

        &:hover,
        &.hover {
          background: ${theme.colorScheme.color.green[100]};
          border-color: ${theme.colorScheme.color.green[100]};
        }

        &:active,
        &.active {
          background: ${theme.colorScheme.color.green[300]};
          border-color: ${theme.colorScheme.color.green[300]};
        }
      `,
      danger: css`
        background: transparent;
        border-color: transparent;
        color: ${theme.colorScheme.color.red[500]};

        &:hover,
        &.hover {
          background: ${theme.colorScheme.color.red[150]};
          border-color: ${theme.colorScheme.color.red[150]};
        }

        &:active,
        &.active {
          background: ${theme.colorScheme.color.red[400]};
          border-color: ${theme.colorScheme.color.red[400]};
        }
      `
    },
    outlined: {
      primary: css`
        background: transparent;
        border-color: ${theme.colorScheme.color.blue[500]};
        color: ${theme.colorScheme.color.blue[500]};

        &:hover,
        &.hover {
          background: ${theme.colorScheme.color.blue[100]};
          border-color: ${theme.colorScheme.color.blue[500]};
        }

        &:active,
        &.active {
          background: ${theme.colorScheme.color.blue[300]};
          border-color: ${theme.colorScheme.color.blue[500]};
        }
      `,
      secondary: css`
        background: transparent;
        border-color: ${theme.colorScheme.color.green[500]};
        color: ${theme.colorScheme.color.green[500]};

        &:hover,
        &.hover {
          background: ${theme.colorScheme.color.green[100]};
          border-color: ${theme.colorScheme.color.green[500]};
        }

        &:active,
        &.active {
          background: ${theme.colorScheme.color.green[300]};
          border-color: ${theme.colorScheme.color.green[500]};
        }
      `,
      danger: css`
        background: transparent;
        border-color: ${theme.colorScheme.color.red[500]};
        color: ${theme.colorScheme.color.red[500]};

        &:hover,
        &.hover {
          background: ${theme.colorScheme.color.red[150]};
          border-color: ${theme.colorScheme.color.red[500]};
        }

        &:active,
        &.active {
          background: ${theme.colorScheme.color.red[400]};
          border-color: ${theme.colorScheme.color.red[500]};
        }
      `
    }
  },
  sizes: {
    small: css`
      border-radius: 7px;
      padding: 5px 12px;
      font-size: ${theme.scale.font.size.small};
      line-height: ${theme.scale.font.lineHeight.small};
      font-weight: ${theme.global.font.weight.semiBold};
      &:after {
        border-radius: 7px;
      }
    `,
    medium: css`
      border-radius: 9px;
      padding: 6.5px 12px;
      font-size: ${theme.scale.font.size.medium};
      line-height: ${theme.scale.font.lineHeight.medium};
      font-weight: ${theme.global.font.weight.semiBold};
      &:after {
        border-radius: 9px;
      }
    `,
    large: css`
      border-radius: 12px;
      padding: 11px 18px;
      font-size: ${theme.scale.font.size.large};
      line-height: ${theme.scale.font.lineHeight.large};
      font-weight: ${theme.global.font.weight.semiBold};
      &:after {
        border-radius: 12px;
      }
    `
  },
  isDisabled: css`
    opacity: 0.4;

    &:hover,
    &.hover {
      cursor: default;
    }
  `,
  focusRingColors: {
    primary: css`
      &:after {
        box-shadow: ${theme.colorScheme.color.blue[400]} 0 0 0 4px;
      }
    `,
    secondary: css`
      &:after {
        box-shadow: ${theme.colorScheme.color.green[400]} 0 0 0 4px;
      }
    `,
    danger: css`
      &:after {
        box-shadow: ${theme.colorScheme.color.red[400]} 0 0 0 4px;
      }
    `
  },
  label: css`
    align-self: center;
    justify-self: center;
    text-align: center;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
  `,
  iconAlignments: {
    start: css`
      order: 0;
      margin-right: 8px;
    `,
    end: css`
      order: 1;
      margin-left: 8px;
    `
  }
});

/**
 * A button triggers an event or action. They let users know what will happen next.
 */
export const Button = forwardRef(
  <T extends ElementType = 'button'>(props: ButtonProps<T>, ref?: RefObject<ButtonElement<T>>) => {
    const styles = useStyles(buttonStylesFactory);

    // @ts-expect-error TODO: fix this
    props = useSlotProps('button', props);
    const {
      autoFocus,
      children,
      className,
      color = 'primary',
      isDisabled,
      size = 'medium',
      elementType: ElementType = 'button',
      icon,
      // We only set the alignment if there are other children in the button.
      iconAlignment = children ? 'start' : undefined,
      isActive,
      isLoading,
      variant = 'contained'
    } = props;

    ref = useFallbackRef<ButtonElement<T>>(ref);

    const {buttonProps, buttonState} = useButton(props, ref);
    const {hoverProps, hoverState} = useHover({isDisabled});

    const contents = (
      <SlotsProvider
        shouldPropagate
        slots={{
          text: {
            className: styles.label
          }
        }}
      >
        {children}
      </SlotsProvider>
    );

    return (
      <FocusRing focusRingClassName={styles.focusRingColors[color]} autoFocus={autoFocus}>
        <ElementType
          {...mergeProps(buttonProps, hoverProps)}
          ref={ref}
          className={cx(
            'button',
            styles.root,
            styles.sizes[size],
            styles.variants[variant][color],
            hoverState.isHovered && 'hover',
            isDisabled && styles.isDisabled,
            (buttonState.isPressed || isActive) && 'active',
            className
          )}
        >
          {isLoading ? (
            <Spinner>{contents}</Spinner>
          ) : (
            <>
              {icon && <Icon icon={icon} className={styles.iconAlignments[iconAlignment]} />}
              {typeof children === 'string' ? <Text className={styles.label}>{contents}</Text> : contents}
            </>
          )}
        </ElementType>
      </FocusRing>
    );
  }
);
