import {Children, forwardRef, isValidElement, RefObject} from 'react';

import {useButton} from '@inperium-corp/convergo-aria-button';
import {FocusRing} from '@inperium-corp/convergo-aria-focus';
import {useHover} from '@inperium-corp/convergo-aria-interactions';
import {mergeProps, useFallbackRef} from '@inperium-corp/convergo-aria-utils';
import {Icon} from '@inperium-corp/convergo-react-icon';
import {SlotsProvider, useSlotProps} from '@inperium-corp/convergo-react-layout';
import {css, cx, Theme, useStyles} from '@inperium-corp/convergo-react-styles';
import {Text} from '@inperium-corp/convergo-react-text';
import {ButtonAriaProps, ButtonBaseProps, StyleProps} from '@inperium-corp/convergo-types';

export interface ActionButtonProps extends ButtonBaseProps<HTMLButtonElement>, ButtonAriaProps, StyleProps {
  /** Displays a triangle in the left corner of the button if true, to allow holding its affordance. */
  holdAffordance?: boolean;
}

export const actionButtonStylesFactory = (theme: Theme) => ({
  focusRing: 'focus-ring',
  root: cx(
    'ActionButton',
    css`
      -moz-osx-font-smoothing: grayscale;
      -webkit-appearance: none;
      -webkit-font-smoothing: antialiased;
      -webkit-user-select: none;
      align-items: center;
      block-size: ${theme.scale.actionButton.height};
      border-radius: ${theme.scale.actionButton.borderRadius};
      border-style: solid;
      border-width: ${theme.scale.actionButton.borderWidth};
      box-sizing: border-box;
      cursor: pointer;
      display: inline-flex;
      font-size: ${theme.scale.actionButton.fontSize};
      font-weight: ${theme.global.font.weight.medium};
      isolation: isolate;
      justify-content: center;
      line-height: ${theme.scale.actionButton.lineHeight};
      margin: 0;
      min-inline-size: ${theme.scale.actionButton.minWidth};
      overflow: visible;
      padding: 0;
      position: relative;
      text-decoration: none;
      text-transform: none;
      touch-action: none;
      user-select: none;
      user-select: none;
      vertical-align: top;
      transition: background ${theme.global.animation.duration[100]} ease-out,
        border-color ${theme.global.animation.duration[100]} ease-out,
        color ${theme.global.animation.duration[100]} ease-out,
        box-shadow ${theme.global.animation.duration[100]} ease-out;
      &:focus {
        outline: none;
      }
      &:focus-ring {
        z-index: 3;
      }
      &::-moz-focus-inner {
        border: 0;
        border-style: none;
        padding: 0;
        margin-block-start: -2px;
        margin-block-end: -2px;
      }
      &:disabled,
      &.is-disabled {
        cursor: default;
      }
      .Icon {
        max-block-size: 100%;
        flex-shrink: 0;
        order: 0;
        transition: background ${theme.global.animation.duration[100]} ease-out,
          fill ${theme.global.animation.duration[100]} ease-out;
        box-sizing: initial;
      }
      .ActionButton-label {
        padding-inline-end: ${theme.scale.actionButton.text.paddingX};
      }
      .Icon + .ActionButton-label {
        padding-inline-start: ${theme.scale.actionButton.icon.paddingX};
      }
      .ActionButton-label:not([hidden]) + .Icon {
        padding-inline-end: ${theme.scale.actionButton.icon.paddingX};
      }
      .ActionButton-label:only-child,
      .ActionButton-hold + .ActionButton-label:last-child {
        padding-inline-start: ${theme.scale.actionButton.text.paddingX};
      }
      .Icon:only-child,
      .ActionButton-hold + .Icon:last-child {
        padding-inline-end: ${theme.scale.actionButton.icon.paddingX};
      }
      .ActionButton-hold {
        position: absolute;
        inset-inline-end: ${theme.scale.actionButton.icon.paddingX};
        inset-block-end: ${theme.scale.actionButton.icon.paddingY};
      }
      [dir='rtl'] .ActionButton-hold {
        transform: rotate(90deg);
      }
      .ActionButton-label {
        align-self: center;
        justify-self: center;
        order: 1;
        text-align: center;
        inline-size: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:empty {
          display: none;
        }
      }
    `
  ),
  isActive: 'is-active',
  isDisabled: 'is-disabled',
  isHovered: 'is-hovered',
  hold: 'ActionButton-hold',
  icon: 'Icon',
  label: 'ActionButton-label'
});

export const ActionButton = forwardRef((props: ActionButtonProps, ref?: RefObject<HTMLButtonElement>) => {
  const styles = useStyles(actionButtonStylesFactory);

  props = useSlotProps('button', props);
  const {isDisabled, children, autoFocus, className, holdAffordance} = props;

  ref = useFallbackRef<HTMLButtonElement>(ref);

  const {buttonProps, buttonState} = useButton(props, ref);
  const {hoverProps, hoverState} = useHover({isDisabled});
  const isTextOnly = Children.toArray(props.children).every((c) => !isValidElement(c));

  return (
    <FocusRing focusRingClassName={styles.focusRing} autoFocus={autoFocus}>
      <button
        {...mergeProps(buttonProps, hoverProps)}
        ref={ref}
        className={cx(
          styles.root,
          buttonState.isPressed && styles.isActive,
          isDisabled && styles.isDisabled,
          hoverState.isHovered && styles.isHovered,
          className
        )}
      >
        {holdAffordance && <Icon icon='caretDownOutlined' className={styles.hold} />}
        <SlotsProvider
          slots={{
            icon: {
              className: styles.icon
            },
            text: {
              className: styles.label
            }
          }}
        >
          {typeof children === 'string' || isTextOnly ? <Text>{children}</Text> : children}
        </SlotsProvider>
      </button>
    </FocusRing>
  );
});
