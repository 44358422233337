import {forwardRef, RefObject, useContext, useMemo} from 'react';

import {useDialog} from '@inperium-corp/convergo-aria-dialog';
import {FocusScopeProvider} from '@inperium-corp/convergo-aria-focus';
import {useMessageFormatter} from '@inperium-corp/convergo-aria-i18n';
import {DismissButton} from '@inperium-corp/convergo-aria-overlays';
import {mergeProps, useFallbackRef} from '@inperium-corp/convergo-aria-utils';
import {Button} from '@inperium-corp/convergo-react-button';
import {SlotsProvider, useSlotProps} from '@inperium-corp/convergo-react-layout';
import {css, cx, Theme, useStyles} from '@inperium-corp/convergo-react-styles';
import {DialogProps} from '@inperium-corp/convergo-types';

import i18nMessages from '../i18n/*.json';
import {DialogContext, DialogContextProps} from './DialogContext';

export const dialogStylesFactory = (theme: Theme, props: DialogProps) => ({
  root: css`
    display: grid;
    grid-template-columns: auto min-content;
    grid-template-rows: min-content auto min-content;
    grid-template-areas: ${props.isDismissable
      ? `'header header' 
        'content content' 
        'footer footer'`
      : `'header dismiss-button' 
        'content content' 
        'footer footer'`};
    align-items: start;

    box-sizing: border-box;
    min-width: 288px;
    outline: none;
    background: ${theme.global.static.colors.white};
    box-shadow: 0 1.6rem 4.8rem rgba(0, 0, 0, 0.175);
    border-radius: 9px;
    padding: 20px;
  `,
  isDismissable: css``,
  sizes: {
    small: css`
      width: 400px;
      max-height: inherit;
      max-width: 100%;
    `,
    medium: css`
      width: 480px;
      max-height: inherit;
      max-width: 100%;
    `,
    large: css`
      width: 640px;
      max-height: inherit;
      max-width: 100%;
    `,
    fullscreen: css`
      width: 100%;
      height: 100%;
      max-height: none;
      max-width: none;
    `,
    fullscreenTakeover: css`
      width: 100%;
      height: 100%;
      max-height: none;
      max-width: none;
      border-radius: 0;
    `,
    drawer: css`
      width: 640px;
      height: 100vh;
      max-height: inherit;
      border-radius: 0;
      margin-left: auto;
    `
  },
  dismissButton: css`
    grid-area: dismiss-button;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px;
  `,
  header: css`
    grid-area: header;
    width: 100%;
    padding: 4px 4px 4px 0;
    display: flex;
    flex-direction: column;
    gap: 6px;
  `,
  heading: css`
    margin: 0;
    outline: none;
  `,
  content: css`
    grid-area: content;
    overflow-y: auto;
    height: 100%;
    max-height: calc(100vh - 183px);
    padding: 12px 0;
  `,
  form: css`
    display: flex;
    flex-direction: column;
  `,
  footer: css`
    grid-area: footer;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 0 0;
  `,
  buttonGroup: css`
    justify-content: flex-end;
  `
});

export const Dialog = forwardRef((props: DialogProps, ref?: RefObject<HTMLDivElement>) => {
  props = useSlotProps('dialog', props);
  const {type = 'modal', ...contextProps} = useContext(DialogContext) || ({} as DialogContextProps);
  const {
    children,
    isDismissable = contextProps.isDismissable,
    onDismiss = contextProps.onClose,
    size: sizeProp,
    className
  } = props;

  const styles = useStyles(dialogStylesFactory, props);

  let size: DialogProps['size'] | 'fullscreen' | 'fullscreenTakeover' | 'drawer';
  if (sizeProp) {
    size = sizeProp;
  } else {
    if (type === 'fullscreen' || type === 'fullscreenTakeover' || type === 'drawer') {
      size = type;
    } else {
      size = type === 'popover' ? 'small' : 'large';
    }
  }

  ref = useFallbackRef<HTMLDivElement>(ref);

  const formatMessage = useMessageFormatter(i18nMessages);
  const {dialogProps, titleProps} = useDialog(mergeProps(contextProps, props), ref);

  const slots = useMemo(
    () => ({
      header: {className: styles.header},
      heading: {className: styles.heading, size: 'xlarge', ...titleProps},
      paragraph: {level: 'medium'},
      content: {className: styles.content},
      form: {className: styles.form},
      footer: {className: styles.footer},
      buttonGroup: {className: styles.buttonGroup}
    }),
    [titleProps, styles]
  );

  // If rendered in a popover or tray there won't be a visible dismiss button,
  // so we render a hidden one for screen readers.
  let hiddenDismissButton: JSX.Element;
  if (type === 'popover' || type === 'tray') {
    hiddenDismissButton = <DismissButton onDismiss={onDismiss} />;
  }

  return (
    <FocusScopeProvider contain restoreFocus>
      <section
        {...dialogProps}
        className={cx(styles.root, isDismissable && styles.isDismissable, styles.sizes[size], className)}
        ref={ref}
      >
        <SlotsProvider slots={slots}>{children}</SlotsProvider>
        {isDismissable && (
          <Button
            variant='text'
            icon='crossOutlined'
            aria-label={formatMessage('dismiss')}
            onPress={onDismiss}
            className={styles.dismissButton}
          />
        )}
        {hiddenDismissButton}
      </section>
    </FocusScopeProvider>
  );
});
