const dimensionValues = {
  scaleFactor: 1.25,
  size: {
    0: '0px',
    5: '0.5px',
    10: '1px',
    25: '2px',
    40: '4px',
    50: '5px',
    65: '6px',
    75: '8px',
    85: '9px',
    100: '10px',
    115: '11px',
    125: '13px',
    130: '14px',
    150: '15px',
    160: '16px',
    175: '18px',
    200: '20px',
    225: '22px',
    250: '25px',
    275: '28px',
    300: '30px',
    325: '32px',
    350: '35px',
    400: '40px',
    450: '45px',
    500: '50px',
    550: '56px',
    600: '60px',
    675: '68px',
    700: '70px',
    800: '80px',
    900: '90px',
    1000: '100px',
    1200: '120px',
    1250: '125px',
    1600: '160px',
    1700: '170px',
    2000: '200px',
    2400: '240px',
    3000: '300px',
    3400: '340px',
    3600: '360px',
    4600: '460px',
    5000: '500px',
    6000: '600px'
  },
  fontSize: {
    25: '1.2rem',
    50: '1.3rem',
    75: '1.5rem',
    100: '1.7rem',
    150: '1.8rem',
    200: '1.9rem',
    300: '2.2rem',
    400: '2.4rem',
    500: '2.7rem',
    600: '3.1rem',
    700: '3.4rem',
    800: '3.9rem',
    900: '4.4rem',
    1000: '4.9rem',
    1100: '5.5rem',
    1200: '6.2rem',
    1300: '7.0rem'
  }
};

const fontValues = {
  size: {
    small: dimensionValues.fontSize[75],
    medium: dimensionValues.fontSize[100],
    large: dimensionValues.fontSize[200],
    xlarge: dimensionValues.fontSize[400],
    '2xlarge': dimensionValues.fontSize[700],
    '3xlarge': dimensionValues.fontSize[900]
  },
  lineHeight: {
    small: dimensionValues.fontSize[300],
    medium: dimensionValues.fontSize[500],
    large: dimensionValues.fontSize[600],
    xlarge: dimensionValues.fontSize[700],
    '2xlarge': dimensionValues.fontSize[900],
    '3xlarge': dimensionValues.fontSize[1200]
  }
};

const borderRadiusValues = {
  xsmall: dimensionValues.size[10],
  small: dimensionValues.size[25],
  regular: dimensionValues.size[50],
  medium: dimensionValues.size[100],
  large: dimensionValues.size[200]
} as const;

const actionGroupValues = {
  gap: dimensionValues.size[25],
  borderRadius: dimensionValues.size[115],
  button: {
    fontSize: fontValues.size.medium,
    lineHeight: fontValues.lineHeight.medium,
    borderRadius: dimensionValues.size[85]
  },
  text: {
    fontSize: dimensionValues.fontSize[200],
    marginY: dimensionValues.size[65],
    marginX: dimensionValues.size[150]
  },
  icon: {
    fontSize: dimensionValues.fontSize[100],
    marginX: dimensionValues.size[150],
    marginY: dimensionValues.size[115]
  },
  menu: {
    gap: dimensionValues.size[225],
    fontSize: dimensionValues.fontSize[200]
  },
  item: {
    borderRadius: dimensionValues.size[115],
    marginX: dimensionValues.size[25],
    marginY: dimensionValues.size[25],
    paddingX: dimensionValues.size[25],
    paddingY: dimensionValues.size[25]
  }
};

const alertValues = {
  borderRadius: dimensionValues.size[50],
  borderWidth: dimensionValues.size[10],
  paddingX: dimensionValues.size[125],
  paddingY: dimensionValues.size[125],
  gap: dimensionValues.size[100],
  fontSize: fontValues.size.medium,
  closeButton: {
    borderRadius: dimensionValues.size[50],
    borderWidth: dimensionValues.size[25],
    paddingX: dimensionValues.size[25],
    paddingY: dimensionValues.size[25]
  }
};

const actionButtonVariables = {
  height: dimensionValues.size[100],
  minWidth: dimensionValues.size[100],
  borderRadius: dimensionValues.size[50],
  borderWidth: dimensionValues.size[10],
  fontSize: fontValues.size.medium,
  lineHeight: fontValues.lineHeight.medium,
  text: {
    paddingX: dimensionValues.size[25],
    paddingY: dimensionValues.size[25]
  },
  icon: {
    paddingX: dimensionValues.size[25],
    paddingY: dimensionValues.size[25]
  },
  holdIcon: {
    paddingX: dimensionValues.size[25],
    paddingY: dimensionValues.size[25]
  }
};

const calendarVariables = {
  gap: dimensionValues.size[400],
  minWidth: dimensionValues.size[3000],
  maxWidth: dimensionValues.size[3400],
  borderWidthReset: dimensionValues.size[0],
  borderRadiusReset: dimensionValues.size[0],
  button: {
    gap: dimensionValues.size[50]
  },
  focusRing: {
    gap: dimensionValues.size[10]
  },
  title: {
    fontSize: fontValues.size.large,
    lineHeight: fontValues.lineHeight.large
  },
  day: {
    height: dimensionValues.size[400],
    width: dimensionValues.size[400],
    paddingX: dimensionValues.size[0],
    paddingY: dimensionValues.size[40],
    fontSize: fontValues.size.medium,
    fontSizeHan: fontValues.size.small,
    lineHeight: fontValues.lineHeight.medium
  }
};

const dividerVariables = {
  small: {
    height: dimensionValues.size[5],
    borderRadius: dimensionValues.size[5]
  },
  medium: {
    height: dimensionValues.size[25],
    borderRadius: dimensionValues.size[10]
  },
  large: {
    height: dimensionValues.size[50],
    borderRadius: dimensionValues.size[25]
  }
};

const mainNavigationVariables = {
  width: dimensionValues.size[10],
  paddingX: dimensionValues.size[0],
  paddingY: dimensionValues.size[40]
};

// TODO: Actualize those
const sideNavigationVariables = {
  minWidth: dimensionValues.size[3000],
  paddingX: dimensionValues.size[300],
  paddingY: dimensionValues.size[225],
  item: {
    gapX: 0,
    gapY: dimensionValues.size[25],
    paddingX: dimensionValues.size[150],
    paddingY: dimensionValues.size[115],
    height: dimensionValues.size[100],
    borderRadius: borderRadiusValues.regular,
    fontSize: fontValues.size.small
  },
  icon: {
    gap: dimensionValues.size[125],
    fontSize: fontValues.size.medium
  },
  focusRing: {
    size: dimensionValues.size[10]
  },
  header: {
    paddingX: dimensionValues.size[150],
    paddingY: 0,
    gapTop: dimensionValues.size[100],
    gapBottom: dimensionValues.size[100],
    height: dimensionValues.size[225],
    borderRadius: borderRadiusValues.regular,
    fontSize: fontValues.size.small
  }
};

export const largeScale = {
  name: 'large',
  dimension: dimensionValues,
  font: fontValues,
  borderRadius: borderRadiusValues,
  actionGroup: actionGroupValues,
  alert: alertValues,
  actionButton: actionButtonVariables,
  calendar: calendarVariables,
  divider: dividerVariables,
  mainNavigation: mainNavigationVariables,
  sideNavigation: sideNavigationVariables
} as const;
