import {forwardRef, RefObject} from 'react';

import {useFallbackRef} from '@inperium-corp/convergo-aria-utils';
import {useSlotProps} from '@inperium-corp/convergo-react-layout';
import {PopoverProps} from '@inperium-corp/convergo-types';

import {Overlay} from './Overlay';
import {PopoverWrapper} from './PopoverWrapper';

export const Popover = forwardRef((props: PopoverProps, ref?: RefObject<HTMLDivElement>) => {
  props = useSlotProps('popover', props);
  const {
    arrowProps,
    children,
    className,
    hideArrow,
    isKeyboardDismissDisabled,
    isNonModal,
    onClose,
    direction,
    shouldCloseOnBlur,
    style,
    isDismissable = true,
    ...otherProps
  } = props;

  ref = useFallbackRef<HTMLDivElement>(ref);

  return (
    <Overlay {...otherProps}>
      <PopoverWrapper
        ref={ref}
        direction={direction}
        arrowProps={arrowProps}
        onClose={onClose}
        shouldCloseOnBlur={shouldCloseOnBlur}
        isKeyboardDismissDisabled={isKeyboardDismissDisabled}
        hideArrow={hideArrow}
        isNonModal={isNonModal}
        isDismissable={isDismissable}
        style={style}
        className={className}>
        {children}
      </PopoverWrapper>
    </Overlay>
  );
});
